import { useState } from "react";
import ARROW from "assets/icons/right-arrow.svg";
import Text from "components/general/Text";
import { Link } from "react-router-dom";
import Image from "components/general/Image";
import ModalWrapper from "components/modals/ModalWrapper";
import CheckBox from "components/general/CheckBox";
import styles from "./styles.module.scss";

export default function Settings() {
  const [showLanguageModal, setShowLanguageModal] = useState(false)
  const [selectedLang, setselectedLang] = useState("en")


  const onShowLangModal = () => {
    setShowLanguageModal(true)
  };

  const languages = [{ id: 1, name: "English", value: "en" }, { id: 2, name: "Arabic", value: "ar" }]

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Settings</Text>

      <div className={styles.links}>
        <Link to="organization" className={styles.clickableCard}>
          <Text>Organization</Text>
          <Image src={ARROW} alt="arrow" />
        </Link>

        <Link to="setups" className={styles.clickableCard}>
          <Text>Setups</Text>
          <Image src={ARROW} alt="arrow" />
        </Link>

        <Link to="user-access-control" className={styles.clickableCard}>
          <Text>User Access Control</Text>
          <Image src={ARROW} alt="arrow" />
        </Link>

        <button onClick={onShowLangModal} className={styles.clickableCard}>
          <Text>Language</Text>
          <Image src={ARROW} alt="arrow" />
        </button>

        <div className={styles.clickableCard}>
          <div className="d-flex flex-column">
            <Text>Contact Support</Text>
            <Text className={styles.secondaryText}>Support@gmail.com</Text>
          </div>
        </div>
        <ModalWrapper size="small" isVisible={showLanguageModal} setIsVisible={setShowLanguageModal} onConfirm={() => setShowLanguageModal(false)} headerTitle="Language">
          <div className="py-3 d-block">
            {languages.map((item) => (
              <div className="my-2">
                <CheckBox text={item.name} disabled defaultChecked={selectedLang === item.value} onValueChange={() => setselectedLang(item.value)} />
              </div>
            ))}
          </div>
        </ModalWrapper>
      </div>
    </div>
  );
}
