import { Navigate, Outlet } from "react-router-dom";
import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import Setups from "pages/settings/Setups/shifts";
import Request from "pages/settings/Setups/requests";
import PublicHoliday from "pages/settings/Setups/public-holiday";

const children = [
  {
    path: "",
    element: <Navigate to="shifts" />,
  },
  {
    path: "Shifts",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Setups />,
      },
    ],
  },
  {
    path: "Requests Setup",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Request />,
      },
    ],
  },
  {
    path: "Public Holiday",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <PublicHoliday />,
      },
    ],
  },
];

const setupsRoutes = {
  path: "settings/setups",
  element: (
    <SecondarySidebarLayout list={children} title="Setups">
      <Outlet />
    </SecondarySidebarLayout>
  ),
  children,
};

export default setupsRoutes;
