/* eslint-disable */

import dayjs from "dayjs";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { useCreateRequestMutation } from "apis/services/requests";
import TextInput from "components/inputs/TextInput";
import UserInfo from "components/cards/UserInfo";
import DatePickerInput from "components/inputs/DatePickerInput";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import showSuccessMsg from "utils/showSuccessMsg";
import ModalWrapper from "../../ModalWrapper";
import { CreateRequestProps } from "../types";
import styles from "../styles.module.scss";
import { useState } from "react";
import moment from "moment";
import { RequestsTypes } from "constants/Requests";

export default function Trip({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate,
}: CreateRequestProps) {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const [form] = Form.useForm();

  const [createRequest] = useCreateRequestMutation();

  const onFinish = (values: {
    template_id: number;
    employee_ids: any;
    start_date?: string;
    end_date?: string;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      URL_request_type: "business_trip_requests",
      request_type: RequestsTypes.business_trip_requests,
      created_for: values?.employee_ids?.value ?? userData?.id,
      attachments: [],
      delegated_to: [],
    };

    if (values?.start_date) {
      // data.start_date = dayjs(data.start_date).format("YYYY-MM-DD");
      // data.end_date = dayjs(data.end_date).format("YYYY-MM-DD");

      data.start_date = dayjs(data.start_date).format("YYYY-MM-DDThh:mm");
      data.end_date = dayjs(data.end_date).format("YYYY-MM-DDThh:mm");
    }

    createRequest(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request created successfully!" });
        setIsVisible(false);
      });
  };

  const [customEndDateError, setcustomEndDateError] = useState(false);

  const handleDisabledFields = (changedFields: any, allValues: any) => {
    if (allValues.start_date && "end_date" in changedFields) {
      const start_date = moment(allValues.start_date);
      const end_date = moment(changedFields.end_date);
      const isAfterStart = end_date.isAfter(start_date);
      if (isAfterStart) {
        setcustomEndDateError(false);
      } else {
        setcustomEndDateError(true);
      }
    }
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Request Business trip"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleDisabledFields}
          >
            {!isMultipleAssign ? (
              <div className={styles.memberContent}>
                <UserInfo data={userData} />
              </div>
            ) : (
              <Form.Item
                name="employee_ids"
                label="Assignees Members"
                rules={[{ required: true }]}
              >
                <AssigneeSelection isMulti={false} />
              </Form.Item>
            )}

            <Form.Item
              name="start_date"
              label="Start Date and Time"
              rules={[{ required: true }]}
            >
              <DatePickerInput shouldShowTime />
            </Form.Item>

            <Form.Item
              name="end_date"
              label="End Date and Time"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!customEndDateError) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "End date must be after start date"
                      );
                    }
                  },
                },
              ]}
            >
              <DatePickerInput shouldShowTime />
            </Form.Item>

            <Form.Item name="reason">
              <TextInput type="textarea" placeholder="Reason For Request" />
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
