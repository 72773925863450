import { useState } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import COLORS from "constants/Colors";
import DateFilter from "components/modals/DateFilter";
import Button from "../Button";
import { DateFilterPaperProps } from "./types";
import Paper from "../PaperComponent";
import styles from "./styles.module.scss";
import Text from "../Text";
import Icon from "../Icon";

export default function DateFilterPaper({
  containerStyle,
  isRange,
  children,
}: DateFilterPaperProps) {
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get("startDate")
    ? dayjs(searchParams.get("startDate")).format("DD-MM-YYYY")
    : dayjs().format("DD-MM-YYYY");

  const endDate = searchParams.get("endDate")
    ? dayjs(searchParams.get("endDate")).format("DD-MM-YYYY")
    : dayjs().add(1, "day").format("DD-MM-YYYY");

  return (
    <Paper
      backgroundColor={COLORS.lightDangerBackground}
      borderColor={COLORS.lightDangerBackground}
      paddingVertical={20}
      paddingHorizontal={32}
    >
      {children}
      {!!children && <hr />}
      <div className={`${styles.container} ${containerStyle}`}>
        <div className={styles.period}>
          <Text className={styles.periodText}>
            {isRange ? "Period from" : "Day"}:
          </Text>
          <Text className={styles.periodDate}>{startDate}</Text>
          {isRange && (
            <>
              <Text className={styles.periodText}>To</Text>
              <Text className={styles.periodDate}>{endDate}</Text>
            </>
          )}
        </div>
        <Button
          onClick={() => setIsDateFilterVisible(true)}
          btnClassName={styles.filterBtn}
          suffix={<Icon name="calendar" color="grey" size={24} />}
        >
          Filter By Date
        </Button>
        <DateFilter
          setIsVisible={() => setIsDateFilterVisible(false)}
          isVisible={isDateFilterVisible}
          isRange={isRange}
        />
      </div>
    </Paper>
  );
}
