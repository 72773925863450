import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Image from "components/general/Image";
import DownloadImage from "assets/icons/download.svg";

import {
  useGetStaffRequestQuery,
  useGetTransferRequestMoreDetailsQuery,
} from "apis/services/requests";
import { RequestProps } from "apis/types/requests";
// import { Requests, RequestsStatus } from "constants/Requests";
import ManageRequest from "components/modals/ManageRequest";
import { TableItemProps } from "components/general/Table/types";
import Button from "components/general/Button";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import StatusBadge from "components/general/StatusBadge";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import IMPORTANT_VARS from "constants/ImportantVars";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import getClassNames from "utils/getClassName";
import UploadFiles from "components/modals/UploadShift";
import styles from "./styles.module.scss";

export default function TeamRequests() {
  const navigate = useNavigate();
  const [isModalShown, setIsModalShown] = useState(false);
  const [bulkModal, setbulkModal] = useState(false);
  const [requestedData, setRequestedData] = useState<RequestProps | null>(null);
  const [searchParams] = useSearchParams();
  const { data, isFetching: isStaffLoading } = useGetStaffRequestQuery({
    page: Number(searchParams.get("page") ?? "1"),
    department: searchParams.get("department") || undefined,
    status: searchParams.get("status") || undefined,
    location: searchParams.get("location") || undefined,
    default_shift_type: searchParams.get("shift") || undefined,
    search: searchParams.get("search") || undefined,
    start_date: searchParams.get("startDate") || undefined,
    end_date: searchParams.get("endDate") || undefined,
    page_size: IMPORTANT_VARS.TabelDataPageSize,
  });

  const { data: selectedTransferRequestDetails } =
    useGetTransferRequestMoreDetailsQuery(requestedData as RequestProps, {
      skip: requestedData?.request_type !== "transfer",
    });

  const onEditRequestFunction = (request: RequestProps) => () => {
    setRequestedData(request);
    setIsModalShown(true);
  };

  const getMenuItems = (request: RequestProps): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Request",
      actionFn: onEditRequestFunction(request),
    },
  ];

  const changeStaffData = (item: RequestProps): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item.created_for} isTableCol />,
      },
      {
        type: "text",
        dataItem: String(item?.id),
      },
      {
        type: "text",
        dataItem: item?.request_type,
      },
      {
        type: "text",
        dataItem: item?.date ?? item?.start_date,
      },
      { type: "text", dataItem: item?.date ?? item?.end_date },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper menuData={getMenuItems(item)}>
            <StatusBadge
              text={item?.status}
              color={item?.status === "accepted" ? "success" : "warning"}
              containerStyle={styles.statusBadge}
            />
          </MenuTableDataWrapper>
        ),
      },
    ],
  });

  return (
    <div className={getClassNames([styles.container])}>
      <SecondaryPageHeader
        title="Team Requests"
        RightComponent={
          <div className="d-flex flex-row">
            <Button
              isOutline
              btnClassName="mx-2"
              onClick={() => setbulkModal(true)}
            >
              <Image src={DownloadImage} /> Bulk New request
            </Button>
            <Button onClick={() => navigate("create-new-request")}>
              Apply New Request
            </Button>
          </div>
        }
      />
      <UploadFiles
        isVisible={bulkModal}
        setIsVisible={() => setbulkModal(false)}
        page="requests"
      />
      <div className={styles.tableContainer}>
        <Table
          isCheckBoxVisible
          showSearchFilter
          isLoading={isStaffLoading}
          headers={[
            "Members ID",
            "Request ID",
            "Type",
            "Start Date",
            "End Date",
            "Status",
          ]}
          data={data?.results.map((item) => changeStaffData(item)) ?? []}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
      {requestedData && (
        <ManageRequest
          request={requestedData}
          transferAllocations={
            selectedTransferRequestDetails?.transfer_allocations
          }
          setIsVisible={setIsModalShown}
          isVisible={isModalShown}
          is_my_request={false}
        />
      )}
    </div>
  );
}
