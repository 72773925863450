import { Modal } from "antd";
import Button from "components/general/Button";
import { ModalWrapperProps } from "./types";
import styles from "./styles.module.scss";

export default function ModalWrapper({
  children,
  size,
  headerTitle,
  isFooterHidden = false,
  onConfirm,
  isVisible = false,
  setIsVisible,
  isLoading,
  CustomFooterComponent,
  isBtnDisabled,
}: ModalWrapperProps) {
  if (!isVisible) return null;

  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      className={styles.modal}
      footer={null}
      title={headerTitle}
    >
      <div className={`${styles.modalBox} ${styles[size]}`}>
        {/* Modal Body */}
        <div className={`${styles.modalContainer} ${styles.body}`}>
          {children}
        </div>
        {/* Modal Footer */}
        {!isFooterHidden && (
          <div className={`${styles.modalContainer} ${styles.footer}`}>
            <Button
              onClick={onConfirm}
              isLoading={isLoading}
              disabled={isBtnDisabled}
            >
              Confirm
            </Button>
          </div>
        )}
        {!!CustomFooterComponent && (
          <div className={`${styles.modalContainer} ${styles.footer}`}>
            {CustomFooterComponent}
          </div>
        )}
      </div>
    </Modal>
  );
}
