import { useState } from "react";
import { Form } from "antd";
import showSuccessMsg from "utils/showSuccessMsg";
import TextInput from "components/inputs/TextInput";
import {
  useAddShiftWorkinghoursMutation,
  useEditShiftWorkinghoursMutation,
} from "apis/services/workingHour";
import { AddWorkingHourProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

export default function AddWorkingHour({
  containerStyle,
  isVisible,
  setIsVisible,
  isUpdate,
  initValues = {},
}: AddWorkingHourProps) {
  const [form] = Form.useForm();
  const [tolerance, setTolerance] = useState({
    hrs: initValues?.number_of_hrs || 0,
    start: initValues?.tolerance_after_start || 0,
    end: initValues?.tolerance_before_end || 0,
  });
  const [addWorkingHour] = useAddShiftWorkinghoursMutation();
  const [editWorkingHour] = useEditShiftWorkinghoursMutation();

  const onFinish = (values: {
    name: string;
    number_of_hrs: string;
    start_date?: string;
    end_date?: string;
  }) => {
    const requiredMutation = isUpdate ? editWorkingHour : addWorkingHour;

    requiredMutation({ ...initValues, ...values })
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: isUpdate
            ? "Working hour edited successfully!"
            : "Working hour added successfully!",
        });
        setIsVisible(false);
      });
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if ("number_of_hrs" in changedValues) {
      setTolerance({ ...tolerance, hrs: allValues.number_of_hrs });
    }
    if ("tolerance_after_start" in changedValues) {
      setTolerance({ ...tolerance, start: allValues.tolerance_after_start });
    }
    if ("tolerance_before_end" in changedValues) {
      setTolerance({ ...tolerance, end: allValues.tolerance_before_end });
    }
  };

  const validateTolerance = (field = "start") => {
    if (
      // @ts-ignore
      tolerance[field] >= tolerance.hrs * 60 ||
      tolerance.start + tolerance.end >= tolerance.hrs * 60
    ) {
      return Promise.reject(
        new Error("Tolerance cannot be more than working hours")
      );
    }
    return Promise.resolve();
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Add New Working Hour"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initValues}
            onValuesChange={handleFormChange}
          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <TextInput />
            </Form.Item>

            <Form.Item
              name="number_of_hrs"
              label="Working Hour"
              rules={[{ required: true }, { type: "number", min: 1 }]}
            >
              <TextInput isNumberInput />
            </Form.Item>

            <Form.Item
              name="tolerance_after_start"
              label="Tolerance after Start"
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              rules={[{ validator: () => validateTolerance("start") }]}
            >
              <TextInput isNumberInput placeholder="Tolerance before Start" />
            </Form.Item>

            <Form.Item
              name="tolerance_before_end"
              label="Tolerance before End"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
              rules={[{ validator: () => validateTolerance("start") }]}
            >
              <TextInput isNumberInput placeholder="Tolerance before End" />
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
