import { useState } from "react";
import { message, Steps } from "antd";
import styles from "./styles.module.scss";
import Button from "../Button";

export default function CustomSteps({
  steps,
  containerStyle,
  filesLength,
  customButtonClick,
  handleClose,
  onStepChange,
  onUploadHandler,
}: any) {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
    onStepChange(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    onStepChange(current - 1);
  };

  const onUploadClick = () => {
    onUploadHandler(() => {
      message.success("Processing complete!");
      handleClose(false);
    });
  };

  /* 
// @ts-ignore */
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.contentSpace}>
        <Steps
          className={styles.contentSpace}
          current={current}
          items={items}
        />
        <div className={styles.contentContainer}>{steps[current].content}</div>
      </div>

      <hr />
      <div className={styles.containerButtonsGroup}>
        <div className={styles.buttonsGroup}>
          {current < steps.length - 1 && (
            <Button onClick={() => next()}>Next</Button>
          )}

          {current === steps.length - 1 && (
            <Button disabled={!filesLength} onClick={onUploadClick}>
              Upload
            </Button>
          )}

          <Button isOutline onClick={customButtonClick}>
            {steps[current].buttonText}
          </Button>
        </div>

        {current > 0 && (
          <Button btnClassName={styles.backButton} onClick={() => prev()}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
}
