import styles from "./styles.module.scss";
import { SeparatedRowElementsProps } from "./types";

export default function SeparatedRowElements({
  containerStyle,
  children,
}: SeparatedRowElementsProps) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>{children}</div>
  );
}
