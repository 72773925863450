import UserForm from "components/pages/settings/UserForm";
import styles from "./styles.module.scss";

export default function AddUser() {
  return (
    <div className={styles.container}>
      <UserForm />
    </div>
  );
}
