/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import { AllStaffShiftsProps } from "apis/types/shift";
import Menu from "components/Menu";
import Icon from "components/general/Icon";
import StatusBadge from "components/general/StatusBadge";
import EditOvertime from "components/modals/EditOvertime";
import styles from "./styles.module.scss";

export default function StatusView({
  checkin,
  type,
  employee,
  show_status = true,
  status = false,
  custom_data,
}: {
  checkin: AllStaffShiftsProps;
  type?: string;
  employee?: any;
  show_status?: boolean;
  status?: boolean;
  custom_data?: any;
}) {
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const MenuData = [
    {
      key: "profile",
      label: (
        <Link
          to={`/staff/${
            checkin?.employee?.id ?? checkin?.employee?.id
          }/overtime`}
          className={styles.view_profile}
        >
          View Profile
        </Link>
      ),
      shown: type !== "edit_overtime",
    },
    {
      key: "overtime",
      label: (
        <button
          onClick={() => setIsDetailsShown(true)}
          className={styles.view_profile}
        >
          Edit Overtime
        </button>
      ),
      shown: type === "edit_overtime",
    },
  ];

  const modified_custom_data = {
    overtime: custom_data,
    employee: employee,
  };

  const checkin_data = modified_custom_data || checkin;

  return (
    <div className={styles.statusContainer}>
      {show_status && (
        <StatusBadge
          iconType={
            checkin_data?.overtime?.status || status ? "success" : "fail"
          }
          color={
            checkin_data?.overtime?.status || status ? "success" : "warning"
          }
        />
      )}
      <Menu items={MenuData.filter((item) => !!item.shown)}>
        <button className={styles.memberActionBtn}>
          <Icon name="Menu" size={24} color="info" />
        </button>
      </Menu>
      {isDetailsShown && (
        <EditOvertime
          isVisible={isDetailsShown}
          setIsVisible={() => setIsDetailsShown(false)}
          shift={checkin_data}
          isUpdate
        />
      )}
    </div>
  );
}
