import MainLayout from "components/wrappers/MainLayout";
import useGenerateRoutes from "routes/useGenerateRoutes";

function App() {
  const routes = useGenerateRoutes();

  return <MainLayout>{routes}</MainLayout>;
}

export default App;
