import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Text from "components/general/Text";
import Paper from "components/general/PaperComponent";
import ManageRequest from "components/modals/ManageRequest";
import ConflictLocation from "components/modals/ConflictLocation";
import COLORS from "constants/Colors";
import {
  useGetNotificationsQuery,
  useGetSpecificStaffFromAllStaffQuery,
} from "apis/services/staff";
import { useLazyGetRequestQuery } from "apis/services/requests";
import { useLazyGetConflictDetialsQuery } from "apis/services/shift";
import { useGetLocationQuery } from "apis/services/locations";
import ActivityCard from "components/cards/ActivityCard";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";

export default function Notifications() {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isConflictModalVisible, setIsConflictModalVisible] = useState(false);
  const [conflictDateAndTime, setConflictDateAndTime] = useState({});
  const navigate = useNavigate();

  const { userInfo } = useGetUserInfo();

  const { data } = useGetNotificationsQuery({});

  const [trigger, { data: requestData }] = useLazyGetRequestQuery();

  const [getConflictDetails, { data: conflictData, isLoading }] =
    useLazyGetConflictDetialsQuery();

  const { data: staffDetails, isLoading: getStaffDeatilsLoading } =
    useGetSpecificStaffFromAllStaffQuery(Number(conflictData?.employee), {
      skip: !conflictData || isLoading,
    });

  const { data: currentLocationData, isLoading: currentLocationLoading } =
    useGetLocationQuery(
      { id: conflictData?.location },
      {
        skip: !conflictData || isLoading,
      }
    );

  useEffect(() => {
    if (
      !currentLocationLoading &&
      !getStaffDeatilsLoading &&
      currentLocationData &&
      staffDetails
    ) {
      setIsConflictModalVisible(true);
    }
  }, [
    currentLocationLoading,
    getStaffDeatilsLoading,
    staffDetails,
    currentLocationData,
  ]);

  const handleRedirectNotification = (notification: any) => {
    if (
      notification?.extra?.id &&
      !["delegation", "conflict", "new_location"].includes(
        notification?.slug
      ) &&
      !["calendar", "shift"].includes(notification?.extra?.slug)
    ) {
      trigger(notification?.extra?.id).then(() => {
        setIsModalShown(true);
      });
    } else if (notification?.extra?.slug === "calendar") {
      navigate(`/staff/${userInfo?.id}/calender`);
    } else if (notification?.extra?.slug === "shift") {
      navigate("/shifts");
    } else if (notification?.extra?.slug === "conflict") {
      if (conflictData && conflictData?.id === notification?.extra?.id) {
        setIsConflictModalVisible(true);
      } else {
        setConflictDateAndTime({
          date: notification?.date,
          time: notification?.time,
        });
        getConflictDetails({ id: notification?.extra?.id });
      }
    }
  };

  return (
    <>
      <div className={`${styles.container} p-5`}>
        <div className="mb-3">
          <Text className={styles.bold}>Notifications</Text>
        </div>
        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingVertical={20}
          paddingHorizontal={32}
        >
          {/* @ts-ignore */}
          {data?.results?.map((item) => (
            <div
              key={item.id}
              className="mb-3"
              onClick={() => handleRedirectNotification(item)}
              onKeyDown={() => handleRedirectNotification(item)}
              role="button"
              title="notification card"
              tabIndex={0}
            >
              <ActivityCard item={item} isNotificaion />
            </div>
          ))}
        </Paper>
      </div>

      {requestData && (
        <ManageRequest
          request={requestData}
          transferAllocations={requestData?.transfer_allocations}
          setIsVisible={setIsModalShown}
          isVisible={isModalShown}
        />
      )}

      <ConflictLocation
        user={{
          conflict: conflictData,
          employee: staffDetails,
          currentLocation: currentLocationData,
          dateAndTime: conflictDateAndTime,
        }}
        setIsVisible={setIsConflictModalVisible}
        isVisible={isConflictModalVisible}
      />
    </>
  );
}
