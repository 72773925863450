import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import styles from "./styles.module.scss";

export default function SingleSelection<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  onChange,
  value,
  placeholder = "Select",
  ...props
}: any) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (search: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(search).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <div className={styles.container}>
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        placeholder={placeholder}
        showSearch
        notFoundContent={
          fetching ? (
            <div className="d-flex justify-content-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        {...props}
        value={value}
        onChange={(e, onChangeoptions) => {
          onChange?.(e?.target?.value || e.value, onChangeoptions);
        }}
        options={options}
      />
    </div>
  );
}
