import TabsLayout from "components/general/TabsLayout";
import ProfileInfo from "./info";
import ProfileCalender from "./calender";
import ProfileLeaveBalance from "./balance";
import ProfileOvertime from "./overtime";
import styles from "./styles.module.scss";

export default function MyProfile() {
  return (
    <div className={`${styles.container} p-5`}>
      <TabsLayout
        position="top"
        tabs={[
          {
            label: "Basic information",
            key: "basic_information",
            children: <ProfileInfo />,
          },
          {
            label: "View Calendar",
            key: "view_calendrer",
            children: <ProfileCalender />,
          },
          {
            label: "Leave balance",
            key: "leave_balance",
            children: <ProfileLeaveBalance />,
          },
          {
            label: "Overtime",
            key: "overtime",
            children: <ProfileOvertime />,
          },
        ]}
      />
    </div>
  );
}
