import { Form } from "antd";
import ModalWrapper from "components/modals/ModalWrapper";
import TextInput from "components/inputs/TextInput";
import showSuccessMsg from "utils/showSuccessMsg";
import SelectionInput from "components/inputs/SelectionInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import {
  usePostPublicHolidayMutation,
  useUpdatePublicHolidayMutation,
} from "apis/services/other";
// import { AddPublicHolidayProps } from "./types";

export default function AddPublicHoliday({
  isVisible,
  setIsVisible,
  isUpdate,
  initValues,
}: any) {
  const [form] = Form.useForm();
  const [postPublicHoliday, { isLoading }] = usePostPublicHolidayMutation();
  const [updatePublicHoliday, { isLoading: isUpdatingTemplate }] =
    useUpdatePublicHolidayMutation();

  const addNewZone = (values: any) => {
    postPublicHoliday({ ...values, enabled: true })
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added new public holiday successfully!" });
      });
  };

  const updateHoliday = (values: any) => {
    updatePublicHoliday({ ...values, id: initValues.id, enabled: true })
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Edited public holiday successfully!" });
      });
  };

  const handleConfirm = (values: any) => {
    if (isUpdate) {
      updateHoliday(values);
    } else {
      addNewZone(values);
    }
  };

  const submitForm = () => {
    form.submit();
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle="Public Holiday"
      onConfirm={submitForm}
      isLoading={isLoading || isUpdatingTemplate}
    >
      <Form
        form={form}
        onFinish={handleConfirm}
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item
          name="name"
          label="Holiday Name"
          rules={[{ required: true }]}
          initialValue={isUpdate ? initValues.name : undefined}
        >
          <TextInput />
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          initialValue={isUpdate ? initValues.date : undefined}
        >
          <DatePickerInput />
        </Form.Item>
        <Form.Item
          name="rate"
          label="Rate"
          initialValue={isUpdate ? initValues.rate : undefined}
        >
          <SelectionInput
            options={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="reason_for_leave"
          label="Description"
          initialValue={isUpdate ? initValues.reason_for_leave : undefined}
        >
          <TextInput type="textarea" />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
