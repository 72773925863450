import { useSearchParams } from "react-router-dom";
import {
  useGetNewStaffOvertimesQuery,
  useGetOverTimeStatisticsQuery,
  // useGetMyOverTimeQuery,
  // useGetStaffOvertimesQuery,
} from "apis/services/overtime";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import InfoRow from "components/general/InfoRow";
import Button from "components/general/Button";
import UserInfo from "components/cards/UserInfo";
import Table from "components/general/Table";
import StatusView from "components/pages/calender/status-view";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";

export default function Overtime() {
  const [searchParams] = useSearchParams();
  const { role } = useGetUserInfo();

  // const getOverTime =
  //   role === "employee" ? useGetMyOverTimeQuery : useGetStaffOvertimesQuery;

  const { data, isFetching: isEmployeeShiftsLoading } =
    useGetNewStaffOvertimesQuery(
      {
        page: searchParams.get("page") ?? 1,
        search: searchParams.get("search") ?? "",
        start_date: searchParams.get("startDate") ?? "",
        end_date: searchParams.get("endDate") ?? "",
        department: searchParams.get("department") || undefined,
        zone: searchParams.get("zone") || undefined,
        project: searchParams.get("project") || undefined,
      },
      { skip: !role }
    );
  const { data: overtimeStatistics } = useGetOverTimeStatisticsQuery(
    {},
    { skip: !role }
  );

  const INFODATA = [
    {
      title: "Total overtime",
      info: overtimeStatistics?.total_overtime?.toFixed(2) ?? 0,
    },
    {
      title: "Paid",
      info: overtimeStatistics?.paid_hrs?.toFixed(2) ?? 0,
    },
    {
      title: "Unpaid",
      info: overtimeStatistics?.unpaid_hrs?.toFixed(2) ?? 0,
    },
    {
      title: "Leave Balance",
      info: overtimeStatistics?.hrs_added_to_leave_balance?.toFixed(2) ?? 0,
    },
  ];

  const changeStaffData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: (
          <UserInfo
            data={
              {
                ...item,
                user: {
                  first_name: item.username.split(" ")?.[0] || "",
                  last_name: item.username.split(" ")?.[1] || "",
                },
              } ?? item.conflict?.employee
            }
            isTableCol
          />
        ),
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.total_over_time) ?? "--"}h`,
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.total_unpaid_over_time) ?? "--"}h`,
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.total_leave_balance_over_time) ?? "--"}h`,
      },
      {
        type: "element",
        dataItem: (
          <StatusView
            checkin={
              { ...item.shift, employee: { id: item.id } } ?? item.conflict
            }
            show_status={false}
          />
        ),
      },
    ],
  });

  const SubTitleComponent = searchParams.get("startDate") && (
    <div className="d-block">
      Perid from: {searchParams.get("startDate")} To{" "}
      {searchParams.get("endDate")}
    </div>
  );

  return (
    <div className={styles.container}>
      <section>
        <SecondaryPageHeader
          title="Overtime"
          SubTitleComponent={SubTitleComponent}
          RightComponent={
            role &&
            role !== "employee" && <Button onClick={() => {}}>Export</Button>
          }
        />
      </section>
      <section>
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} Hrs`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </section>

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          headers={[
            "Members ID",
            "Total Overtime",
            "Unpaid",
            "Leave balance",
            // "Status",
          ]}
          data={data?.results?.map((item: any) => changeStaffData(item)) ?? []}
          isLoading={isEmployeeShiftsLoading}
          count={data?.count}
        />
      </div>
    </div>
  );
}
