// import Text from "components/general/Text";
import CustomTable from "components/general/CustomTable";
import Button from "components/general/Button";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import ImportImage from "assets/icons/import.svg";
import DownloadImage from "assets/icons/download.svg";
import { useGetCalenderLeaveBalanceQuery } from "apis/services/other";

import { useSearchParams } from "react-router-dom";
import Image from "components/general/Image";
import styles from "./styles.module.scss";

export default function LeaveBalance() {
  const [searchParams] = useSearchParams();

  const { data, isFetching } = useGetCalenderLeaveBalanceQuery({
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    start_date: searchParams.get("startDate") ?? "",
    end_date: searchParams.get("endDate") ?? "",
    department: searchParams.get("department") || undefined,
  });
  const RightComponent = (
    <div className="d-flex flex-row">
      <Button isOutline btnClassName={`mx-2 ${styles.headerBtn}`}>
        <Image src={DownloadImage} />
      </Button>
      <Button isOutline btnClassName={styles.headerBtn}>
        <Image src={ImportImage} />
      </Button>
    </div>
  );

  return (
    <section>
      <div className={styles.container}>
        <SecondaryPageHeader
          title="Leave Balance"
          RightComponent={RightComponent}
        />
        <CustomTable
          loading={isFetching}
          count={data?.count}
          data={data?.results}
        />
      </div>
    </section>
  );
}
