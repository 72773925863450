import { Link } from "react-router-dom";
import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import loginHandler from "utils/loginHandler";
import { useLoginMutation } from "apis/services/auth";
import HandleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

export default function Login() {
  const [login] = useLoginMutation();

  const onLoginHandler = (values: any) => {
    login({ cfm_id: values.cfm_id, password: values.password })
      .unwrap()
      .then((res) => {
        if (
          res.role === "manager" ||
          res.role === "admin" ||
          res.role === "employee"
        ) {
          loginHandler({
            token: res.access_token,
            refreshToken: res.refersh_token,
          });
        } else {
          HandleErrors(["You are not role manager."]);
        }
      });
  };

  return (
    <>
      <Text className={styles.title}>Welcome Back</Text>
      <Text className={styles.secondaryTitle}>Log in to continue</Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={onLoginHandler}
      >
        <Form.Item name="cfm_id" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="CFM_ID" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="PASSWORD" type="password" />
        </Form.Item>

        <Button btnClassName={styles.submitBtn} type="submit">
          Log in
        </Button>
      </Form>

      <Link className={styles.forgotPassword} to="/auth/forgot-password">
        Forget password?
      </Link>
    </>
  );
}
