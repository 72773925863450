import api from "apis";

export const overtimeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStaffOvertimes: build.query({
      query: (params) => ({
        url: "/shifts/my_staff_overtimes/",
        params,
      }),
      providesTags: ["MemberOvertime"],
    }),
    getDetailedStaffOvertimes: build.query({
      query: (params) => ({
        url: "/my_staff_overtimes/detailed_staff_overtime/",
        params,
      }),
      providesTags: ["MemberOvertime"],
    }),
    getNewStaffOvertimes: build.query({
      query: (params) => ({
        url: "/my_staff_overtimes/",
        params,
      }),
      providesTags: ["MemberOvertime"],
    }),
    editNewStaffOvertimes: build.mutation({
      query: (params) => ({
        url: `/my_staff_overtimes/${params.id}`,
        method: "PATCH",
        params,
      }),
      invalidatesTags: ["MemberOvertime"],
    }),
    getStaffOvertimeID: build.query({
      query: (params) => ({
        url: `/leave-balance/over_time/?cfm_staff=${params.employee}`,
        // params,
      }),
      providesTags: ["MemberOvertime"],
    }),
    getStaffOvertimeReport: build.query({
      query: (params) => ({
        url: "/shifts/my_staff_overtimes/staff_total_overtime_report/",
        params,
        providesTags: ["MemberOvertime"],
      }),
    }),
    editMemberOvertime: build.mutation<void, any>({
      query: (body) => ({
        url: `/shifts/my_staff_overtimes/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["MemberOvertime"],
    }),
    getMyOverTime: build.query({
      query: (params) => ({
        url: "/shifts/my_overtimes/",
        params,
      }),
      providesTags: ["MyOvertime"],
    }),
    getOverTimeStatistics: build.query({
      query: (params) => ({
        url: `/overtimes/statistics/`,
        params,
      }),
      // providesTags: ["MyOvertime"],
    }),
    getOverTimes: build.query({
      query: (params) => ({
        url: `/overtimes/`,
        params,
      }),
      providesTags: ["MemberOvertime"],
    }),
  }),
});

export const {
  useGetStaffOvertimesQuery,
  useGetDetailedStaffOvertimesQuery,
  useGetNewStaffOvertimesQuery,
  useEditNewStaffOvertimesMutation,
  useGetStaffOvertimeReportQuery,
  useEditMemberOvertimeMutation,
  useGetMyOverTimeQuery,
  useGetStaffOvertimeIDQuery,
  useGetOverTimeStatisticsQuery,
  useGetOverTimesQuery,
} = overtimeApi;
