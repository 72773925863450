import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Outlet } from "react-router-dom";

function Requests({ list }: { list: any[] }) {
  const { role } = useGetUserInfo();

  const manipulatedResuestList =
    role === "employee"
      ? list?.filter((item) => item.path !== "team Requests")
      : list;

  return (
    <SecondarySidebarLayout list={manipulatedResuestList} title=" ">
      <Outlet />
    </SecondarySidebarLayout>
  );
}

export default Requests;
