/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import getClassNames from "utils/getClassName";
import Button from "components/general/Button";
import Image from "components/general/Image";
import ADD_USER from "assets/icons/add-user.svg";
import ImportImage from "assets/icons/import.svg";
import DownloadImage from "assets/icons/download.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
import {
  useDeleteStaffMutation,
  useGetAllStaffQuery,
  useGetMyStaffQuery,
} from "apis/services/staff";
import moment from "moment";
import { TableItemProps } from "components/general/Table/types";
import UserInfo from "components/cards/UserInfo";
import StatusBadge from "components/general/StatusBadge";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import showSuccessMsg from "utils/showSuccessMsg";
import toCapitalize from "utils/toCapitalize";
import IMPORTANT_VARS from "constants/ImportantVars";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";

export default function Users({
  title = "",
  isAddUserHidden = false,
  isForDepartments = false,
}) {
  const navigate = useNavigate();

  const { isAdmin, userInfo } = useGetUserInfo();

  const useGetConditinalStaff = isAdmin
    ? useGetAllStaffQuery
    : useGetMyStaffQuery;

  const [searchParams] = useSearchParams();
  const { data, isFetching: isStaffLoading } = useGetConditinalStaff(
    {
      page: Number(searchParams.get("page") ?? "1"),
      department: searchParams.get("department") || undefined,
      status: searchParams.get("status") || undefined,
      location: searchParams.get("location") || undefined,
      default_shift_type: searchParams.get("shift") || undefined,
      search: searchParams.get("search") || undefined,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    },
    { skip: !userInfo }
  );

  const [deleteUser, { isLoading: isDeletingStaffLoading }] =
    useDeleteStaffMutation();

  const goToAddUser = () => {
    navigate("/settings/user-access-control/users/add");
  };

  const onGoToEditUser = (id?: number) => {
    if (!id) return;

    navigate(`/settings/user-access-control/users/${id}`, {
      state: { canBack: true },
    });
  };

  const onGetDeleteUserFunction = (userId: number) => () => {
    deleteUser(userId)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Staff has been deleted successfully!" });
      });
  };

  const getMenuItems = (userId: number): MenuDataItem[] => [
    {
      key: "delete",
      label: "Delete User",
      actionFn: onGetDeleteUserFunction(userId),
      isDanger: true,
      isLoading: isDeletingStaffLoading,
    },
  ];

  const changeStaffData = (item: any): TableItemProps => ({
    id: String(item.cfm_id),
    rowData: [
      {
        type: "element",
        dataItem: (
          <div
            onClick={() => {
              onGoToEditUser(item?.id);
            }}
            className="pointer"
          >
            <UserInfo
              data={{
                // @ts-ignore
                job_title: item?.job_title,
                // @ts-ignore
                cfm_id: item?.cfm_id,
                id: item?.id,
                user: {
                  first_name: item?.user?.first_name || "--",
                  last_name: item?.user?.last_name || "--",
                },
              }}
            />
          </div>
        ),
      },
      {
        type: "text",
        dataItem: moment(item?.date_of_joining).format("DD-MM-YYYY"),
      },
      {
        type: "text",
        dataItem: toCapitalize(item?.role) || "-",
      },
      { type: "text", dataItem: item?.default_project?.name || "-" },
      {
        type: "element",
        dataItem: isAdmin ? (
          <MenuTableDataWrapper menuData={getMenuItems(item?.id as number)}>
            <StatusBadge
              text={item?.user?.is_active ? "Active" : "Inactive"}
              color={item?.user?.is_active ? "success" : "warning"}
              containerStyle={styles.statusBadgeContanier}
            />
          </MenuTableDataWrapper>
        ) : (
          <StatusBadge
            text={item?.user?.is_active ? "Active" : "Inactive"}
            color={item?.user?.is_active ? "success" : "warning"}
            containerStyle={styles.statusBadgeContanier}
          />
        ),
      },
    ],
  });

  return (
    <div className={getClassNames([styles.container, "min-vh-100"])}>
      <SecondaryPageHeader
        title={title || "Users"}
        RightComponent={
          isAdmin && (
            <div className={styles.btnGroups}>
              {!isAddUserHidden && (
                <Button
                  isOutline
                  btnClassName={styles.headerBtn}
                  onClick={goToAddUser}
                >
                  <Image src={ADD_USER} />
                </Button>
              )}
              <Button isOutline btnClassName={styles.headerBtn}>
                <Image src={DownloadImage} />
              </Button>
              <Button isOutline btnClassName={styles.headerBtn}>
                <Image src={ImportImage} />
              </Button>
            </div>
          )
        }
      />

      <div className={styles.tableContainer}>
        <Table
          isCheckBoxVisible
          showSearchFilter
          isDataCentered
          isHeaderCentered
          isLoading={isStaffLoading}
          filtersToHide={["status", isForDepartments ? "department" : ""]}
          headers={[
            "Members ID",
            "Date of Joining",
            "Role(s)",
            "Default Project",
            "Account Status",
          ]}
          data={data?.results.map((item) => changeStaffData(item)) ?? []}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
