import api from "apis";

export const WorkingHourApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNormalShiftWorkinghours: build.query({
      query: (params) => ({
        url: "/shifts/working_hours/",
        params,
      }),
      providesTags: ["NormalShiftWorkingHours"],
    }),

    addShiftWorkinghours: build.mutation({
      query: (body) => ({
        url: "/shifts/working_hours/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["NormalShiftWorkingHours"],
    }),

    editShiftWorkinghours: build.mutation({
      query: (body) => ({
        url: `/shifts/working_hours/${body?.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["NormalShiftWorkingHours"],
    }),

    deleteShiftWorkinghours: build.mutation({
      query: (id) => ({
        url: `/shifts/working_hours/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["NormalShiftWorkingHours"],
    }),
  }),
});

export const {
  useAddShiftWorkinghoursMutation,
  useGetNormalShiftWorkinghoursQuery,
  useEditShiftWorkinghoursMutation,
  useDeleteShiftWorkinghoursMutation,
} = WorkingHourApi;
