import { useSearchParams } from "react-router-dom";
import Text from "components/general/Text";
import Button from "components/general/Button";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import InfoRow from "components/general/InfoRow";
import IMPORTANT_VARS from "constants/ImportantVars";
import UserInfo from "components/cards/UserInfo";
import StatusView from "components/pages/home/status-view";
import { AllStaffShiftsProps } from "apis/types/shift";
import { TableItemProps } from "components/general/Table/types";
import moment from "moment";
import useGetUserInfo from "hooks/useGetUserInfo";
import Table from "components/general/Table";
import {
  useGetAllMyStaffCheckinsQuery,
  useGetAllOwnCheckinsQuery,
} from "apis/services/checkin";
import {
  useGetShiftsStatisticsQuery,
  useGetStaffShiftsStatisticsQuery,
} from "apis/services/shift";
import Icon from "components/general/Icon";
import DateFilter from "components/modals/DateFilter";
import { useState } from "react";
import styles from "./styles.module.scss";

export default function Attendance() {
  const [searchParams] = useSearchParams();
  const { role } = useGetUserInfo();
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);

  const getStatistics =
    role === "employee"
      ? useGetShiftsStatisticsQuery
      : useGetStaffShiftsStatisticsQuery;

  // @ts-ignore
  const { data: statistics, isLoading: isStatisticLoading } = getStatistics(
    {
      start_date:
        searchParams.get("summaryStartDate") ??
        moment(new Date()).format("YYYY-MM-DD"),
      end_date:
        searchParams.get("summaryEndDate") ??
        moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
    },
    { skip: !role }
  );

  const getChckins =
    role === "employee"
      ? useGetAllOwnCheckinsQuery
      : useGetAllMyStaffCheckinsQuery;

  const { data: all_checkins, isFetching: isEmployeeShiftsLoading } =
    getChckins(
      {
        page: searchParams.get("page") ?? 1,
        search: searchParams.get("search") ?? "",
        status: searchParams.get("status") ?? "",
        location: searchParams.get("location") ?? undefined,
        department: searchParams.get("department") ?? undefined,
        shift_type: searchParams.get("shift") ?? undefined,
        start_date: searchParams.get("startDate") ?? "",
        end_date: searchParams.get("endDate") ?? "",
        page_size: "25",
      },
      { skip: !role }
    );

  const formateDate = (date: Date | string | null, format = "hh:mm A") =>
    date ? moment(date).format(format) : "-";

  /**
   * return "off" if the shift is off, otherwise return {returnedValue}
   */
  const holidayGaurd = (item: AllStaffShiftsProps, returnedValue: string) => {
    if (item?.specification === "holiday") {
      return "-";
    }
    return returnedValue;
  };

  const changeCheckinsData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item?.checked_staff} isTableCol />,
      },
      { type: "text", dataItem: item?.checked_staff?.department?.name ?? "-" },
      {
        type: "text",
        dataItem: formateDate(item?.date || item?.checkin_time, "DD-MM-YYYY"),
      },
      {
        type: "text",
        dataItem: holidayGaurd(
          item,
          formateDate(item?.checkin_time, "DD-MM-YYYY hh:mm A")
        ),
      },
      {
        type: "text",
        dataItem: holidayGaurd(
          item,
          formateDate(item?.checkout_time, "DD-MM-YYYY hh:mm A")
        ),
      },
      {
        type: "text",
        dataItem: holidayGaurd(
          item,
          `${item?.overtime?.total_overtime ?? item?.over_time ?? 0} h`
        ),
      },
      {
        type: "element",
        dataItem: (
          <StatusView handleClick={() => {}} checkin={item} page="attendance" />
        ),
      },
    ],
  });
  return (
    <section>
      <div className={styles.container}>
        <div className="d-flex align-items-center justify-content-between">
          <Text className={styles.bold}>Attendance</Text>
          {role && role !== "employee" && (
            <Button btnClassName={styles.export} onClick={() => {}}>
              Export
            </Button>
          )}
        </div>
        <div className="mt-5 d-flex align-items-center">
          <SeparatedRowElements containerStyle="flex-grow-1">
            {role && role !== "employee" && (
              <InfoRow
                title="Staff"
                // @ts-ignore
                info={statistics?.staff ?? "0"}
                isReverse
                infoFontSize={28}
                infoFontWeight="500"
              />
            )}

            <InfoRow
              title="Present"
              info={statistics?.present ?? "0"}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />

            <InfoRow
              title="Not In"
              info={statistics?.absent ?? "0"}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />

            <InfoRow
              title="Leaves and off"
              info={
                (
                  (Number(statistics?.leaves) || 0) +
                  (Number(statistics?.off) || 0)
                ).toString() ?? "0"
              }
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />
            {role && role !== "employee" && (
              <InfoRow
                title="No shift"
                info={statistics?.no_shift?.toString() ?? "0"}
                isReverse
                infoFontSize={28}
                infoFontWeight="500"
              />
            )}
          </SeparatedRowElements>
          <div>
            <Button
              onClick={() => setIsDateFilterVisible(true)}
              btnClassName={styles.filterBtn}
              suffix={<Icon name="calendar" color="grey" size={24} />}
            >
              {" "}
            </Button>
            <DateFilter
              setIsVisible={() => setIsDateFilterVisible(false)}
              isVisible={isDateFilterVisible}
              isRange={false}
              isPrimary={false}
            />
          </div>
        </div>
        <div className="mt-4">
          <Table
            isCheckBoxVisible
            showSearchFilter
            headers={[
              "Members ID",
              "Department",
              "Date",
              "In-Time",
              "Out-Time",
              "Overtime",
              "Status",
            ]}
            data={
              all_checkins?.results?.map((item: any) =>
                changeCheckinsData(item)
              ) ?? []
            }
            isLoading={isEmployeeShiftsLoading || isStatisticLoading}
            count={all_checkins?.count}
            pageSize={IMPORTANT_VARS.TabelDataPageSize}
          />
        </div>
      </div>
    </section>
  );
}
