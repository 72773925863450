/* eslint-disable */
import { useState } from "react";
import { ConfigProvider, Form, Tabs, TabsProps } from "antd";
import CLOCK_ICON from "assets/icons/clock.svg";
import TextInput from "components/inputs/TextInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import Paper from "components/general/PaperComponent";
import SelectionInput from "components/inputs/SelectionInput";
import showSuccessMsg from "utils/showSuccessMsg";
import {
  useCreateFixedTemplateMutation,
  useEditFixedShiftTemplateMutation,
} from "apis/services/fixedShiftTemplate";
import dayjs from "dayjs";
import { AddFixedTemplateProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

const Days = [
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];

function DayRow({ day, to_time, from_time, isUpdate }: any) {
  const initValue = day === "friday" ? "holiday" : "work";
  const [status, setStatus] = useState(isUpdate ? undefined : initValue);

  return (
    <tr>
      <td>{day}</td>
      <td>
        <Form.Item
          name={day}
          label=" "
          initialValue={isUpdate ? undefined : initValue}
        >
          <SelectionInput
            onChange={setStatus}
            options={[
              { label: "Work", value: "work" },
              { label: "Off", value: "holiday" },
            ]}
          />
        </Form.Item>
      </td>
      <td>
        <div className={styles.dateStyle}>
          {status === "holiday" ? (
            "Off"
          ) : (
            <>
              {from_time ?? "-"}
              <img
                className={styles["calender-icon"]}
                src={CLOCK_ICON}
                alt="calender"
              />
            </>
          )}
        </div>
      </td>
      <td>
        <div className={styles.dateStyle}>
          {status === "holiday" ? (
            "Off"
          ) : (
            <>
              {to_time ?? "-"}
              <img
                className={styles["calender-icon"]}
                src={CLOCK_ICON}
                alt="calender"
              />
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default function AddFixedTemplate({
  containerStyle,
  isVisible,
  setIsVisible,
  isUpdate,
  initValues = {},
}: AddFixedTemplateProps) {
  const init_from = dayjs(initValues.from_time, "HH:mm:ss");
  const init_to = dayjs(initValues.to_time, "HH:mm:ss");
  const init_combinedHrs = init_to.diff(init_from, "h", true);

  const [selectedTab, setSelectedTab] = useState("fixed");
  const [tolerance, setTolerance] = useState({
    hrs: init_combinedHrs || 0,
    start: initValues?.tolerance_after_start || 0,
    end: initValues?.tolerance_before_end || 0,
  });
  const [form] = Form.useForm();
  const from_time_value = Form.useWatch("from_time", form);
  const to_time_value = Form.useWatch("to_time", form);
  const [createFixedTemplate] = useCreateFixedTemplateMutation();
  const [editFixedTemplate] = useEditFixedShiftTemplateMutation();

  const onFinish = (values: {
    name: string;
    from_time?: string;
    to_time?: string;
    saturday: any;
    tolerance_after_start?: number;
    tolerance_before_end?: number;
  }) => {
    const data = { ...values };
    const {
      name,
      from_time,
      to_time,
      tolerance_after_start,
      tolerance_before_end,
      ...others
    } = data;

    const formatedFromTime = dayjs(from_time).isValid()
      ? dayjs(from_time).format("HH:mm:ss")
      : from_time;
    const formatedToTime = dayjs(to_time).isValid()
      ? dayjs(to_time).format("HH:mm:ss")
      : to_time;

    const fromTime = isUpdate ? formatedFromTime : from_time;
    const toTime = isUpdate ? formatedToTime : to_time;

    const transformedObject = {
      name,
      from_time: `${fromTime}.00`,
      to_time: `${toTime}.00`,
      tolerance_after_start: values?.tolerance_after_start ?? 0,
      tolerance_before_end: values?.tolerance_before_end ?? 0,
      days: {
        friday: { specification: "holiday" },
        monday: { specification: "work" },
        saturday: { specification: "work" },
        sunday: { specification: "work" },
        thursday: { specification: "work" },
        tuesday: { specification: "work" },
        wednesday: { specification: "work" },
      },
    } as any;

    Object.keys(others).forEach((key) => {
      const index = key as keyof typeof others;
      transformedObject.days[index] = { specification: others[index] };
    });

    if (isUpdate) {
      const fixedShiftDays: any[] = [];
      Object.keys(transformedObject?.days).forEach((key) => {
        fixedShiftDays.push({
          template: initValues?.fixed_shift_days?.find(
            (item: any) => item?.day === key
          )?.template,
          day: key,
          specification: transformedObject?.days[key]?.specification,
        });
      });
      transformedObject.fixed_shift_days = fixedShiftDays;
    }

    const fixedTemplateController = isUpdate
      ? editFixedTemplate
      : createFixedTemplate;

    fixedTemplateController({ ...initValues, ...transformedObject })
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: isUpdate
            ? "Fixed template created successfully!"
            : "Fixed template added successfully!",
        });
        setIsVisible(false);
      });
  };

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const getInitValues = () => {
    if (!initValues) return undefined;

    const editedValues = {
      ...initValues,
      from_time: dayjs(initValues?.from_time, "HH:mm:ss"),
      to_time: dayjs(initValues?.to_time, "HH:mm:ss"),
    };

    initValues?.fixed_shift_days?.forEach((item: any) => {
      editedValues[item?.day] = item?.specification;
    });

    return editedValues;
  };
  const handleFormChange = (changedValues: any, allValues: any) => {
    const from = dayjs(allValues.from_time, "HH:mm:ss");
    const to = dayjs(allValues.to_time, "HH:mm:ss");
    const combinedHrs = to.diff(from, "h", true);
    const tempObj = { ...tolerance };

    if ("to_time" in changedValues || "from_time" in changedValues) {
      tempObj.hrs = combinedHrs;
    }
    if ("tolerance_after_start" in changedValues) {
      tempObj.start = allValues?.tolerance_after_start;
    }
    if ("tolerance_before_end" in changedValues) {
      tempObj.end = allValues?.tolerance_before_end;
    }
    setTolerance(tempObj);
  };

  const validateTolerance = (field = "start") => {
    if (
      // @ts-ignore
      tolerance[field] >= tolerance.hrs * 60 ||
      tolerance.start + tolerance.end >= tolerance.hrs * 60
    ) {
      return Promise.reject(
        new Error("Tolerance cannot be more than working hours")
      );
    }
    return Promise.resolve();
  };

  const items: TabsProps["items"] = [
    {
      key: "fixed",
      label: "Fixed shifts",
      children: (
        <div className="pt-5">
          <Form.Item
            name="name"
            label="Shift Name"
            rules={[{ required: true }]}
          >
            <TextInput />
          </Form.Item>

          <Form.Item
            name="from_time"
            label="Shift Duration"
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            rules={[{ required: true }]}
          >
            <DatePickerInput picker="time" />
          </Form.Item>

          <Form.Item
            name="to_time"
            label=" "
            className={styles.to_time}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
            rules={[{ required: true, message: "Please enter Shift Duration" }]}
          >
            <DatePickerInput picker="time" />
          </Form.Item>

          <Form.Item
            name="tolerance_after_start"
            label="Tolerance after Start"
            // initialValue={0}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            rules={[{ validator: () => validateTolerance("start") }]}
          >
            <TextInput isNumberInput placeholder="Tolerance before Start" />
          </Form.Item>

          <Form.Item
            name="tolerance_before_end"
            label="Tolerance before End"
            // initialValue={0}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
            rules={[{ validator: () => validateTolerance("end") }]}
          >
            <TextInput isNumberInput placeholder="Tolerance before End" />
          </Form.Item>
        </div>
      ),
    },
    {
      key: "working",
      label: "Shift schedule",
      children: (
        <table className="my-5">
          <thead>
            <tr>
              <td>Days</td>
              <td>Sepcification</td>
              <td>Start Time</td>
              <td>End Time</td>
            </tr>
          </thead>
          <tbody>
            {Days.map((item) => (
              <DayRow
                key={item}
                day={item}
                from_time={
                  isUpdate
                    ? dayjs(from_time_value).format("HH:mm:ss")
                    : from_time_value
                }
                to_time={
                  isUpdate
                    ? dayjs(to_time_value).format("HH:mm:ss")
                    : to_time_value
                }
                isUpdate={isUpdate}
              />
            ))}
          </tbody>
        </table>
      ),
    },
  ];

  return (
    <ModalWrapper
      size="large"
      headerTitle="Add New Shifts"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={isUpdate ? getInitValues() : undefined}
            //     @ts-ignore
            onValuesChange={handleFormChange}
          >
            <Paper
              backgroundColor="white"
              borderColor="white"
              paddingVertical={32}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      fontSize: 16,
                      colorBgContainer: "#E3332D0D",
                      colorBorderSecondary: "#fffff",
                      colorFillAlter: "#fffff",
                      padding: 24,
                      margin: 0,
                    },
                  },
                }}
              >
                <Tabs
                  type="card"
                  items={items}
                  onChange={onChange}
                  prefixCls="tabContainer"
                  defaultActiveKey={selectedTab}
                />
              </ConfigProvider>
            </Paper>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
