// import { useState } from "react";
import Menu from "components/Menu";
import Icon from "components/general/Icon";
import AssignBalance from "components/modals/AssignBalance";
// import showSuccessMsg from "utils/showSuccessMsg";
// import { useDeleteAssignedShiftMutation } from "apis/services/shift";
// import { StaffActionProps } from "./types";
// import {
//   usePostLeaveBalancePolicyMutation,
//   useUpdateLeaveBalancePolicyMutation,
// } from "apis/services/other";
// import showSuccessMsg from "utils/showSuccessMsg";
import showSuccessMsg from "utils/showSuccessMsg";
import { useDeleteLeaveBalancePolicyMutation } from "apis/services/other";
import styles from "./styles.module.scss";

export default function CalenderAction({
  containerStyle,
  ItemData,
  tableData,
  settableData,
  isDetailsShown,
  setIsDetailsShown,
  detailModalType,
  setdetailModalType,
  policyID,
}: any) {
  const [deleteLeaveBalancePolicy] = useDeleteLeaveBalancePolicyMutation();

  const onDeleteBalance = () => {
    deleteLeaveBalancePolicy({ id: ItemData.id })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Balance deleted successfully!" });
      });
    // const tempArr = [...tableData];
    // tempArr.splice(ItemData.index, 1);
    // settableData(tempArr);
  };

  const MenuData = [
    {
      key: "edit_balance",
      label: (
        <button
          onClick={() => {
            setIsDetailsShown(true);
            setdetailModalType("edit");
          }}
          className={styles.memberActionBtn}
          type="button"
        >
          Edit Balance
        </button>
      ),
    },
    {
      key: "delete_balance",
      label: (
        <button
          onClick={onDeleteBalance}
          className={styles.memberActionBtn}
          type="button"
        >
          Delete Balance
        </button>
      ),
    },
  ];

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Menu items={MenuData}>
        <button className={styles.memberActionBtn} type="button">
          <Icon name="Menu" size={24} color="info" />
        </button>
      </Menu>
      {isDetailsShown && (
        <AssignBalance
          isVisible={isDetailsShown}
          setIsVisible={setIsDetailsShown}
          ItemData={ItemData}
          tableData={tableData}
          settableData={settableData}
          detailModalType={detailModalType}
          policyID={policyID}
        />
      )}
    </div>
  );
}
