import CFM_LOGO_SQUARE from "assets/images/logo-square.png";
import Image from "components/general/Image";
import { AuthLayoutProps } from "./types";
import styles from "./styles.module.scss";

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <section>
      <div className={styles.container}>
        <div className={styles.formSection}>
          <div className={styles.formContainer}>{children}</div>
        </div>
        <div className={styles.logoSection}>
          <Image
            className={styles.logoImage}
            src={CFM_LOGO_SQUARE}
            alt="logo-saquare"
          />
        </div>
      </div>
    </section>
  );
}
