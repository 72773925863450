export const ShiftOccuranceEnum = {
  Current: "current",
  Next: "next",
  previous: "previous",
};

export const ShiftType = {
  Fixed: 1,
  Based: 2,
  Flexible: 3,
};

export const ShiftTypeName = {
  [ShiftType.Fixed]: "Fixed Shift",
  [ShiftType.Based]: "Based Setup Shift",
  [ShiftType.Flexible]: "Flexible Shift",
};
