/* eslint-disable */
import Button from "components/general/Button";
import { useState } from "react";
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
} from "apis/services/locations";
import Table from "components/general/Table";
import { useSearchParams } from "react-router-dom";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Text from "components/general/Text";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import showSuccessMsg from "utils/showSuccessMsg";
import IMPORTANT_VARS from "constants/ImportantVars";
import styles from "./styles.module.scss";
import AddNewWorkflow from "components/modals/AddNewWorkflow";
import {
  useDeleteWorkflowMutation,
  useGetWorkflowQuery,
} from "apis/services/workflow";

export default function Workflow() {
  const [searchParams] = useSearchParams();

  const [isAddNewLocationModalVisible, setisAddNewLocationModalVisible] =
    useState(false);
  const [isEditWorkflowModal, setisEditWorkflowModal] = useState(false);
  const [requestedData, setRequestedData] = useState<any | null>(null);

  const { data: workflows, isFetching: isWorkflowLoading } =
    useGetWorkflowQuery({
      ordering: "-id",
      page: searchParams.get("page") ?? 1,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    });

  const [deleteWorkflow, { isLoading: isDeletingLocationLoading }] =
    useDeleteWorkflowMutation();

  const onGetDeleteWorkflowFunction = (ID: number) => () => {
    deleteWorkflow(ID)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "This location has been deleted successfully!" });
      });
  };
  const onEditWorkflowFunction = (workflow: any) => () => {
    setRequestedData(workflow);
    // setisAddNewLocationModalVisible(true);
    setisEditWorkflowModal(true);
  };

  const getWorkflowMenuItems = (workflow: any): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit",
      actionFn: onEditWorkflowFunction(workflow),
    },
    {
      key: "delete",
      label: "Delete",
      actionFn: onGetDeleteWorkflowFunction(workflow.id),
      isDanger: true,
      isLoading: isDeletingLocationLoading,
    },
  ];

  const tabelData =
    workflows?.results?.map((item: any) => ({
      id: item.id,
      rowData: [
        {
          dataItem: item?.zone_name,
          type: "text",
        },
        {
          dataItem: item?.project_name,
          type: "text",
        },
        {
          dataItem: item?.department_name,
          type: "text",
        },
        {
          dataItem: item?.job_classification_name || "-",
          type: "text",
        },
        {
          dataItem: item?.user_username,
          type: "text",
        },
        {
          type: "element",
          dataItem: (
            <MenuTableDataWrapper menuData={getWorkflowMenuItems(item)} />
          ),
        },
      ],
    })) || [];

  const onShowAddModal = () => {
    setRequestedData(null);
    setisAddNewLocationModalVisible(true);
  };
  const onCloseModal = () => {
    setisAddNewLocationModalVisible(false);
    setisEditWorkflowModal(false);
  };

  const tabelHeaders = [
    "Zone",
    "Default Project",
    "Department.",
    "Job Classification",
    "Person",
  ];
  const shouldShowModal = isAddNewLocationModalVisible || isEditWorkflowModal;
  return (
    <div>
      <SecondaryPageHeader
        title="Workflow"
        RightComponent={
          <Button btnClassName={styles.headerBtn} onClick={onShowAddModal}>
            Add Workflow
          </Button>
        }
      />

      <div>
        <Table
          containerStyle={styles.locationContainer}
          headers={tabelHeaders}
          isDataCentered
          isHeaderCentered
          isLoading={isWorkflowLoading}
          data={tabelData}
          isCheckBoxVisible={false}
          count={workflows?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
      {shouldShowModal && (
        <AddNewWorkflow
          isVisible={shouldShowModal}
          setIsVisible={onCloseModal}
          initialValues={requestedData}
          isUpdate={isEditWorkflowModal}
        />
      )}
    </div>
  );
}
