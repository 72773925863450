import { useState } from "react";
import { useLocation } from "react-router";
import Menu from "components/Menu";
import FilterSelectionModal from "components/modals/FilterSelectionModal";
import DateFilter from "components/modals/DateFilter";
import { ShiftType, ShiftTypeName } from "constants/ShiftType";
import {
  useGetDepartmentsQuery,
  useGetLocationsQuery,
  useGetProjectsQuery,
  useGetZonesQuery,
} from "apis/services/locations";
import Icon from "../Icon";
import { FilterButtonProps } from "./types";
import Button from "../Button";
import styles from "./styles.module.scss";

export default function FilterButton({
  containerStyle = "",
  onClick,
  filtersToHide = [],
}: FilterButtonProps) {
  const [isFilterModalVisible, setIsFilterVisible] = useState<boolean>(false);
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { data: departments } = useGetDepartmentsQuery({ page_size: 100 });
  const { data: locations } = useGetLocationsQuery({ page_size: 100 });
  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const { data: projects } = useGetProjectsQuery({
    page_size: 100,
  });
  const location = useLocation();

  const handleFilterClick = (name: string) => {
    setSelectedFilter(name);
    setIsFilterVisible(true);
  };

  const MenuData = [
    {
      key: "shift",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("shift")}
        >
          Filter by Shift
        </button>
      ),
      data: Object.values(ShiftType).map((item) => {
        const obj = {
          label: "",
          value: "",
        };
        obj.label = ShiftTypeName[item];
        obj.value = item.toString();
        return obj;
      }),
      shown: [
        "/",
        "/calender/attendance",
        "/settings/user-access-control/users",
        "/integration",
      ].includes(location.pathname),
      onClick: () => handleFilterClick("shift"),
    },
    {
      key: "status",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("status")}
        >
          Filter by Status
        </button>
      ),
      data: [
        {
          label: "IN",
          value: "Checked In",
        },
        {
          label: "OUT",
          value: "Checked Out",
        },
        {
          label: "NOT IN",
          value: "Not In",
        },
        {
          label: "OFF",
          value: "Off",
        },
      ],
      shown: [
        "/",
        // "/integration/Overtime",
        // "/integration/Transaction",
      ].includes(location.pathname),
      onClick: () => handleFilterClick("status"),
    },
    {
      key: "location",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("location")}
        >
          Filter by Location
        </button>
      ),
      data: locations?.results.map((item: any) => {
        const obj = {
          label: "",
          value: "",
        };
        obj.label = item.name;
        obj.value = item.id.toString();
        return obj;
      }),
      shown: [
        "/",
        "/staff",
        "/calender/attendance",
        "/settings/user-access-control/users",
      ].includes(location.pathname),
      onClick: () => handleFilterClick("location"),
    },
    {
      key: "department",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("department")}
        >
          Filter by Department
        </button>
      ),
      data: departments?.results.map((item: any) => {
        const obj = {
          label: "",
          value: "",
        };
        obj.label = item.name;
        obj.value = item.id.toString();
        return obj;
      }),
      shown: [
        "/",
        "/calender/overtime",
        "/calender/attendance",
        "/staff",
        "/settings/user-access-control/users",
      ].includes(location.pathname),
      onClick: () => handleFilterClick("department"),
    },
    {
      key: "zone",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("zone")}
        >
          Filter by Zone
        </button>
      ),
      data: zones?.results.map((item: any) => {
        const obj = {
          label: "",
          value: "",
        };
        obj.label = item.name;
        obj.value = item.id.toString();
        return obj;
      }),
      shown: ["/calender/overtime"].includes(location.pathname),
      onClick: () => handleFilterClick("zone"),
    },
    {
      key: "project",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("project")}
        >
          Filter by project
        </button>
      ),
      data: projects?.results.map((item: any) => {
        const obj = {
          label: "",
          value: "",
        };
        obj.label = item.name;
        obj.value = item.id.toString();
        return obj;
      }),
      shown: ["/calender/overtime"].includes(location.pathname),
      onClick: () => handleFilterClick("project"),
    },
    {
      key: "group",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => handleFilterClick("group")}
        >
          Filter by Group
        </button>
      ),
      data: [
        {
          label: "Leaves",
          value: "leave",
        },
        {
          label: "Business Permissions",
          value: "permission",
        },
      ],
      shown: location.pathname.includes("setups/Requests"),
      onClick: () => handleFilterClick("group"),
    },
    {
      key: "date",
      label: (
        <button
          className={styles.optionBtn}
          onClick={() => setIsDateFilterVisible(true)}
        >
          Filter by Date
        </button>
      ),
      data: [],
      shown:
        [
          "/staff",
          "/shifts",
          "/calender/overtime",
          "/calender/attendance",
          "/requests/team%20Requests",
          "/my-profile",
        ].includes(location.pathname) ||
        location.pathname.includes("/calender") ||
        location.pathname.includes("/integration"),
      onClick: () => setIsDateFilterVisible(true),
    },
  ];

  const finalMenuData = MenuData?.filter(
    (item) => !filtersToHide.includes(item.key)
  );

  return (
    <div>
      <Menu items={finalMenuData.filter((item) => item.shown)}>
        <Button
          isOutline
          suffix={<Icon name="sort" color="grey" size={24} />}
          btnClassName={`${styles.container} ${containerStyle}`}
          onClick={onClick}
        >
          Filter
        </Button>
      </Menu>

      <FilterSelectionModal
        data={
          finalMenuData.find((item) => item.key === selectedFilter)?.data || []
        }
        filterType={selectedFilter ?? ""}
        isVisible={isFilterModalVisible}
        setIsVisible={setIsFilterVisible}
      />
      <DateFilter
        setIsVisible={() => setIsDateFilterVisible(false)}
        isVisible={isDateFilterVisible}
        isRange
      />
    </div>
  );
}
