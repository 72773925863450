import Text from "components/general/Text";
import Button from "components/general/Button";
import StatusBadge from "components/general/StatusBadge";
import AddNewDepartmentModal from "components/modals/AddNewDepartmentModal";
import { useState } from "react";
import { useGetDepartmentsQuery } from "apis/services/department";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export default function Departments() {
  const [isAddNewDepartmentModalVisible, setisAddNewDepartmentModalVisible] =
    useState(false);

  const { data: departments } = useGetDepartmentsQuery({
    page_size: 100,
    ordering: "-id",
  });

  const onShowAddModal = () => {
    setisAddNewDepartmentModalVisible(true);
  };

  return (
    <div>
      <SecondaryPageHeader
        title="Departments"
        RightComponent={
          <Button btnClassName={styles.headerBtn} onClick={onShowAddModal}>
            Add New Department
          </Button>
        }
      />

      <div>
        <div className={styles.cardContainers}>
          {departments?.results?.map((item: any) => (
            <Link
              className={styles.infoCard}
              to={`staff?department=${item?.id}`}
            >
              <Text>{item?.name}</Text>
              <StatusBadge
                color="success"
                text={`Members: ${item?.number_of_members}`}
                containerStyle={styles.statusBadge}
              />
            </Link>
          ))}
        </div>
      </div>

      <AddNewDepartmentModal
        isVisible={isAddNewDepartmentModalVisible}
        setIsVisible={setisAddNewDepartmentModalVisible}
      />
    </div>
  );
}
