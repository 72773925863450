import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import Departments from "pages/settings/Departments";
import FingerPrints from "pages/settings/FingerPrints";
import Locations from "pages/settings/Locations";
import Projects from "pages/settings/Projects";
import Users from "pages/settings/Users";
import Workflow from "pages/settings/Workflow";
import Zones from "pages/settings/Zones";
import { Navigate, Outlet } from "react-router-dom";

const children = [
  {
    path: "",
    element: <Navigate to="departments" />,
  },
  {
    path: "departments",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Departments />,
      },
      {
        path: "staff",
        element: <Users title="Departments" isForDepartments isAddUserHidden />,
      },
    ],
  },
  {
    path: "zones",
    element: <Zones />,
  },
  {
    path: "locations",
    element: <Locations />,
  },
  {
    path: "fingerprints",
    element: <FingerPrints />,
  },
  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "workflow",
    element: <Workflow />,
  },
];

const organizationRoutes = {
  path: "settings/organization",
  element: (
    <SecondarySidebarLayout list={children} title="Organization">
      <Outlet />
    </SecondarySidebarLayout>
  ),
  children,
};

export default organizationRoutes;
