export const handleNumberDecimals = (number = 0, decimal = 1) => {
  const hasDecimals = Number.isInteger(number) === false;

  // If there are decimals, format the number to have 1 decimal place
  const formattedNumber = hasDecimals ? number.toFixed(decimal) : number;

  return formattedNumber;
};

export default handleNumberDecimals;
