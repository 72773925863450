import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";
import api from "apis";
import { CheckinsProp } from "apis/types/checkin";
import { PaginatedResponse } from "apis/types/general";

export const checkinApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllMyStaffCheckins: build.query({
      query: (params) => ({
        url: "/my_staff_checkins/",
        params: {
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      providesTags: ["Checkins"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),

    getRecentCheckins: build.query({
      query: (params) => ({
        url: "/my_staff_checkins/",
        params,
      }),
      providesTags: ["Checkins"],
    }),

    getAllOwnCheckins: build.query<PaginatedResponse<CheckinsProp>, any>({
      query: (params) => ({
        url: "/own_checkins/",
        params: {
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== null)
          ),
        },
      }),
      providesTags: ["OwnCheckins"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),

    getRecentOwnCheckins: build.query({
      query: (params) => ({
        url: "/own_checkins/",
        params,
      }),
      providesTags: ["OwnCheckins"],
    }),

    signinEmployeeByManager: build.mutation({
      query: (body) => ({
        url: "/checkins/employee_sign_in_by_manager/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Checkins", "Staff"],
    }),

    checkinForMySelf: build.mutation({
      query: ({ latitude, longitude }) => ({
        url: "/checkins/employee_sign_in_by_himself/",
        method: "POST",
        body: {
          latitude,
          longitude,
        },
      }),
      invalidatesTags: ["OwnCheckins", "User"],
    }),
  }),
});

export const {
  useGetAllMyStaffCheckinsQuery,
  useLazyGetAllMyStaffCheckinsQuery,
  useSigninEmployeeByManagerMutation,
  useGetRecentCheckinsQuery,
  useCheckinForMySelfMutation,
  useGetRecentOwnCheckinsQuery,
  useGetAllOwnCheckinsQuery,
  // useLazyGetAllOwnCheckinsQuery
} = checkinApi;
