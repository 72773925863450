/* eslint-disable */

import Text from "components/general/Text";
import Button from "components/general/Button";
import StatusBadge from "components/general/StatusBadge";
import { useGetZonesQuery } from "apis/services/zone";
import AddNewZoneModal from "components/modals/AddNewZoneModal";
import { useState } from "react";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import { FaRegEdit } from "react-icons/fa";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";

export default function Zones() {
  const [isAddNewZoneModalVisible, setisAddNewZoneModalVisible] =
    useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [selectedItem, setselectedItem] = useState({});
  const { data: zones } = useGetZonesQuery({ page_size: 100 });

  const onAddNewZone = () => {
    setisAddNewZoneModalVisible(true);
  };

  const onEditZone = (item: any) => {
    setisUpdate(true);
    setisAddNewZoneModalVisible(true);
    setselectedItem(item);
    console.log("hi?");
  };

  return (
    <div>
      <SecondaryPageHeader
        title="Zones"
        RightComponent={
          <Button btnClassName={styles.headerBtn} onClick={onAddNewZone}>
            Add New Zone
          </Button>
        }
      />

      <div>
        <div className={styles.cardContainers}>
          {zones?.results?.map((item: any) => (
            <div
              className={styles.infoCard}
              style={{ position: "relative" }}
              onClick={() => onEditZone(item)}
            >
              <div style={{ display: "block" }}>
                <Text>{`Name: ${item?.name}`}</Text>
                <p>{`Head: ${item?.head_username || "-"}`}</p>
              </div>
              <StatusBadge
                color="success"
                text={`Members: ${item?.number_of_members}`}
                containerStyle={styles.statusBadge}
              />
              {/* <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: 15,
                  zIndex: 5,
                }}
              >
                <FaRegEdit size={26} color={COLORS.primary} />
              </div> */}
            </div>
          ))}
        </div>
      </div>
      {isAddNewZoneModalVisible && (
        <AddNewZoneModal
          isVisible={isAddNewZoneModalVisible}
          setIsVisible={setisAddNewZoneModalVisible}
          isUpdate={isUpdate}
          initialValues={selectedItem}
        />
      )}
    </div>
  );
}
