import Text from "components/general/Text";
import Image from "components/general/Image";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import { UserInfoProps } from "./types";
import styles from "./styles.module.scss";

export default function UserInfo({
  containerStyle,
  data,
  hideImage,
  isTableCol,
}: UserInfoProps) {
  return (
    <div
      className={`${styles.container} ${containerStyle} ${
        isTableCol && styles.tableCol
      }`}
    >
      {!hideImage && (
        <div className={styles.avatar}>
          <Image src={AVATAR_LOGO} alt="User Avatar" />
        </div>
      )}
      <div>
        <Text
          className={styles.memberName}
        >{`${data?.user?.first_name} ${data?.user?.last_name}`}</Text>
        <Text className={styles.memberId}>{`${
          data?.job_title ?? ""
        } - ID: ${data?.cfm_id}`}</Text>
      </div>
    </div>
  );
}
