/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from "react";
import Menu from "components/Menu";
import Icon from "components/general/Icon";
import Text from "components/general/Text";
import StaffCheckInOut from "components/modals/StaffCheckInOut";
import moment from "moment";
import getClassNames from "utils/getClassName";
import styles from "./styles.module.scss";

export default function StatusView({
  handleClick,
  checkin,
  page,
}: {
  handleClick?: () => void;
  checkin: any;
  page?: any;
}) {
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const custom_checkin = {
    checkin: {
      checkin_time: checkin?.checkin_time,
      checkout_time: checkin?.checkout_time,
      checked_staff: checkin?.checked_staff,
    },
    employee: checkin?.checked_staff,
    working_hour: checkin?.working_hour,
    start_time: checkin?.start_time,
    end_time: checkin?.end_time,
    conflict: checkin?.conflict,
    date: checkin?.date,
    status: checkin?.status || checkin?.checked_staff?.day_status,
    specification: checkin?.specification,
  };
  const checkin_item = page === "attendance" ? custom_checkin : checkin;
  const MenuData = [
    {
      key: "profile",
      label: (
        <button
          onClick={() => setIsDetailsShown(true)}
          className={styles.view_profile}
        >
          View Profile
        </button>
      ),
    },
    // {
    //   key: "checkin-out",
    //   label: <button>Checkin</button>,
    // },
  ];

  const getStatusText = () => {
    let status = "Not In";
    if (
      // checkin_item?.status === "Off" &&
      // checkin_item.specification === "holiday"
      checkin_item.is_public_holiday
    ) {
      status = "Public_Holiday";
    } else if (checkin_item?.status === "Off") {
      status = "OFF";
    } else if (
      checkin_item?.checkin?.checkout_time &&
      checkin_item?.status !== "Not In"
    ) {
      status = "OUT";
    } else if (
      checkin_item?.checkin?.checkin_time &&
      checkin_item?.status !== "Not In"
    ) {
      status = "In";
    }
    return status;
  };

  const isStatusNotOk = () => {
    if (checkin_item?.attended && checkin_item?.checkin?.checkout_time)
      return false;

    const hasNotCheckedout =
      !checkin_item?.checkin?.checkout_time ||
      !checkin_item?.checkin?.checkin_time;

    if (hasNotCheckedout) return true;

    if (!checkin_item?.start_time) return true;

    if (!checkin_item?.end_time) return true;

    if (!checkin_item?.date) return true;

    const tolerance_after_start =
      custom_checkin?.working_hour?.tolerance_after_start;
    const tolerance_before_end =
      custom_checkin?.working_hour?.tolerance_before_end;

    const hasExceedStartTime = tolerance_after_start
      ? moment(checkin_item?.checkin?.checkin_time)
          .subtract(tolerance_after_start, "minute")
          .isAfter(
            moment(
              `${checkin_item?.date} ${checkin_item?.start_time}`,
              "YYYY-MM-DD HH:mm:ss"
            )
          )
      : moment(checkin_item?.checkin?.checkin_time).isAfter(
          moment(
            `${checkin_item?.date} ${checkin_item?.start_time}`,
            "YYYY-MM-DD HH:mm:ss"
          )
        );

    const hasExceedEndTime = tolerance_before_end
      ? moment(checkin_item?.checkin?.checkout_time)
          .add(tolerance_before_end, "minute")
          .isBefore(
            moment(
              `${checkin_item?.date} ${checkin_item?.end_time}`,
              "YYYY-MM-DD HH:mm:ss"
            )
          )
      : moment(checkin_item?.checkin?.checkout_time).isBefore(
          moment(
            `${checkin_item?.date} ${checkin_item?.end_time}`,
            "YYYY-MM-DD HH:mm:ss"
          )
        );

    return hasExceedEndTime || hasExceedStartTime;
  };

  return (
    <div className={styles.statusContainer}>
      <Text
        className={getClassNames([
          styles.statusStyle,
          styles[`status-${getStatusText()}`],
          isStatusNotOk() ? "" : styles["status-ok"],
        ])}
      >
        {getStatusText().replace("_", " ")}
      </Text>
      {handleClick && (
        <Menu items={MenuData}>
          <button
            onClick={handleClick}
            className={styles.memberActionBtn}
            title="View Profile"
          >
            <Icon name="Menu" size={24} color="info" />
          </button>
        </Menu>
      )}
      {isDetailsShown && checkin && (
        <StaffCheckInOut
          isVisible={isDetailsShown}
          setIsVisible={() => setIsDetailsShown(false)}
          user={checkin_item}
        />
      )}
    </div>
  );
}
