import { Select } from "antd";
import { SelectionInputProps } from "./types";
import styles from "./styles.module.scss";

export default function SelectionInput({
  containerStyle,
  onChange,
  value,
  options,
  disabled = false,
  placeholder,
  mode = undefined,
  showSearch = false,
  isLoading = false,
}: SelectionInputProps) {
  const id = `selectInput${Math.random()}`;
  const should_show_search = showSearch || options?.length >= 10;
  return (
    <div className={`${styles.container} ${containerStyle}`} id={id}>
      <Select
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        mode={mode}
        showSearch={should_show_search}
        optionFilterProp="label"
        loading={isLoading}
        defaultValue={value}
        onChange={onChange}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
}
