import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FixedTemplate } from "apis/types/shift";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Button from "components/general/Button";
import Table from "components/general/Table";
// import formatTime from "utils/formatDateTime";
import IMPORTANT_VARS from "constants/ImportantVars";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import AddPublicHoliday from "components/modals/AddPublicHoliday";
import showSuccessMsg from "utils/showSuccessMsg";
import moment from "moment";
import { Switch } from "antd";
import {
  useDeletePublicHolidayMutation,
  useGetPublicHolidaysQuery,
  useUpdatePublicHolidayMutation,
} from "apis/services/other";
import styles from "./styles.module.scss";

const FixedTemplateHeaders = [
  "Name",
  "Date",
  "reason for leave",
  "rate",
  "is active",
  "",
];

export default function PublicHoliday() {
  const [searchParams] = useSearchParams();

  const { data, isFetching: isHolidaysLoading } = useGetPublicHolidaysQuery({
    page: searchParams.get("page") ?? "1",
    search: searchParams.get("search") ?? "",
    page_size: IMPORTANT_VARS.TabelDataPageSize,
    ordering: "-created_at",
  });

  const [deletePublicHoliday, { isLoading: isDeletingHolidayTemplate }] =
    useDeletePublicHolidayMutation();
  const [updatePublicHoliday, { isLoading: isUpdatingTemplate }] =
    useUpdatePublicHolidayMutation();
  const [fixedTemplateModalShown, setFixedTemplateModalShown] = useState(false);

  const [editHolidayTemplateModalShown, setEditHolidayTemplateModalShown] =
    useState<any>();

  const deleteHolidayFunction = (holidayID: number) => () => {
    deletePublicHoliday({ id: holidayID })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Holiday template deleted successfully!" });
      });
  };

  const handleHolidaySwitch = (isEnabled: any, id: any) => {
    const update_params = { enabled: isEnabled, id };
    updatePublicHoliday(update_params)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Edited public holiday successfully!" });
      });
  };

  const onGetEditHolidayFunction = (holidayID: number) => () => {
    setEditHolidayTemplateModalShown({
      isVisible: true,
      selectedHolidayTemplate: data?.results?.find(
        (item: any) => item.id === holidayID
      ),
    });
  };

  const getPublicHolidayMenuItems = (holidayID: number): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Shift",
      actionFn: onGetEditHolidayFunction(holidayID),
    },
    {
      key: "delete",
      label: "Delete Shift",
      actionFn: deleteHolidayFunction(holidayID),
      isDanger: true,
      isLoading: isDeletingHolidayTemplate,
    },
  ];

  const changeHolidayData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: `${item.name}`,
      },
      { type: "text", dataItem: String(moment(item.date).format("LL")) ?? "-" },
      { type: "text", dataItem: item.reason_for_leave },
      { type: "text", dataItem: item.rate },
      {
        type: "element",
        dataItem: (
          <Switch
            defaultChecked={item?.enabled}
            loading={isUpdatingTemplate}
            onChange={(e) => handleHolidaySwitch(e, item.id)}
          />
        ),
      },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper
            menuData={getPublicHolidayMenuItems(item?.id as number)}
          />
        ),
      },
    ],
  });

  const RightComponent = (
    <Button
      btnClassName={`mx-2 ${styles.headerBtn}`}
      onClick={() => setFixedTemplateModalShown(true)}
    >
      Add New Public Holiday
    </Button>
  );

  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title="Public Holiday"
        RightComponent={RightComponent}
      />

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          isFilterBtnVisible={false}
          showSearchFilter
          headers={FixedTemplateHeaders}
          searchPlaceholder="Search for Holiday"
          data={
            data?.results?.map((item: FixedTemplate) =>
              changeHolidayData(item)
            ) ?? []
          }
          isLoading={isHolidaysLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>

      {fixedTemplateModalShown && (
        <AddPublicHoliday
          isVisible={fixedTemplateModalShown}
          setIsVisible={() => setFixedTemplateModalShown(false)}
        />
      )}

      {!!editHolidayTemplateModalShown?.isVisible && (
        <AddPublicHoliday
          isVisible={!!editHolidayTemplateModalShown?.isVisible}
          setIsVisible={() =>
            setEditHolidayTemplateModalShown({ isVisible: false })
          }
          isUpdate
          initValues={editHolidayTemplateModalShown?.selectedHolidayTemplate}
        />
      )}
    </div>
  );
}
