const tagTypes = [
  "User",
  "Product",
  "AllStaff",
  "Department",
  "Zone",
  "Location",
  "EmployeeShiftS",
  "NormalShiftWorkingHours",
  "FixedTemplates",
  "Category",
  "Requests",
  "Activities",
];

export default tagTypes;
