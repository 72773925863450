import UserForm from "components/pages/settings/UserForm";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetBalanceDetailsForUserQuery,
  useGetSpecificStaffFromAllStaffQuery,
} from "apis/services/staff";
import LoadingIndicator from "components/general/LoadingIndicator";
import Paper from "components/general/PaperComponent";
import { ConfigProvider, Tabs } from "antd";
import { useState } from "react";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";

export default function EditUser() {
  const params = useParams();
  const userId = params?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("info");
  const { data, isFetching: isGettingUserLoading } =
    useGetSpecificStaffFromAllStaffQuery(Number(userId));
  const { data: balaceData, isFetching: balanceLoader } =
    useGetBalanceDetailsForUserQuery(Number(userId));
  const headers = ["Leave Type", "Date", "Alloted", "Used", "Balance", ""];
  const items = [
    {
      key: "info",
      label: "Profile information",
    },
    {
      key: "balance",
      label: "Customize Balance",
    },
  ];
  const changeBalanceData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: `${item.name} (${item.from_time} - ${item.to_time})`,
      },
      { type: "text", dataItem: item.from_time ?? "-" },
      {
        type: "text",
        dataItem: item.to_time,
      },
      {
        type: "text",
        dataItem: "0",
      },
      { type: "text", dataItem: "0" },
      {
        type: "element",
        dataItem: <MenuTableDataWrapper />,
      },
    ],
  });
  const onChange = (key: string) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setSelectedTab(key);
  };

  return (
    <div className={styles.container}>
      {!isGettingUserLoading ? (
        <>
          <div className="mb-5">
            <Paper
              backgroundColor="white"
              borderColor="white"
              paddingHorizontal={32}
              paddingVertical={14}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      /* here is your component tokens */
                      fontSize: 16,
                      colorBgContainer: "#E3332D0D",
                      colorBorderSecondary: "#fffff",
                      colorFillAlter: "#fffff",
                      padding: 24,
                      margin: 0,
                    },
                  },
                }}
              >
                <Tabs
                  type="card"
                  items={items}
                  onChange={onChange}
                  prefixCls="tabContainer"
                  defaultActiveKey={selectedTab}
                />
              </ConfigProvider>
            </Paper>
          </div>
          {selectedTab === "info" ? (
            <UserForm user={data} />
          ) : (
            <div className="mt-4">
              <Paper
                borderColor={COLORS.lightGrey}
                paddingHorizontal={44}
                paddingVertical={24}
              >
                <UserInfo
                  data={{
                    user: {
                      first_name: "mahmoud salah",
                      last_name: "",
                    },
                    job_title: "Frontend Dev",
                    id: 6,
                  }}
                  containerStyle={styles.userInfo}
                />
              </Paper>
              <div className="mt-4">
                <Table
                  isCheckBoxVisible={false}
                  headers={headers}
                  data={
                    // @ts-ignore
                    Object.keys(balaceData).map((item: any) =>
                      // @ts-ignore
                      changeBalanceData({ item, ...balaceData[key] })
                    ) ?? []
                  }
                  isLoading={balanceLoader}
                  // count={data?.count}
                  // pageSize={IMPORTANT_VARS.TabelDataPageSize}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <LoadingIndicator color="primary" isFullscreen />
      )}
    </div>
  );
}
