import { Input } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";
import { TextInputProps } from "./types";

export default function TextInput({
  containerStyle,
  inputSuffix,
  inputPrefix,
  outerSuffix,
  name,
  value,
  onChange,
  placeholder,
  i18nPlaceholder,
  label,
  i18nLabelKey,
  type = "text",
  isNumberInput = false,
  disabled = false,
}: TextInputProps) {
  const { t } = useAutoCompleteTranslation();

  const TargetInputTypeMap = new Map<string, any>([
    ["password", Input.Password],
    ["textarea", Input.TextArea],
    ["text", Input],
  ]);
  const handleKeyDown = (e: any) => {
    const preventedCharacters = ["e", "E", "-", "+"];
    // Prevent characters
    if (preventedCharacters.includes(e.key)) {
      e.preventDefault();
    }
  };

  const TargetInput = TargetInputTypeMap?.get(type);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {(!!label || !!i18nLabelKey) && (
        <span>{i18nLabelKey ? t(i18nLabelKey) : label}</span>
      )}

      <div className={styles.inputContent}>
        <TargetInput
          name={name}
          className={styles.input}
          placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
          suffix={inputSuffix}
          prefix={inputPrefix}
          value={value}
          onChange={(e: any) => {
            onChange?.(
              isNumberInput
                ? {
                    ...e,
                    target: {
                      ...e.target,
                      value:
                        e.target.value === "" ? null : Number(e.target.value),
                    },
                  }
                : e
            );
          }}
          onKeyDown={isNumberInput ? handleKeyDown : () => {}}
          autoSize={{ minRows: 6, maxRows: 6 }}
          type={isNumberInput ? "number" : undefined}
          disabled={disabled}
        />

        {outerSuffix}
      </div>
    </div>
  );
}
