/* eslint-disable */
import { Form, Tag, Upload } from "antd";
import Button from "components/general/Button";
import AttachmentIcon from "assets/icons/paperclip-2.svg";
import Text from "components/general/Text";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

function renderItem(_: any, file: any, files: any[], actions: any) {
  return (
    <Tag closeIcon onClose={actions.remove}>
      {file.originFileObj?.name}
    </Tag>
  );
}

export default function UploadFiles({
  name,
  label,
  required = true,
}: {
  name: string;
  label: string;
  required?: boolean;
}) {
  return (
    <>
      <Text>{label}</Text>
      <div className="border rounded-4 p-2 px-3 w-100 d-flex flex-row justify-content-between mb-4">
        <Form.Item
          name={name}
          getValueFromEvent={normFile}
          rules={[{ required: required }]}
          className="mb-0"
        >
          <Upload
            beforeUpload={() => {
              return false;
            }}
            listType="picture-card"
            itemRender={renderItem}
          >
            <Button isOutline btnClassName="border-0 py-0 m-0">
              Upload
            </Button>
          </Upload>
        </Form.Item>
        <img src={AttachmentIcon} alt="Attachment Icon" />
      </div>
    </>
  );
}
