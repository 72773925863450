import Text from "components/general/Text";
import getClassNames from "utils/getClassName";
import styles from "./styles.module.scss";

export default function FingerPrints() {
  return (
    <div className={getClassNames([styles.container, "min-vh-100"])}>
      <Text>FingerPrints</Text>
    </div>
  );
}
