import { useNavigate } from "react-router";
import { RequestProps } from "apis/types/requests";
import { useGetMyRequestQuery } from "apis/services/requests";
import { Spin } from "antd";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import Button from "components/general/Button";
import InfoRow from "components/general/InfoRow";
import RequestCard from "components/cards/RequestCard";
import styles from "./styles.module.scss";

export default function MyRequests() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetMyRequestQuery({
    page_size: 100,
  });
  const INFODATA = [
    {
      title: "All",
      info: 0,
    },
    {
      title: "Available",
      info: 0,
    },
    {
      title: "Used",
      info: 0,
    },
  ];

  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title="My Request"
        RightComponent={
          <Button onClick={() => navigate("create-new-request")}>
            Apply New Request
          </Button>
        }
      />

      <SeparatedRowElements>
        {INFODATA.map((item) => (
          <InfoRow
            key={item.title}
            title={`${item.title}:`}
            info={`${item.info} Days`}
            infoFontSize={18}
            infoFontWeight="500"
            containerStyle="py-2"
          />
        ))}
      </SeparatedRowElements>

      <section className="my-5">
        {isLoading ? (
          <div className="d-flex">
            <Spin className="m-auto" />
          </div>
        ) : (
          data?.results.map((item: RequestProps) => (
            <RequestCard key={item.id} data={item} isMyRequest />
          ))
        )}
      </section>
    </div>
  );
}
