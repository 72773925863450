// import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";
import UserInfo from "components/cards/UserInfo";
import InfoDetailsCard from "components/general/InfoDetailsCard";
// import { useGetStaffMemberInfoQuery } from "apis/services/staff";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";

export default function ProfileInfo() {
  // const { id } = useParams();
  const { userInfo: data } = useGetUserInfo();

  // const { data } = useGetStaffMemberInfoQuery(id!);

  const PERSONALDETAILS = [
    {
      label: "Mobile num:",
      value: data?.phone,
    },
    {
      label: "E-mail:",
      value: data?.user?.email,
    },
    {
      label: "National ID:",
      value: data?.national_id,
    },
    {
      label: "Date of Birth:",
      value: data?.dob,
    },
    {
      label: "Age:",
      value: `${dayjs(new Date()).diff(data?.dob, "year")} years`,
    },
    {
      label: "Marital Status:",
      value: data?.marital_status,
    },
    {
      label: "Gender:",
      value: data?.gender,
    },
  ];

  const ACCOUNTSTATUS = [
    {
      label: "Account Status:",
      value: data?.user?.is_active ? "Active" : "InActive",
    },
    {
      label: "Enable mobile app:",
      value: data?.mobile_app_enabled ? "Enabled" : "Disabled",
    },
  ];

  const REPORTINGTO = [
    {
      label: "",
      value: data?.reports_to && <UserInfo data={data?.reports_to} />,
    },
  ];

  const WORKINFO = [
    {
      label: "Job Classification:",
      value: data?.job_classification,
    },
    {
      label: "Employment Type:",
      value: data?.employment_type,
    },
    {
      label: "Department:",
      value: data?.department?.name ?? "-",
    },
    {
      label: "Default Zone:",
      value: data?.assigned_locations[0]?.zone?.name,
    },
    {
      label: "Location 1:",
      value: data?.assigned_locations[0]?.address,
    },
    {
      label: "Location 2:",
      value: data?.assigned_locations[1]?.address,
    },
    {
      label: "Finger print device:",
      value: data?.current_day_checkin?.fingerprint_device,
    },
    {
      label: "Bus Rotation:",
      value: "",
    },
    {
      label: "Date of Joining:",
      value: data?.date_of_joining,
    },
    {
      label: "Date of Leaving:",
      value: data?.date_of_leaving,
    },
  ];

  const SHIFTINFO = [
    {
      label: "Shift Type:",
      value: data?.default_shift_type,
    },
    {
      label: "Working Hour",
      value: `${data?.working_hour} hours`,
    },
    {
      label: "No. of Weekend",
      value: `${data?.no_of_weekend} ${
        (data?.no_of_weekend || 0) <= 1 ? "Day" : "Days"
      }`,
    },
  ];

  const LEAVEBALANCE = [
    {
      label: "Seniority year:",
      value: `${data?.seniority_year ?? 0} year/s`,
    },
    {
      label: "Overtime Allowance:",
      value: data?.overtime_allowed ? "Yes" : "No",
    },
  ];

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={data} />
      </Paper>
      <hr />
      <InfoDetailsCard cardTitle="Personal Details" data={PERSONALDETAILS} />
      <hr />
      <InfoDetailsCard cardTitle="Account Status" data={ACCOUNTSTATUS} />
      <hr />
      <InfoDetailsCard cardTitle="Reporting To:" data={REPORTINGTO} />
      <hr />
      <InfoDetailsCard cardTitle="Work Information" data={WORKINFO} />
      <hr />
      <InfoDetailsCard cardTitle="Shift Information" data={SHIFTINFO} />
      <hr />
      <InfoDetailsCard cardTitle="Leaves and Balance" data={LEAVEBALANCE} />
      <hr />
    </div>
  );
}
