/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import Button from "components/general/Button";
import Paper from "components/general/PaperComponent";
import Image from "components/general/Image";
import Text from "components/general/Text";
import LoadingIndicator from "components/general/LoadingIndicator";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import moment from "moment";
import { Col, Row } from "antd";
import { useUpdateConflictMutation } from "apis/services/shift";
// import { ConflictLocationProps } from "./types";
import StatusBadge from "components/general/StatusBadge";
import ModalWrapper from "../ModalWrapper";
import Mission from "../requests/Mission";
import Transfer from "../requests/Transfer";
import styles from "./styles.module.scss";

function MemberUpdatedAt({
  conflictDate,
  dateAndTime,
}: {
  conflictDate: any;
  dateAndTime: any;
}) {
  return (
    <Text className={styles.memberUpdatedDate}>
      {moment(dateAndTime?.date || conflictDate).format("MMM DD, YYYY")} at{" "}
      {dateAndTime?.time
        ? moment(dateAndTime?.time, " hh:mm:ss").format("hh:mm a")
        : moment(conflictDate).format("hh:mm a")}
    </Text>
  );
}

function KeyValueView({
  label,
  value,
}: {
  label: string;
  value: string | undefined | null;
}) {
  return (
    <Row className={`${styles.keyValueView} mb-2`}>
      <Col>
        <Text>{label} </Text>
      </Col>
      <Col className="px-2">
        <Text> {value}</Text>
      </Col>
    </Row>
  );
}

export default function ConflictLocation({
  containerStyle,
  isVisible,
  setIsVisible,
  user,
  setisConflictResolved,
  isConflictResolved,
}: any) {
  const [isRequestVisible, setisRequestVisible] = useState(false);
  const [requestType, setrequestType] = useState(null);

  useEffect(() => {
    if (isConflictResolved) {
      setIsVisible(false);
    }
  }, [isConflictResolved]);

  const [updateConflict, { isLoading: isUpdateConflictLoading }] =
    useUpdateConflictMutation();

  const handleRejectConflict = () => {
    updateConflict({
      ...user.conflict,
      employee: user.conflict.employee.id || user.conflict.employee,
      location: user.conflict.location.id || user.conflict.location,
      resolve_status: 2,
    });
    // console.log("U:: ", user.conflict);
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Conflict Location"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div className={styles.memberContent}>
          <div className="mb-3">
            <Paper
              backgroundColor="#F0F0F040"
              borderColor="#D9D9D980"
              paddingHorizontal={24}
              paddingVertical={18}
            >
              <div className={styles.memberInfo}>
                <div className={styles.avatar}>
                  <Image src={AVATAR_LOGO} alt="User Avatar" />
                </div>
                <div>
                  <Text
                    className={styles.memberName}
                  >{`${user?.employee?.user?.first_name} ${user?.employee?.user?.last_name} has been Sign-in in Different location`}</Text>
                  <MemberUpdatedAt
                    conflictDate={user?.conflict?.checkin?.created_at}
                    dateAndTime={{
                      date: user?.dateAndTime?.date,
                      time: user?.dateAndTime?.time,
                    }}
                  />
                </div>
              </div>
              <StatusBadge
                color={
                  user?.conflict?.resolved
                    ? "success"
                    : user?.conflict?.resolve_status === 2
                    ? "danger"
                    : "warning"
                }
                text={
                  user?.conflict?.resolved
                    ? "resolved"
                    : user?.conflict?.resolve_status === 2
                    ? "rejected"
                    : "pending"
                }
                containerStyle={styles.statusBadge}
              />
            </Paper>
          </div>

          <Paper
            backgroundColor="#F0F0F080"
            borderColor="transparent"
            paddingHorizontal={24}
            paddingVertical={24}
          >
            <KeyValueView
              label="Default Zone:"
              value={
                user?.conflict?.original_location?.zone?.name ||
                user?.employee?.assigned_locations[0]?.zone?.name
              }
            />
            <KeyValueView
              label="Default location:"
              value={
                user?.conflict?.original_location?.address ||
                user?.employee?.assigned_locations[0]?.address
              }
            />
            <hr />
            <KeyValueView
              label="Current Zone:"
              value={
                user?.conflict?.location?.zone?.name ||
                user?.currentLocation?.zone?.name
              }
            />
            <KeyValueView
              label="Current Sign in location:"
              value={
                user?.conflict?.location?.address ||
                user?.currentLocation?.address
              }
            />
            <KeyValueView
              label="Date:"
              value={moment(
                user?.dateAndTime?.date || user?.conflict?.checkin?.created_at
              ).format("ddd, DD MMM, YYYY")}
            />
            <KeyValueView
              label="Time:"
              value={
                user?.dateAndTime?.time
                  ? moment(user?.dateAndTime?.time, "hh:mm:ss").format(
                      "hh:mm a"
                    )
                  : moment(user?.conflict?.checkin?.created_at).format(
                      "hh:mm a"
                    )
              }
            />
          </Paper>
        </div>

        <hr />
        {isRequestVisible && requestType === "mission" && (
          <Mission
            isVisible={isRequestVisible}
            setIsVisible={setisRequestVisible}
            setisConflictResolved={setisConflictResolved}
            created_for={user}
          />
        )}
        {isRequestVisible && requestType === "transfer" && (
          <Transfer
            isVisible={isRequestVisible}
            setIsVisible={setisRequestVisible}
            setisConflictResolved={setisConflictResolved}
          />
        )}

        {!user?.conflict?.resolve_status && !user?.conflict?.resolved && (
          <div className={styles.buttonsGroup}>
            <Button
              onClick={() => {
                // @ts-ignore
                setrequestType("mission");
                setisRequestVisible(true);
              }}
            >
              Mission Request
            </Button>
            <Button
              isOutline
              onClick={() => {
                // @ts-ignore
                setrequestType("transfer");
                setisRequestVisible(true);
              }}
            >
              Transfer Request
            </Button>
            <button className="btn btn-link" onClick={handleRejectConflict}>
              {isUpdateConflictLoading ? (
                <LoadingIndicator color="primary" />
              ) : (
                "Reject"
              )}
            </button>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}
