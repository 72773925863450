import api from "apis";
import { PaginationParams } from "apis/types/general";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const workflowApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWorkflow: build.query<any, PaginationParams>({
      query: (params) => ({
        url: "/work-flow/",
        params,
      }),
      providesTags: ["workflow"],
      serializeQueryArgs: getSerializedQueryArgs,
    }),
    addWorkflow: build.mutation<any, PaginationParams>({
      query: (body) => ({
        url: "/work-flow/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["workflow"],
    }),
    editWorkflow: build.mutation<any, PaginationParams>({
      query: (body) => ({
        url: `/work-flow/${body?.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["workflow"],
    }),

    deleteWorkflow: build.mutation({
      query: (id) => ({
        url: `/work-flow/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["workflow"],
    }),
  }),
});

export const {
  useGetWorkflowQuery,
  useAddWorkflowMutation,
  useEditWorkflowMutation,
  useDeleteWorkflowMutation,
} = workflowApi;
