export default function convertSecondsToFullTime(seconds: number) {
  let result = "hh:mm:ss";

  if (!seconds || Number(seconds) < 0) {
    result = result.replace("hh", "00");
    result = result.replace("mm", "00");
    result = result.replace("ss", "00");
    return result;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsToDisplay = seconds % 60;

  // Add leading zeros if needed
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    secondsToDisplay < 10 ? `0${secondsToDisplay}` : secondsToDisplay;

  result = result.replace("hh", String(formattedHours));
  result = result.replace("mm", String(formattedMinutes));
  result = result.replace("ss", String(formattedSeconds));
  return result;
}
