export const RequestsStatus = {
  0: "pending",
  1: "accepted",
  2: "rejected",
  3: "cancelled",
};

export const Requests = {
  0: "Mission",
  1: "Transfer",
  2: "Leave",
  3: "Business Trip",
  4: "Business Permission",
};

export const RequestsTypes = {
  mission_requests: 0,
  transfer_requests: 1,
  leave_requests: 2,
  business_trip_requests: 3,
  business_permission_requests: 4,
};
