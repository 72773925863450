import { useState } from "react";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetStaffMemberInfoQuery } from "apis/services/staff";
import {
  useGetOverTimeStatisticsQuery,
  useGetOverTimesQuery,
  // useGetStaffOvertimesQuery,
} from "apis/services/overtime";
import { OvertimeProps } from "apis/types/overtime";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";
import InfoRow from "components/general/InfoRow";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import UserInfo from "components/cards/UserInfo";
import Table from "components/general/Table";
import Text from "components/general/Text";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { TableItemProps } from "components/general/Table/types";
import StatusView from "components/pages/calender/status-view";
import DateFilter from "components/modals/DateFilter";
import styles from "./styles.module.scss";

export default function StaffMemberOvertime() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);

  const { data: userData } = useGetStaffMemberInfoQuery(id!);
  const { data: overtimeStatistic } = useGetOverTimeStatisticsQuery({
    employee: id,
    start_date:
      searchParams.get("startDate") ??
      moment(new Date()).subtract(1, "months").format("YYYY-MM-DD"),
    end_date:
      searchParams.get("endDate") ??
      moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
  });
  const { data, isLoading } = useGetOverTimesQuery({
    employee: id,
    // ordering: "-id",
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    start_date:
      searchParams.get("startDate") ??
      moment(new Date()).subtract(1, "months").format("YYYY-MM-DD"),
    end_date:
      searchParams.get("endDate") ??
      moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
    department: searchParams.get("department") || undefined,
  });

  const INFODATA = [
    {
      title: "Total Overtime",
      info: overtimeStatistic?.total_overtime?.toFixed(2) ?? 0,
    },
    {
      title: "Paid",
      info: overtimeStatistic?.paid_hrs?.toFixed(2) ?? 0,
    },
    {
      title: "Unpaid",
      info: overtimeStatistic?.unpaid_hrs?.toFixed(2) ?? 0,
    },
    {
      title: "Leave Balance",
      info: overtimeStatistic?.hrs_added_to_leave_balance?.toFixed(2) ?? 0,
    },
  ];

  const formateDate = (
    date: Date | string | null | undefined,
    format = "hh:mm A"
  ) => (date ? moment(date).format(format) : "-");

  const changeStaffData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "text",
        dataItem: formateDate(item?.date, "DD-MM-YYYY"),
      },
      {
        type: "text",
        dataItem:
          item?.checkin?.checkin_time ?? item?.conflict?.checkin?.checkin_time
            ? formateDate(
                item?.checkin?.checkin_time ??
                  item?.conflict?.checkin?.checkin_time,
                "hh:mm A"
              )
            : "-",
      },
      {
        type: "text",
        dataItem:
          item?.checkin?.checkout_time ?? item?.conflict?.checkin?.checkout_time
            ? formateDate(
                item?.checkin?.checkout_time ??
                  item?.conflict?.checkin?.checkout_time,
                "hh:mm A"
              )
            : "-",
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.total_overtime) ?? "0"}h`,
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.paid_hrs) ?? "0"}h`,
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.unpaid_hrs) ?? "0"}h`,
      },
      {
        type: "text",
        dataItem: `${Math.floor(item?.hrs_added_to_leave_balance) ?? "0"}h`,
      },
      // {
      //   type: "text",
      //   dataItem: item?.project_for_paid_overtime?.name ?? "--",
      // },
      {
        type: "element",
        dataItem: (
          <StatusView
            checkin={item.shift ?? item.conflict}
            employee={userData}
            status={item.status}
            type="edit_overtime"
            custom_data={item}
          />
        ),
      },
    ],
  });

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={userData} />
        <hr />
        <div className="d-flex flex-row align-items-center justify-content-between">
          <Text>
            Perid from:
            {searchParams.get("startDate") ??
              moment(new Date())
                .subtract(1, "months")
                .format("DD/MM/YYYY")}{" "}
            To{" "}
            {searchParams.get("endDate") ??
              moment(new Date()).format("DD/MM/YYYY")}
          </Text>
          <Button
            onClick={() => setIsDateFilterVisible(true)}
            btnClassName={styles.filterBtn}
            suffix={<Icon name="calendar" color="grey" size={24} />}
            isOutline
            color="info"
          >
            Date Filter
          </Button>
        </div>
      </Paper>

      <div className="mt-4">
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} Hrs`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </div>

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          headers={[
            "Date",
            "Check in",
            "Check Out",
            "Overtime",
            "Paid",
            "Unpaid",
            "Leave balance",
            // "Project",
            "Status",
          ]}
          data={
            data?.results?.map((item: OvertimeProps) =>
              changeStaffData(item)
            ) ?? []
          }
          isLoading={isLoading}
          count={data?.count}
        />
      </div>
      <DateFilter
        setIsVisible={() => setIsDateFilterVisible(false)}
        isVisible={isDateFilterVisible}
        isRange
      />
    </div>
  );
}
