import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabsLayoutProps } from "./types";
import styles from "./styles.module.scss";

export default function TabsLayout({
  containerStyle,
  tabs,
  title,
  position = "left",
}: TabsLayoutProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabitems = tabs.map((item) => {
    return {
      label: t(item.label),
      key: item.key,
      children: item.children,
    };
  });

  const titleTabItem = {
    label: title,
    key: "title_tab",
    children: null,
    disabled: true,
    className: styles.title,
  };

  const setInitPage = () => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Tabs
        className="antdTabs"
        tabPosition={position}
        defaultActiveKey={tabs?.[0]?.key}
        items={title ? [titleTabItem, ...tabitems] : tabitems}
        onChange={() => setInitPage()}
        destroyInactiveTabPane
      />
    </div>
  );
}
