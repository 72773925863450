import api from "apis";
import { PaginationParams } from "apis/types/general";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const projectApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<any, PaginationParams>({
      query: (params) => ({
        url: "/locations/projects/",
        params,
      }),
      providesTags: ["Project"],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    deleteProject: build.mutation({
      query: (id) => ({
        url: `/locations/projects/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
  }),
});

export const { useGetProjectsQuery, useDeleteProjectMutation } = projectApi;
