/* eslint-disable */
interface Results<T> {
  results: T[];
}
interface UseGetReportsListProps {
  departments?: Results<any>;
  users?: Results<any>;
  zones?: Results<any>;
  projects?: Results<any>;
  requests?: Results<any>;
}
export default function useGetReportsList({
  departments,
  users,
  zones,
  projects,
  requests,
}: UseGetReportsListProps) {
  const departmentOptions =
    departments?.results?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    })) || [];
  const adminsOptions =
    users?.results?.map((item: any) => ({
      label: `${item?.user?.username} (${item?.cfm_id})`,
      value: item?.cfm_id,
    })) || [];
  const zoneOptions =
    zones?.results?.map((item: any) => ({
      label: item.name,
      value: item.id,
    })) || [];
  const projectOptions =
    projects?.results?.map((item: any) => ({
      label: `${item.name} (${item.project_id})`,
      value: item.id,
    })) || [];
  const requestOptions =
    requests?.results?.map((item: any) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    }) || [];
  const formInputsData = [
    {
      label: "Detailed Absence",
      value: 1,
      url: "/shifts/my_staff_shifts/export_detailed_absence_report/",
      data: [
        {
          id: 1,
          name: "start_date",
          label: "Date From",
          type: "date",
        },
        {
          id: 2,
          name: "end_date",
          label: "Date To",
          type: "date",
        },
        {
          id: 3,
          name: "employee_department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 4,
          name: "employee_project_zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 5,
          name: "employee_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
    {
      label: "Summary Absence",
      value: 2,
      url: "/staff/all/export_absence_summary_report/",
      data: [
        {
          id: 1,
          name: "start_date",
          label: "Date From",
          type: "date",
        },
        {
          id: 2,
          name: "end_date",
          label: "Date To",
          type: "date",
        },
        {
          id: 3,
          name: "department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 4,
          name: "zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 5,
          name: "default_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
    {
      label: "Daily Attendance Analysis",
      value: 3,
      url: "/departments/export_daily_attendance_report/",
      data: [
        {
          id: 1,
          name: "date",
          label: "Single Date",
          type: "date",
        },
        {
          id: 2,
          name: "Department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 3,
          name: "zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 4,
          name: "project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
    {
      label: "Employees Attendance Record",
      value: 4,
      url: "/shifts/my_staff_shifts/export_detailed_daily_attendance_report/",
      data: [
        {
          id: 1,
          name: "start_date",
          label: "Date From",
          type: "date",
        },
        {
          id: 2,
          name: "end_date",
          label: "Date To",
          type: "date",
        },
        {
          id: 3,
          name: "employee_department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 4,
          name: "checkin_zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 5,
          name: "checkin_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
    {
      label: "Leave Type Analysis",
      value: 5,
      url: "/shifts/my_staff_shifts/export_leavetype_analysis_report/",
      data: [
        {
          id: 1,
          name: "start_date",
          label: "Date From",
          type: "date",
        },
        {
          id: 2,
          name: "end_date",
          label: "Date To",
          type: "date",
        },
        {
          id: 3,
          name: "leave_type",
          label: "Leave Type",
          type: "select",
          options: requestOptions,
        },
        {
          id: 4,
          name: "cfm_id",
          label: "Worker ID",
          type: "users",
          options: adminsOptions,
        },
        {
          id: 5,
          name: "employee_department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 6,
          name: "employee_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
        {
          id: 7,
          name: "employee_project_zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
      ],
    },
    {
      label: "Overtime Detailed",
      value: 6,
      url: "/shifts/my_staff_shifts/export_overtime_report/",
      data: [
        {
          id: 1,
          name: "start_date",
          label: "Date From",
          type: "date",
        },
        {
          id: 2,
          name: "end_date",
          label: "Date To",
          type: "date",
        },
        {
          id: 4,
          name: "cfm_id",
          label: "Worker ID",
          type: "users",
          options: adminsOptions,
        },
        {
          id: 5,
          name: "employee_department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 7,
          name: "checkin_zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 6,
          name: "checkin_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
    {
      label: "Employee Vacation Balance",
      value: 7,
      url: "/staff/all/export_vaccation_balance_report/",
      data: [
        {
          id: 4,
          name: "cfm_id",
          label: "Worker ID",
          type: "users",
          options: adminsOptions,
        },
        {
          id: 5,
          name: "department",
          label: "Department",
          type: "select",
          options: departmentOptions,
        },
        {
          id: 7,
          name: "zone",
          label: "Zone",
          type: "select",
          options: zoneOptions,
        },
        {
          id: 6,
          name: "default_project",
          label: "Project",
          type: "select",
          options: projectOptions,
        },
      ],
    },
  ];
  return formInputsData;
}
