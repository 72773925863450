import Text from "components/general/Text";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import { SecondarySidebarLayoutProps } from "./types";

export default function SecondarySidebarLayout({
  list,
  title,
  children,
}: SecondarySidebarLayoutProps) {
  const realTabs = list
    ?.map((item) => ({
      label: item?.path?.split("/")?.[item.path.split("/").length - 1 || 0],
      path: item?.path,
    }))
    ?.filter((item) => !!item.path);

  return (
    <div className={styles.container}>
      <div className={styles.secondarySidebar}>
        {title !== "" && <Text>{title}</Text>}

        {realTabs?.map((item) => (
          <NavLink key={item?.path} to={item?.path} className={styles.navLink}>
            {item?.label}
          </NavLink>
        ))}
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
