import { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Table from "components/general/Table";
import Button from "components/general/Button";
import { TableProps } from "components/general/Table/types";
import Image from "components/general/Image";
import StatusView from "components/pages/home/status-view";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import Text from "components/general/Text";
import { StaffCheckInOutProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import ConflictLocation from "../ConflictLocation";
import styles from "./styles.module.scss";

export default function StaffCheckInOut({
  isVisible,
  setIsVisible,
  user,
}: StaffCheckInOutProps) {
  const navigate = useNavigate();
  const [isConflictModalVisible, setIsConflictModalVisible] = useState(false);
  const [isConflictResolved, setisConflictResolved] = useState(
    user?.conflict?.resolved
  );
  const isConflictRejected = user?.conflict?.resolve_status === 2;

  const onGoToEmployeeDetails = () => {
    navigate(`/staff/${user?.employee?.id}`);
  };

  const getTimeSpend = () => {
    if (user?.checkin?.checkin_time && user?.checkin?.checkout_time) {
      const checkinDate = moment(user?.checkin?.checkin_time);
      const checkoutDate = moment(user?.checkin?.checkout_time);

      const timeSpentInHours = checkoutDate.diff(checkinDate, "hours");
      return `${timeSpentInHours} hr/s`;
    }
    return "--";
  };

  const checkinText = user?.checkin?.checkin_time
    ? moment(user?.checkin?.checkin_time).format("hh:mm A")
    : "--";

  const checkoutText = user?.checkin?.checkout_time
    ? moment(user?.checkin?.checkout_time).format("hh:mm A")
    : "--";

  const shouldShowConflict = !!user?.conflict?.id;
  console.log(user, "user");

  const tableData: TableProps["data"] = [
    {
      id: "1",
      rowData: [
        {
          type: "element",
          dataItem: (
            <div className={styles.avatar}>
              <Image src={AVATAR_LOGO} width={48} alt="User Avatar" />
            </div>
          ),
        },
        {
          type: "element",
          dataItem: (
            <div className={styles.avatar}>
              <Image src={AVATAR_LOGO} width={48} alt="User Avatar" />
            </div>
          ),
        },
        // Checkin time
        { type: "text", dataItem: checkinText },
        // Checkout time
        { type: "text", dataItem: checkoutText },
        // Time spend
        { type: "text", dataItem: getTimeSpend() },
        // User status
        {
          type: "element",
          dataItem: <StatusView checkin={user} />,
        },
        // Shift name
        {
          type: "text",
          dataItem: user?.employee?.default_shift_type ?? "-",
        },
        // Location
        {
          type: "text",
          dataItem: (
            <>
              {user?.checkin?.checked_staff?.assigned_locations?.[0]?.address ??
                "-"}
              {isConflictRejected && (
                <Text className={styles.bold} style={{ color: "red" }}>
                  Rejected
                </Text>
              )}
              {shouldShowConflict &&
                !isConflictRejected &&
                (isConflictResolved ? (
                  <Text className={styles.bold}>Resolved</Text>
                ) : (
                  <button
                    onClick={() =>
                      setIsConflictModalVisible(!isConflictModalVisible)
                    }
                    className="btn btn-link"
                  >
                    Conflict
                  </button>
                ))}
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <ModalWrapper
        size="large"
        headerTitle="Member Sign In/ Out"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isFooterHidden
      >
        <div className={styles.container}>
          <div>
            <div className={styles.Details}>
              <div style={{ display: "flex" }}>
                <div>{/* <img src={user.image} alt="user" /> */}</div>

                <div className={styles.textContainer}>
                  <span className={styles.name}>
                    {user.employee?.user?.first_name ?? "-"}
                  </span>

                  <div className={styles.titleIdContainer}>
                    <span>title: </span>
                    <span className={styles.content}>
                      {user?.employee?.job_title}
                    </span>
                    <span className={styles.dash}>-</span>
                    <span> ID: </span>
                    <span className={styles.content}>{user?.employee?.id}</span>
                  </div>
                </div>
              </div>

              <div>
                <Button
                  onClick={onGoToEmployeeDetails}
                  color="danger"
                  isOutline
                >
                  employee Details
                </Button>
              </div>
            </div>

            <Table
              isCheckBoxVisible={false}
              headers={[
                "in Photo",
                "Out Photo",
                "Sign-In Time",
                "Sign-Out Time",
                "Time Spent",
                "Status",
                "Shift name",
                "Location",
              ]}
              data={tableData}
              count={1}
            />
          </div>
        </div>
      </ModalWrapper>
      <ConflictLocation
        user={user}
        setIsVisible={setIsConflictModalVisible}
        isVisible={isConflictModalVisible}
        setisConflictResolved={setisConflictResolved}
        isConflictResolved={isConflictResolved}
      />
    </>
  );
}
