import Image from "components/general/Image";
import Paper from "components/general/PaperComponent";
import Text from "components/general/Text";
import COLORS from "constants/Colors";
import moment from "moment";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import convertUpperCase from "utils/convertUpperCase";
import ARROWRIGHT from "../../../assets/icons/right-arrow.svg";
import styles from "./styles.module.scss";

export default function ActivityCard({
  item,
  borderColor = COLORS.lightGrey,
  backgroundColor = "#f8f8f8",
  showArrow = true,
  isNotificaion = false,
}: {
  item: any;
  borderColor?: string;
  backgroundColor?: string;
  showArrow?: boolean;
  isNotificaion?: boolean;
}) {
  const ActivityActionMap = {
    check_in: "Checked In",
    check_out: "Checked Out",
  };

  return (
    <Paper
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingVertical={20}
      paddingHorizontal={32}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <div className={styles.memberImage}>
            <Image src={AVATAR_LOGO} alt="User Avatar" />
          </div>
          <div className="d-grid">
            {isNotificaion ? (
              <Text>{item?.body}</Text>
            ) : (
              <Text>
                {item?.creator_name || item?.staff_name} {/* @ts-ignore */}
                {ActivityActionMap[item?.type] ?? "Create"} {/* @ts-ignore */}
                {!ActivityActionMap[item?.type] &&
                  item?.type &&
                  convertUpperCase(item?.type, "_")}
              </Text>
            )}
            <Text>{`${moment(item?.date_time || item?.date).format(
              "LL"
            )} ${moment(item.time, "hh:mm:ss").format("LT")}`}</Text>
          </div>
        </div>
        <div>{showArrow && <Image src={ARROWRIGHT} alt="User Avatar" />}</div>
      </div>
    </Paper>
  );
}
