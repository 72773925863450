import { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "components/Menu";
import Icon from "components/general/Icon";
import AssignShift from "components/modals/AssignShift";
import showSuccessMsg from "utils/showSuccessMsg";
import { useDeleteAssignedShiftMutation } from "apis/services/shift";
import { StaffActionProps } from "./types";
import styles from "./styles.module.scss";

export default function StaffAction({
  containerStyle,
  employee,
  shift,
  assignType,
}: StaffActionProps) {
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const [deleteAssignedShift] = useDeleteAssignedShiftMutation();

  const onDeleteShift = () => {
    deleteAssignedShift(shift?.id)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Delete shift successfully!" });
      });
  };

  const MenuData = [
    {
      key: "profile",
      label: <Link to={`/staff/${employee?.id}`}>View Profile</Link>,
    },
    {
      key: "edit_assign",
      label: (
        <button
          onClick={() => setIsDetailsShown(true)}
          className={styles.memberActionBtn}
        >
          Edit Shift
        </button>
      ),
    },
    {
      key: "delete_assign",
      label: (
        <button onClick={onDeleteShift} className={styles.memberActionBtn}>
          Delete Shift
        </button>
      ),
    },
  ];

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Menu items={MenuData}>
        <button className={styles.memberActionBtn}>
          <Icon name="Menu" size={24} color="info" />
        </button>
      </Menu>
      {isDetailsShown && employee && (
        <AssignShift
          isVisible={isDetailsShown}
          setIsVisible={() => setIsDetailsShown(false)}
          user={employee}
          assignType={assignType}
          isUpdate
        />
      )}
    </div>
  );
}
