import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import AddUser from "pages/settings/AddUser";
import EditUser from "pages/settings/EditUser";
// import Permissions from "pages/settings/Permissions";
// import Roles from "pages/settings/Roles";
import Users from "pages/settings/Users";
import { Navigate, Outlet } from "react-router-dom";

const children = [
  {
    path: "",
    element: <Navigate to="users" />,
  },
  {
    path: "users",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "add",
        element: <AddUser />,
      },
      {
        path: ":id",
        element: <EditUser />,
      },
    ],
  },
  // {
  //   path: "roles",
  //   element: <Roles />,
  // },
  // {
  //   path: "permissions",
  //   element: <Permissions />,
  // },
];

const userAccessControlRoutes = {
  path: "settings/user-access-control",
  element: (
    <SecondarySidebarLayout list={children} title="User Access Control">
      <Outlet />
    </SecondarySidebarLayout>
  ),
  children,
};

export default userAccessControlRoutes;
