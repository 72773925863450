/* eslint-disable jsx-a11y/control-has-associated-label */
import { Pagination, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import useToggleState from "hooks/useToggleState";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import RED_DOWN_ARROW from "assets/icons/down-red-arrow.svg";
import getClassNames from "utils/getClassName";
import CheckBox from "../CheckBox";
import Text from "../Text";
import styles from "./styles.module.scss";
import { TableProps } from "./types";
import FilterButton from "../FilterButton";
import Button from "../Button";
import Image from "../Image";

export default function Table({
  containerStyle,
  headers,
  data,
  isCheckBoxVisible = true,
  isFilterBtnVisible = true,
  isActionBtnVisible = false,
  isDataCentered = false,
  isHeaderCentered = false,
  filtersToHide = [],
  showSearchFilter,
  count,
  hideCount = false,
  pageSize = 5,
  searchPlaceholder,
  isLoading = false,
}: TableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addToSelected, isSelected, replaceState } = useToggleState<string>(
    []
  );

  const onSearch = (value: string) => {
    searchParams.set("search", value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const onPageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const TargetChildMap = new Map<string, (item: string | JSX.Element) => any>([
    [
      "text",
      (item) => (
        <div className={isDataCentered ? "d-flex justify-content-center" : ""}>
          <Text>{item}</Text>
        </div>
      ),
    ],
    [
      "element",
      (item) => {
        return item;
      },
    ],
    // ["staffInfo", (item) => <StaffInfoRow ...item />]
  ]);

  return (
    <div className={styles.tableContainer}>
      {showSearchFilter && (
        <div className={styles.searchAndButton}>
          <div className={styles.search}>
            <DebouncedSearch
              defaultValue={searchParams.get("search") ?? ""}
              onSearchChange={onSearch}
              placeholder={searchPlaceholder}
            />
          </div>
          <div className={styles.btn}>
            {isFilterBtnVisible && (
              <FilterButton onClick={() => {}} filtersToHide={filtersToHide} />
            )}
            {!!isActionBtnVisible && (
              <Button
                isOutline
                color="primary"
                suffix={<Image src={RED_DOWN_ARROW} />}
              >
                Action
              </Button>
            )}
          </div>
        </div>
      )}
      <div className={`${styles.container} ${containerStyle}`}>
        <table>
          <thead>
            <tr>
              {isCheckBoxVisible && (
                <th>
                  <CheckBox
                    defaultChecked={isSelected("all")}
                    onValueChange={(e) =>
                      e
                        ? replaceState(["all", ...data.map((el) => el.id)])
                        : replaceState([])
                    }
                  />
                </th>
              )}
              {headers.map((item, key) => (
                <th key={`KeyHeaders${+key}`}>
                  <Text
                    className={getClassNames([
                      styles.headers,
                      isHeaderCentered ? styles.centeredHeader : "",
                    ])}
                  >
                    {item}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {data.length > 0 &&
                data.map((row) => {
                  return (
                    <tr key={row.id}>
                      {isCheckBoxVisible && (
                        <td>
                          <CheckBox
                            defaultChecked={isSelected(row.id)}
                            onValueChange={() => addToSelected(row.id)}
                          />
                        </td>
                      )}
                      {row.rowData.map((item, key) => (
                        <td key={`Key${row.id}${+key}`}>
                          {TargetChildMap.get(item.type)?.(item.dataItem)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>

      {!!isLoading && (
        <div className="d-flex justify-content-center align-items-center my-4">
          <Spin />
        </div>
      )}

      {count
        ? !hideCount && (
            <Pagination
              className="text-center my-3"
              pageSize={pageSize}
              total={count}
              current={Number(searchParams.get("page") ?? 1)}
              defaultCurrent={Number(searchParams.get("page") ?? 1)}
              onChange={(value: number) => onPageChange(value)}
              showSizeChanger={false}
            />
          )
        : !isLoading && <div className="py-5 text-center">No Data</div>}
    </div>
  );
}
