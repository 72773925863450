/* eslint-disable no-nested-ternary */
import { useState } from "react";
import DateFilter from "components/modals/DateFilter";
import { useGetActivitiesQuery } from "apis/services/other";
import ActivityCard from "components/cards/ActivityCard";
// import DateFilterPaper from "components/general/DateFilterPaper";
import Text from "components/general/Text";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import Paper from "components/general/PaperComponent";
import moment from "moment";
import COLORS from "constants/Colors";
import styles from "./sytles.module.scss";

export default function Activity() {
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);
  const [searchParams] = useSearchParams();

  const { data, isLoading } = useGetActivitiesQuery({
    page_size: 100,
    ordering: "-id",
    start_date: searchParams.get("startDate") ?? undefined,
    end_date:
      (searchParams.get("endDate") &&
        moment(searchParams.get("endDate"))
          .subtract(1, "days")
          .format("YYYY-MM-DD")) ??
      undefined,
  });

  return (
    <div className="p-5">
      <div className="d-flex justify-content-between mb-4">
        <Text className={styles.bold}>Activity</Text>
        <Paper
          backgroundColor="white"
          borderColor={COLORS.lightGrey}
          // paddingVertical={28}
          // paddingHorizontal={28}
        >
          <Button
            onClick={() => setIsDateFilterVisible(true)}
            btnClassName={styles.filterBtn}
            suffix={<Icon name="calendar" color="grey" size={24} />}
          >
            Filter By Date
          </Button>
        </Paper>
        <DateFilter
          setIsVisible={() => setIsDateFilterVisible(false)}
          isVisible={isDateFilterVisible}
          isRange
        />
      </div>

      {isLoading ? (
        <Spin />
      ) : data?.results?.length > 0 ? (
        <Paper
          backgroundColor="white"
          borderColor={COLORS.lightGrey}
          paddingVertical={28}
          paddingHorizontal={28}
        >
          {data.results.map((item: any) => (
            <div key={item.id} className="mb-3">
              <ActivityCard item={item} showArrow={false} />
            </div>
          ))}
        </Paper>
      ) : (
        <div className="text-center py-4">
          <Text>There&apos;re no activities yet</Text>
        </div>
      )}
    </div>
  );
}
