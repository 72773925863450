import { Navigate, Outlet } from "react-router-dom";
// import IntegrationLayout from "components/wrappers/IntegrationLayout";
import IntegrationOvertime from "pages/Integration/IntegrationOvertime";
import IntegrationMissions from "pages/Integration/IntegrationMissions";
import IntegrationTransfer from "pages/Integration/IntegrationTransfer";
import IntegrationTransactions from "pages/Integration/IntegrationTransactions";
import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";

const children = [
  {
    path: "",
    element: <Navigate to="Overtime" />,
  },
  {
    path: "Overtime",
    element: <IntegrationOvertime />,
  },
  {
    path: "Missions",
    element: <IntegrationMissions />,
  },
  {
    path: "Transfers",
    element: <IntegrationTransfer />,
  },
  {
    path: "Transaction",
    element: <IntegrationTransactions />,
  },
];

const integrationRotues = {
  path: "integration",
  element: (
    <SecondarySidebarLayout list={children}>
      <Outlet />
    </SecondarySidebarLayout>
  ),
  children,
};

export default integrationRotues;
