import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export default function ForgotPassword() {
  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}>
        We&rsquo;ve sent a verification code to your email
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={(val) => {
          console.log(val);
        }}
      >
        <Form.Item name="code" rules={[{ required: true }, { type: "string" }]}>
          <TextInput i18nLabelKey="CODE" />
        </Form.Item>
        <div className={styles.resendCode}>
          <Text className={styles.text}>Didn’t Receive Code?</Text>
          &nbsp;
          <Link className={styles.link} to="/">
            Resend Code
          </Link>
        </div>

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
