import moment from "moment";

const formatTime = (time: string | null, addedTime?: number) => {
  if (!time) {
    return "-";
  }
  let formatedTime = moment(time, "HH:mm:ss");
  if (addedTime) {
    formatedTime = formatedTime.add(addedTime, "hours");
  }
  return formatedTime.format("hh:mm A");
};

export default formatTime;
