import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ConfigProvider, Tabs } from "antd";
import type { TabsProps } from "antd";

import { FixedTemplate } from "apis/types/shift";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import { TableItemProps } from "components/general/Table/types";
import Button from "components/general/Button";
import Table from "components/general/Table";
import Paper from "components/general/PaperComponent";
import AddWorkingHour from "components/modals/AddWorkingHour";
import AddFixedTemplate from "components/modals/AddFixedTemplate";
import formatTime from "utils/formatDateTime";
import IMPORTANT_VARS from "constants/ImportantVars";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import {
  useDeleteShiftWorkinghoursMutation,
  useGetNormalShiftWorkinghoursQuery,
} from "apis/services/workingHour";
import showSuccessMsg from "utils/showSuccessMsg";
import {
  useDeleteFixedShiftTemplateMutation,
  useGetFixedShiftTemplatesQuery,
} from "apis/services/fixedShiftTemplate";
import styles from "./styles.module.scss";

const items: TabsProps["items"] = [
  {
    key: "fixed",
    label: "Fixed shifts",
  },
  {
    key: "working",
    label: "Working Hour",
  },
];

const FixedTemplateHeaders = [
  "Shift Name",
  "Start Time",
  "End Time",
  "Tolerance after Start",
  "Tolerance before end",
  "",
];

const WorkingHourHeaders = [
  "Name",
  "Working Hour",
  "With Tolerance",
  "Tolerance after start",
  "Tolerance before end",
  "",
];

interface EditWorkinghourModalConfig {
  isVisible: boolean;
  selectedWorkingHour?: any;
}

interface EditFixedShiftTemplateModalConfig {
  isVisible: boolean;
  selectedFixedShiftTemplate?: any;
}

export default function Setups() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("fixed");

  const { data: fixedTemplates, isFetching: isFixedTempLoading } =
    useGetFixedShiftTemplatesQuery(
      {
        page: searchParams.get("page") ?? "1",
        search: searchParams.get("search") ?? "",
        page_size: IMPORTANT_VARS.TabelDataPageSize,
        ordering: "-id",
      },
      {
        skip: selectedTab !== "fixed",
      }
    );
  const { data: workingHours, isFetching: isWorkingHoursLoading } =
    useGetNormalShiftWorkinghoursQuery(
      {
        page: searchParams.get("page") ?? "1",
        search: searchParams.get("search") ?? "",
        page_size: IMPORTANT_VARS.TabelDataPageSize,
        ordering: "-id",
      },
      {
        skip: selectedTab === "fixed",
      }
    );

  const [
    deleteFixedShiftTemplate,
    { isLoading: isDeletingFixedShiftTemplate },
  ] = useDeleteFixedShiftTemplateMutation();

  const [deleteShiftWorkingHour, { isLoading: isDeletingWorkingHours }] =
    useDeleteShiftWorkinghoursMutation();

  const headers =
    selectedTab === "fixed" ? FixedTemplateHeaders : WorkingHourHeaders;
  const data = selectedTab === "fixed" ? fixedTemplates : workingHours;

  const [workingHourModalShown, setWorkingHourModalShown] = useState(false);
  const [fixedTemplateModalShown, setFixedTemplateModalShown] = useState(false);
  const [editWorkingHourModalShown, setEditWorkingHourModalShown] =
    useState<EditWorkinghourModalConfig>();
  const [
    editFixedShiftTemplateModalShown,
    setEditFixedShiftTemplateModalShown,
  ] = useState<EditFixedShiftTemplateModalConfig>();

  const onChange = (key: string) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setSelectedTab(key);
  };

  const onGetDeleteShiftFunction = (shiftId: number) => () => {
    deleteFixedShiftTemplate(shiftId)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Fixed shift template deleted successfully!" });
      });
  };

  const onGetEditShiftFunction = (shiftId: number) => () => {
    setEditFixedShiftTemplateModalShown({
      isVisible: true,
      selectedFixedShiftTemplate: fixedTemplates?.results?.find(
        (item: any) => item.id === shiftId
      ),
    });
  };

  const getFixedShiftMenuItems = (shiftId: number): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Shift",
      actionFn: onGetEditShiftFunction(shiftId),
    },
    {
      key: "delete",
      label: "Delete Shift",
      actionFn: onGetDeleteShiftFunction(shiftId),
      isDanger: true,
      isLoading: isDeletingFixedShiftTemplate,
    },
  ];

  const changeStaffData = (item: FixedTemplate): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: `${item.name} (${formatTime(item.from_time)} - ${formatTime(
          item.to_time
        )})`,
      },
      { type: "text", dataItem: formatTime(item.from_time) ?? "-" },
      {
        type: "text",
        dataItem: formatTime(item.to_time),
      },
      {
        type: "text",
        dataItem: String(item?.tolerance_after_start),
      },
      { type: "text", dataItem: String(item?.tolerance_before_end) },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper
            menuData={getFixedShiftMenuItems(item?.id as number)}
          />
        ),
      },
    ],
  });

  const onGetDeleteWorkingHourFunction = (workingHourId: number) => () => {
    deleteShiftWorkingHour(workingHourId)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Workinghour deleted successfully!" });
      });
  };

  const onGetEditWorkingHourFunction = (workingHourId: number) => () => {
    setEditWorkingHourModalShown({
      isVisible: true,
      selectedWorkingHour: workingHours?.results?.find(
        (item: any) => item.id === workingHourId
      ),
    });
  };

  const getWorkingHourMenuItems = (workingHourId: number): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Working Hour",
      actionFn: onGetEditWorkingHourFunction(workingHourId),
    },
    {
      key: "delete",
      label: "Delete Working Hour",
      actionFn: onGetDeleteWorkingHourFunction(workingHourId),
      isDanger: true,
      isLoading: isDeletingWorkingHours,
    },
  ];

  const changeWorkingData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "text",
        dataItem: item.name,
      },
      {
        type: "text",
        dataItem: `${item.number_of_hrs} ${
          item.number_of_hrs <= 1 ? "Hour" : "Hours"
        }`,
      },
      {
        type: "text",
        dataItem:
          item.tolerance_before_end === 0 && item.tolerance_after_start === 0
            ? "No"
            : "Yes",
      },
      {
        type: "text",
        dataItem: item.tolerance_after_start,
      },
      {
        type: "text",
        dataItem: item.tolerance_before_end,
      },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper
            menuData={getWorkingHourMenuItems(item?.id as number)}
          />
        ),
      },
    ],
  });

  const RightComponent = (
    <div className="d-flex flex-row">
      {selectedTab !== "fixed" ? (
        <Button
          btnClassName={`mx-2 ${styles.headerBtn}`}
          onClick={() => setWorkingHourModalShown(true)}
        >
          Add working hour
        </Button>
      ) : (
        <Button
          btnClassName={styles.headerBtn}
          onClick={() => setFixedTemplateModalShown(true)}
        >
          Add new shift
        </Button>
      )}
    </div>
  );

  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title={`${items?.find((item) => item.key === selectedTab)?.label}`}
        RightComponent={RightComponent}
      />

      <Paper
        backgroundColor="white"
        borderColor="white"
        paddingHorizontal={32}
        paddingVertical={14}
      >
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                /* here is your component tokens */
                fontSize: 16,
                colorBgContainer: "#E3332D0D",
                colorBorderSecondary: "#fffff",
                colorFillAlter: "#fffff",
                padding: 24,
                margin: 0,
              },
            },
          }}
        >
          <Tabs
            type="card"
            items={items}
            onChange={onChange}
            prefixCls="tabContainer"
            defaultActiveKey={selectedTab}
          />
        </ConfigProvider>
      </Paper>

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          headers={headers}
          searchPlaceholder={`Search for ${
            selectedTab === "fixed" ? "shift name" : "working hour type"
          }`}
          data={
            data?.results?.map((item: FixedTemplate) =>
              selectedTab === "fixed"
                ? changeStaffData(item)
                : changeWorkingData(item)
            ) ?? []
          }
          isLoading={isFixedTempLoading || isWorkingHoursLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>

      {fixedTemplateModalShown && (
        <AddFixedTemplate
          isVisible={fixedTemplateModalShown}
          setIsVisible={() => setFixedTemplateModalShown(false)}
        />
      )}

      {!!editFixedShiftTemplateModalShown?.isVisible && (
        <AddFixedTemplate
          isVisible={!!editFixedShiftTemplateModalShown?.isVisible}
          setIsVisible={() =>
            setEditFixedShiftTemplateModalShown({ isVisible: false })
          }
          isUpdate
          initValues={
            editFixedShiftTemplateModalShown?.selectedFixedShiftTemplate
          }
        />
      )}

      {workingHourModalShown && (
        <AddWorkingHour
          isVisible={workingHourModalShown}
          setIsVisible={() => setWorkingHourModalShown(false)}
        />
      )}

      {!!editWorkingHourModalShown?.isVisible && (
        <AddWorkingHour
          isVisible={editWorkingHourModalShown?.isVisible}
          setIsVisible={() =>
            setEditWorkingHourModalShown({ isVisible: false })
          }
          isUpdate
          initValues={editWorkingHourModalShown?.selectedWorkingHour}
        />
      )}
    </div>
  );
}
