/* eslint-disable */

import dayjs from "dayjs";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  useCreateRequestMutation,
  useGetRequestCategoriesQuery,
} from "apis/services/requests";
import { useGetAllStaffQuery } from "apis/services/staff";
import {
  useGetLocationsQuery,
  useGetZonesQuery,
  useGetProjectsQuery,
} from "apis/services/locations";
import TextInput from "components/inputs/TextInput";
import UserInfo from "components/cards/UserInfo";
import SelectionInput from "components/inputs/SelectionInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import showSuccessMsg from "utils/showSuccessMsg";
import ModalWrapper from "../../ModalWrapper";
// import { CreateRequestProps } from "../types";
import styles from "../styles.module.scss";
import React, { useState } from "react";
import moment from "moment";
import { RequestsTypes } from "constants/Requests";

export default function Mission({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate,
  setisConflictResolved = () => {},
  created_for,
  request,
}: any) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const request_created_for =
    created_for?.employee || request?.created_for || userData;
  const [form] = Form.useForm();

  const [createRequest] = useCreateRequestMutation();

  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const { data: categories } = useGetRequestCategoriesQuery({ page_size: 100 });
  const zoneValue = Form.useWatch("zone", form);

  const { data: locations } = useGetLocationsQuery(
    { page_size: 100, zone: zoneValue },
    {
      skip: !zoneValue,
    }
  );
  const locationValue = Form.useWatch("location", form);

  const { data: projects } = useGetProjectsQuery(
    {
      location: locationValue,
      page_size: 100,
    },
    {
      skip: !locationValue,
    }
  );
  const { data: managers } = useGetAllStaffQuery({
    role: "manager",
    employment_type: 1,
    page_size: 100,
  });

  const adminsOptions = managers?.results?.map((item: any) => ({
    label: `${item?.user?.username} (${item?.cfm_id})`,
    value: item?.id,
  }));

  const zoneOptions = zones?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const locationsOptions = locations?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const projectsOptions = projects?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const projectIDsOptions = projects?.results?.map((item: any) => ({
    label: `${item.name} (${item.project_id})`,
    value: item.id,
  }));
  const groupOptions = categories?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  console.log(projects?.results);

  const onFinish = (values: {
    project: number;
    employee_ids: any;
    start_date?: string;
    end_date?: string;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      URL_request_type: "mission_requests",
      request_type: RequestsTypes.mission_requests,
      created_for: values?.employee_ids?.value ?? request_created_for?.id,
      attachments: [],
      delegated_to: [],
    };

    if (request && isUpdate) {
      // @ts-ignore
      data.id = request.id;
    }

    if (values?.start_date) {
      // data.start_date = dayjs(data.start_date).format("YYYY-MM-DD");
      // data.end_date = dayjs(data.end_date).format("YYYY-MM-DD");
      data.start_date = dayjs(data.start_date).format("YYYY-MM-DDThh:mm");
      data.end_date = dayjs(data.end_date).format("YYYY-MM-DDThh:mm");
    }
    //! The next line is just a workaround for making the request, assigned_projects needs to be a collecting list.
    // @ts-ignore
    if (data.assigned_projects) {
      // @ts-ignore
      data.assigned_projects = Array.isArray(data.assigned_projects)
        ? // @ts-ignore
          data.assigned_projects
        : // @ts-ignore
          [data.assigned_projects];
    } else {
      // @ts-ignore
      data.assigned_projects = [];
    }

    createRequest(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request created successfully!" });
        setIsVisible(null);
        try {
          setisConflictResolved(true);
        } catch {
          // ignore setter if from request and not from conflicts
        }
      });
  };

  const [customEndDateError, setcustomEndDateError] = useState(false);
  const handleZoneField = (changedValues: any, allValues: any) => {
    if ("zone" in changedValues) {
      form.resetFields(["location"]);
    }
    if (allValues.start_date && "end_date" in changedValues) {
      const start_date = moment(allValues.start_date);
      const end_date = moment(changedValues.end_date);
      const isAfterStart = end_date.isAfter(start_date);
      if (isAfterStart) {
        setcustomEndDateError(false);
      } else {
        setcustomEndDateError(true);
      }
    }
  };

  const getFormInitialValues = React.useMemo(() => {
    return {
      start_date: request?.start_date,
      end_date: request?.start_date,
      created_by: request?.created_by?.id,
      reason: request?.reason,
      location: request?.location,
      zone: request?.zone,
      assigned_projects: request?.assigned_projects,
      project: request?.project?.id,
      category: request?.category,
      new_reports_to: request?.new_reports_to,
    };
  }, [request]);
  console.log(request, "requueesttttt");

  return (
    <ModalWrapper
      size="large"
      headerTitle="Request Mission"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={getFormInitialValues}
            onValuesChange={handleZoneField}
          >
            {!isMultipleAssign ? (
              <div className={styles.memberContent}>
                <UserInfo data={request_created_for} />
              </div>
            ) : (
              <Form.Item
                name="employee_ids"
                label="Assignees Member"
                rules={[{ required: true }]}
              >
                <AssigneeSelection isMulti={false} />
              </Form.Item>
            )}

            <Form.Item
              name="start_date"
              label="Start Date and Time"
              rules={[{ required: true }]}
            >
              <DatePickerInput shouldShowTime />
            </Form.Item>

            <Form.Item
              name="end_date"
              label="End Date and Time"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!customEndDateError) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "End date must be after start date"
                      );
                    }
                  },
                },
              ]}
            >
              <DatePickerInput shouldShowTime />
            </Form.Item>

            <Form.Item name="zone" label="Zone" rules={[{ required: true }]}>
              <SelectionInput options={zoneOptions} />
            </Form.Item>

            <Form.Item
              name="location"
              label="Location"
              rules={[{ required: isUpdate }]}
            >
              <SelectionInput options={locationsOptions} mode="multiple" />
            </Form.Item>

            <Form.Item
              name="project"
              label="Default Project"
              rules={[{ required: isUpdate }]}
            >
              <SelectionInput options={projectIDsOptions ?? []} />
            </Form.Item>
            <Form.Item
              name="new_reports_to"
              label="Reporting to"
              // initialValue={request_created_for?.reports_to?.id}
              rules={[{ required: isUpdate }]}
            >
              <SelectionInput options={adminsOptions ?? []} />
            </Form.Item>
            <Form.Item
              name="assigned_projects"
              label="Project ID"
              rules={[{ required: isUpdate }]}
            >
              <SelectionInput options={projectIDsOptions ?? []} />
            </Form.Item>

            <Form.Item
              name="category"
              label="Category ID"
              rules={[{ required: isUpdate }]}
            >
              <SelectionInput options={groupOptions} />
            </Form.Item>

            <Form.Item name="reason">
              <TextInput type="textarea" placeholder="Reason (Option)" />
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
