import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import { MultiSelectionProps } from "./types";
import styles from "./styles.module.scss";

export default function MultiSelection<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  onChange,
  value,
  ...props
}: MultiSelectionProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (search: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(search).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <div className={styles.container}>
      <Select
        mode="multiple"
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        showSearch
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        value={value}
        onChange={(e, onChangeoptions) => {
          onChange?.(e, onChangeoptions);
        }}
        options={options}
      />
    </div>
  );
}
