import { Radio } from "antd";
import { RadioGroupInputProps } from "./types";
import styles from "./styles.module.scss";

export default function RadioGroupInput({
  containerStyle,
  onChange,
  value,
  options,
}: RadioGroupInputProps) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Radio.Group
        onChange={({ target }) => {
          onChange?.(target?.value);
        }}
        value={value}
      >
        {options?.map((item) => (
          <Radio value={item?.value}>{item?.label}</Radio>
        ))}
      </Radio.Group>
    </div>
  );
}
