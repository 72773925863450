import Text from "components/general/Text";
import styles from "./styles.module.scss";
import { SecondaryPageHeaderProps } from "./types";

export default function SecondaryPageHeader({
  RightComponent,
  title,
  SubTitleComponent,
}: SecondaryPageHeaderProps) {
  return (
    <header>
      <div>
        <Text className={styles.title}>{title}</Text>
        {SubTitleComponent}
      </div>

      {RightComponent}
    </header>
  );
}
