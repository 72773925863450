import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { Navigate, Outlet } from "react-router-dom";
import { useGetStaffInfoQuery } from "apis/services/auth";
import { useEffect } from "react";
import { setUserInfo } from "reducers/authReducer";

export default function ProtectedOutlet() {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const { data } = useGetStaffInfoQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    if (data) dispatch(setUserInfo(data));
  }, [data]);

  return token ? <Outlet /> : <Navigate to="/auth" />;
}
