import styles from "./styles.module.scss";
import { PaperProps } from "./types";

export default function Paper({
  children,
  borderColor,
  backgroundColor,
  paddingVertical,
  paddingHorizontal,
}: PaperProps) {
  return (
    <div
      className={`${styles.container}`}
      style={{
        borderColor,
        backgroundColor,
        paddingBlock: paddingVertical,
        paddingInline: paddingHorizontal,
      }}
    >
      {children}
    </div>
  );
}
