import api from "apis";
import store from "reducers";
import { login, setIsNewUser } from "reducers/authReducer";

export default function loginHandler({
  token = "",
  refreshToken = "",
  isNew = false,
} = {}) {
  if (token) localStorage.setItem("token", token);
  if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
  store.dispatch(api.util.resetApiState());
  store.dispatch(login(token));
  store.dispatch(setIsNewUser(isNew));
}
