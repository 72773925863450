import moment from "moment";
import { useState, useEffect } from "react";
import convertSecondsToFullTime from "utils/convertSecondsToFullTime";

const useTimeElapsed = (pastTime: string) => {
  const [secondsElapsed, setSecondsElapsed] = useState<number | string>(0);

  const pastTimeMoment = moment(pastTime);

  const changeCounterHandler = () => {
    const checkInTimeMoment = moment(pastTimeMoment, "HH:mm");

    const counterValueFromNowInSeconds = moment
      .duration(moment().diff(checkInTimeMoment))
      .asSeconds();

    if (!counterValueFromNowInSeconds || counterValueFromNowInSeconds < 0) {
      return;
    }

    const adjustedSeconds = Math.floor(counterValueFromNowInSeconds);
    setSecondsElapsed(convertSecondsToFullTime(adjustedSeconds));
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    const canCount = !!pastTime;

    if (canCount) {
      changeCounterHandler();
      intervalId = setInterval(() => {
        changeCounterHandler();
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [pastTime]);

  return secondsElapsed;
};

export default useTimeElapsed;
