import UserInfo from "components/cards/UserInfo";

import { useGetDetailedStaffOvertimesQuery } from "apis/services/overtime";
import { useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
// import StatusView from "components/pages/home/status-view";
import useGetUserInfo from "hooks/useGetUserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import moment from "moment";
import StatusBadge from "components/general/StatusBadge";

export default function IntegrationOvertime() {
  const [searchParams] = useSearchParams();
  const { role } = useGetUserInfo();

  const { data, isFetching: isEmployeeShiftsLoading } =
    useGetDetailedStaffOvertimesQuery(
      {
        page: searchParams.get("page") ?? 1,
        search: searchParams.get("search") ?? "",
        start_date: searchParams.get("startDate") ?? "",
        end_date: searchParams.get("endDate") ?? "",
        status: 1,
      },
      { skip: !role }
    );

  const changeStaffData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: (
          <UserInfo
            data={
              {
                ...item,
                cfm_id: item?.employee_cfm_id,
                job_title: item?.employee_job_title,
                user: {
                  first_name: item?.employee_username?.split(" ")?.[0] || "",
                  last_name: item?.employee_username?.split(" ")?.[1] || "",
                },
              } ?? item.conflict?.employee
            }
            isTableCol
          />
        ),
      },
      {
        type: "text",
        dataItem: String(moment(item?.date).format("DD-MM-YYYY")),
      },
      {
        type: "text",
        dataItem: item?.project_for_paid_overtime?.project_id || "--",
      },
      {
        type: "text",
        dataItem: item?.category_name || "--",
      },
      {
        type: "text",
        dataItem: item?.paid_hrs,
      },
      {
        type: "text",
        dataItem: (
          <StatusBadge
            iconType={item.status ? "success" : "fail"}
            color={item.status ? "success" : "warning"}
          />
        ),
      },
      {
        type: "text",
        dataItem: (
          <StatusBadge
            iconType={item?.is_synced ? "success" : "fail"}
            color={item?.is_synced ? "success" : "warning"}
          />
        ),
      },
    ],
  });
  return (
    <div>
      <SecondaryPageHeader title="Overtime" />

      <Table
        isCheckBoxVisible={false}
        showSearchFilter
        headers={[
          "Members ID",
          "Date",
          "Project ID",
          "Category",
          "Paid",
          "Status",
          "Is Synced",
        ]}
        data={data?.results?.map((item: any) => changeStaffData(item)) ?? []}
        isLoading={isEmployeeShiftsLoading}
        count={data?.count}
      />
    </div>
  );
}
