import COLORS from "constants/Colors";
import { CgClose } from "react-icons/cg";
import { BsCheck2 } from "react-icons/bs";
import { CSSProperties } from "react";
import styles from "./styles.module.scss";
import { StatusBadgeProps } from "./types";

export default function StatusBadge({
  containerStyle,
  iconType,
  color,
  text,
  width,
}: StatusBadgeProps) {
  const changeStatusBadge = () => {
    if (iconType === "success") {
      return <BsCheck2 />;
    }

    if (iconType === "fail") {
      return <CgClose />;
    }

    return <div>{text}</div>;
  };

  const badgeCOlorStyle: CSSProperties = {
    color: COLORS[color],
    backgroundColor: `${COLORS[color]}1a`,
    width,
  };

  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      style={badgeCOlorStyle}
    >
      {changeStatusBadge()}
    </div>
  );
}
