import COLORS from "constants/Colors";
import styles from "./styles.module.scss";
import { LoadingIndicatorProps } from "./types";

export default function LoadingIndicator({
  color = "light",
  isFullscreen = false,
}: LoadingIndicatorProps) {
  const style = {
    border: `2px solid ${COLORS[color]}`,
    borderColor: `${COLORS[color]} transparent transparent transparent`,
  };

  return (
    <div className={isFullscreen ? styles.fullscreen : ""}>
      <div className={styles["lds-ring"]}>
        <div style={style} />
        <div style={style} />
        <div style={style} />
        <div style={style} />
      </div>
    </div>
  );
}
