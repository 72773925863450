import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import styles from "./styles.module.scss";

export default function ResetPassword() {
  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}>Enter your new password</Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={(val) => {
          console.log(val);
        }}
      >
        <Form.Item name="newPassword" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="NEWPASSWORD" type="password" />
        </Form.Item>

        <Form.Item name="confirmPassword" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="CONFIRMPASSWORD" type="password" />
        </Form.Item>

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
