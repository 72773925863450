import api from "apis";
import { PaginationParams } from "apis/types/general";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const departmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getZones: build.query<any, PaginationParams>({
      query: (params) => ({
        url: "/locations/zones/",
        params,
      }),
      providesTags: ["Zone"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    addZone: build.mutation({
      query: (body) => ({
        url: "/locations/zones/",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Zone"],
    }),
    editZone: build.mutation({
      query: (body) => ({
        url: `/locations/zones/${body.id}/`,
        body,
        method: "PATCH",
      }),
      invalidatesTags: ["Zone"],
    }),
  }),
});

export const { useGetZonesQuery, useAddZoneMutation, useEditZoneMutation } =
  departmentApi;
