import { useGetStaffInfoQuery } from "apis/services/auth";
import { useSelector } from "react-redux";
import { RootState } from "reducers";

export default function useGetUserInfo() {
  const token = useSelector((state: RootState) => state.auth.token);

  const { data: userInfo, isFetching: isUserInfoLoading } =
    useGetStaffInfoQuery(undefined, { skip: !token });

  return {
    isAdmin: userInfo?.role === "admin",
    userInfo,
    isUserInfoLoading,
    role: userInfo?.role,
  };
}
