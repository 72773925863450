/* eslint-disable */

import { useCallback, useMemo, useState } from "react";
import { Form } from "antd";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import TextInput from "components/inputs/TextInput";
import SelectionInput from "components/inputs/SelectionInput";
import styles from "./styles.module.scss";
import Button from "components/general/Button";
import Text from "components/general/Text";
import {
  useGetDepartmentsQuery,
  useGetProjectsQuery,
  useGetZonesQuery,
} from "apis/services/locations";
import {
  useGetAllStaffQuery,
  useLazyGetAllStaffQuery,
} from "apis/services/staff";
import useGetReportsList from "hooks/useGetReportsList";
import DatePickerInput from "components/inputs/DatePickerInput";
import {
  useGetRequestsSetupQuery,
  useLazyGetReportFileQuery,
} from "apis/services/other";
import SingleSelection from "components/inputs/SingleSelection";

function Reports() {
  const [form] = Form.useForm();
  const [selectedReport, setselectedReport] = useState("");

  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const { data: departments } = useGetDepartmentsQuery({ page_size: 100 });
  const { data: projects } = useGetProjectsQuery({
    page_size: 100,
  });
  const [getUsers] = useLazyGetAllStaffQuery();

  const { data: users } = useGetAllStaffQuery({
    // role: "manager",
    page_size: 10,
  });

  const { data: requests } = useGetRequestsSetupQuery({
    page_size: 100,
  });
  const [getReportType] = useLazyGetReportFileQuery();

  const form_list = useGetReportsList({
    departments,
    users,
    zones,
    projects,
    requests,
  });

  const shown_form = useMemo(
    //   @ts-ignore
    () => form_list?.[selectedReport - 1]?.data,
    [selectedReport]
  );

  const getUsersOptions = async (search: string) => {
    return getUsers({ search, page_size: 15 })
      .unwrap()
      .then(
        (res) =>
          res?.results?.map((item: any) => ({
            label: `${item?.user?.username} (${item?.cfm_id})`,
            value: item?.cfm_id,
            key: `${item?.id}`,
          }))
      );
  };

  // function tarsh 3amlha 34an trg3 input type based 3la el type ely mb3otlaha originally mn el form_list
  const handleInputType = useCallback(
    (type = "normal", placeholder = "Chose", inputOptions = []) => {
      const inputsEnum = {
        normal: <TextInput placeholder={placeholder} />,
        date: <DatePickerInput />,
        users: <SingleSelection fetchOptions={getUsersOptions} />,
        select: (
          <SelectionInput placeholder={placeholder} options={inputOptions} />
        ),
      };
      // @ts-ignore
      return inputsEnum[type] || inputsEnum["normal"];
    },
    [selectedReport]
  );

  const handleSubmit = (values: any) => {
    const requested_data = {
      ...values,
      //   @ts-ignore
      url: form_list?.[selectedReport - 1]?.url,
    };
    getReportType(requested_data)
      .unwrap()
      .then((res: any) => {
        const sheet = res?.sheet || res[1]["label"];
        window.open(sheet, "_blank");
      });
    form.resetFields();
    // setselectedReport("");
  };

  return (
    <div className={styles.container}>
      <SecondaryPageHeader title="Reports" />
      <Text style={{ fontWeight: 700, fontSize: 18 }}>Select a project:-</Text>
      <SelectionInput
        options={form_list}
        placeholder="Select a report"
        value={selectedReport}
        onChange={setselectedReport}
        containerStyle="mt-2"
      />
      {selectedReport && (
        <div className="my-5">
          <Form
            form={form}
            onFinish={handleSubmit}
            // onFieldsChange={handleFormChange}
            layout="vertical"
            scrollToFirstError
            className="w-100"
          >
            {shown_form?.map((item: any) => (
              <Form.Item name={item.name} label={item.label}>
                {handleInputType(item.type, item.label, item?.options)}
              </Form.Item>
            ))}
          </Form>
        </div>
      )}
      {selectedReport && (
        <div className="d-flex align-items-center justify-content-end gap-3">
          <Button
            onClick={() => {
              setselectedReport("");
            }}
            isOutline
          >
            Cancel
          </Button>
          <Button onClick={() => form.submit()}>Confirm</Button>
        </div>
      )}
    </div>
  );
}
export default Reports;
