import { Navigate, Outlet } from "react-router-dom";
import MyRequests from "pages/requests/MyRequests";
import TeamRequests from "pages/requests/TeamRequests";
import CreateNewRequest from "pages/requests/MyRequests/CreateNewRequest";
import CreateNewMemberRequest from "pages/requests/TeamRequests/CreateNewRequest";
import Requests from "pages/requests";

const children = [
  {
    path: "",
    element: <Navigate to="my requests" />,
  },
  {
    path: "my requests",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <MyRequests />,
      },
      {
        path: "create-new-request",
        element: <CreateNewRequest />,
      },
    ],
  },
  {
    path: "team Requests",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <TeamRequests />,
      },
      {
        path: "create-new-request",
        element: <CreateNewMemberRequest />,
      },
    ],
  },
];

const requestsRoutes = {
  path: "requests",
  element: <Requests list={children} />,
  children,
};

export default requestsRoutes;
