import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import StatusBadge from "components/general/StatusBadge";
import Table from "components/general/Table";
import IMPORTANT_VARS from "constants/ImportantVars";
import { useSearchParams } from "react-router-dom";
import { useGetDetailedStaffOvertimesQuery } from "apis/services/overtime";
import moment from "moment";
import styles from "./styles.module.scss";

export default function IntegrationMissions() {
  const [searchParams] = useSearchParams();

  const { data, isFetching: isStaffLoading } =
    useGetDetailedStaffOvertimesQuery({
      page: Number(searchParams.get("page") ?? "1"),
      search: searchParams.get("search") || undefined,
      status: "1",
      start_date: searchParams.get("startDate") || undefined,
      end_date: searchParams.get("endDate") || undefined,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
      // request_type: "mission",
    });
  const changeStaffData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item.created_for} isTableCol />,
      },
      {
        type: "text",
        dataItem: String(moment(item?.start_date).format("DD-MM-YYYY")),
      },
      {
        type: "text",
        dataItem: String(item?.id),
      },
      {
        type: "text",
        dataItem: String(item?.project?.project_id),
      },
      {
        type: "text",
        dataItem: item?.category_name || "--",
      },
      {
        type: "element",
        dataItem: (
          <StatusBadge
            text={item?.status}
            color={item?.status === "accepted" ? "success" : "warning"}
            containerStyle={styles.statusBadge}
          />
        ),
      },
      {
        type: "text",
        dataItem: (
          <StatusBadge
            iconType={item?.is_synced ? "success" : "fail"}
            color={item?.is_synced ? "success" : "warning"}
          />
        ),
      },
    ],
  });
  return (
    <>
      <SecondaryPageHeader title="Missions" />

      <Table
        isCheckBoxVisible={false}
        showSearchFilter
        isLoading={isStaffLoading}
        headers={[
          "Members ID",
          "Date",
          "Request ID",
          "Project ID",
          "Category",
          "Status",
          "Is Synced",
        ]}
        data={data?.results.map((item: any) => changeStaffData(item)) ?? []}
        count={data?.count}
        pageSize={IMPORTANT_VARS.TabelDataPageSize}
      />
    </>
  );
}
