/* eslint-disable */

import { useParams } from "react-router-dom";

import { useGetStaffMemberInfoQuery } from "apis/services/staff";

import Button from "components/general/Button";
import Image from "components/general/Image";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import InfoRow from "components/general/InfoRow";
import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import CalendarRow from "components/general/CalendarRow";
import FilterButton from "components/general/FilterButton";
import RED_DOWN_ARROW from "assets/icons/down-red-arrow.svg";

import styles from "./styles.module.scss";
import { useGetCalenderLeaveBalanceQuery } from "apis/services/other";
import CustomTable from "components/general/CustomTable";

export default function StaffLeaveBalance() {
  const { id } = useParams();
  const { data } = useGetStaffMemberInfoQuery(id!);
  const { data: leaveBalance, isFetching } = useGetCalenderLeaveBalanceQuery({
    search: data?.cfm_id,
  });

  const INFODATA = [
    {
      title: "Present",
      info: 255,
    },
    {
      title: "Permissions",
      info: 43,
    },
    {
      title: "Absent",
      info: 7,
    },
  ];

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={data} />
      </Paper>

      {/* <div className="mt-4">
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </div> */}
      <div className="mt-4">
        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingVertical={20}
          paddingHorizontal={32}
        >
          <SecondaryPageHeader
            title="Leave Balance"
            RightComponent={
              <div className="d-flex flex-row">
                {/* <div className="mx-2">
                  <FilterButton onClick={() => {}} filtersToHide={[]} />
                </div> */}
                {/* <Button
                  isOutline
                  color="primary"
                  suffix={<Image src={RED_DOWN_ARROW} />}
                >
                  Action
                </Button> */}
              </div>
            }
          />
          <CustomTable
            data={leaveBalance?.results}
            isLoading={isFetching}
            showSearchFilter={false}
            isFilterBtnVisible={false}
          />
        </Paper>
      </div>
    </div>
  );
}
