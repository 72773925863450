import { useEffect, useState } from "react";
import Button from "components/general/Button";
import Text from "components/general/Text";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { DateFilterProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

export default function DateFilter({
  isVisible,
  setIsVisible,
  isRange,
  isPrimary = true,
}: DateFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { RangePicker } = DatePicker;
  const [range, setRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [daysCount, setDaysCount] = useState(0);

  const LeftBtns: [string, () => void][] = [
    [
      "Today",
      () => {
        setRange([dayjs(), dayjs()]);
      },
    ],
    [
      "Yesterday",
      () => {
        setRange([dayjs().add(-1, "day"), dayjs().add(-1, "day")]);
      },
    ],
    [
      "Last Week",
      () => {
        setRange([dayjs().add(-1, "week"), dayjs()]);
      },
    ],
    [
      "Last Month",
      () => {
        setRange([dayjs().add(-1, "month"), dayjs()]);
      },
    ],
    [
      "Last Year",
      () => {
        setRange([dayjs().add(-1, "year"), dayjs()]);
      },
    ],
    [
      "All Time",
      () => {
        setRange([null, null]);
      },
    ],
  ];

  const startDateStr = range[0]?.format("YYYY-MM-DD") || "";
  const endDateStr = range[1]?.format("YYYY-MM-DD") || "";

  const handleInitialParams = () => {
    if (isPrimary) {
      const startDate =
        searchParams.get("startDate") || dayjs().format("YYYY-MM-DD");
      const endDate = searchParams.get("endDate")
        ? dayjs(searchParams.get("endDate"))
            .add(-1, "days")
            .format("YYYY-MM-DD")
        : dayjs().add(1, "days").format("YYYY-MM-DD");
      setRange([dayjs(startDate), dayjs(endDate)]);
    } else {
      const startDate =
        searchParams.get("summaryStartDate") || dayjs().format("YYYY-MM-DD");
      const endDate = searchParams.get("summaryEndDate")
        ? dayjs(searchParams.get("summaryEndDate"))
            .add(-1, "days")
            .format("YYYY-MM-DD")
        : dayjs().add(1, "days").format("YYYY-MM-DD");
      setRange([dayjs(startDate), dayjs(endDate)]);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleInitialParams();
  }, [isVisible]);

  const onConfirm = () => {
    if (isPrimary) {
      searchParams.set("page", "1");
      searchParams.set("startDate", startDateStr);
      searchParams.set("endDate", endDateStr);
    } else {
      searchParams.set("summaryStartDate", startDateStr);
      searchParams.set("summaryEndDate", endDateStr);
    }
    setSearchParams(searchParams);
    setIsVisible(false);
  };

  const onChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      setRange([dates[0], dates[1]]);
    }
  };

  useEffect(() => {
    if (range[1]) {
      if (range[0] === range[1]) {
        setDaysCount(0);
      } else {
        const startDate = range[0];
        const endDate = range[1];
        const numberOfDays = endDate.diff(startDate, "day");

        setDaysCount(numberOfDays);
      }
    } else {
      setDaysCount(0);
    }
  }, [range]);

  const isWrongRange = !range[0] || !range[1];

  return (
    <ModalWrapper
      headerTitle="Filter by Date"
      size="large"
      isFooterHidden
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    >
      <div className={styles.container}>
        <div className={styles.bodySection}>
          <div className={styles.leftBtnsContainer}>
            <Text className={styles.leftTitle}>Predefined periods:</Text>
            <div className={styles.leftBtns}>
              {LeftBtns.map((item, index) => {
                if (index === LeftBtns.length - 1)
                  return (
                    <Button key={item[0]} onClick={() => item[1]()}>
                      {item[0]}
                    </Button>
                  );
                return (
                  <Button
                    key={item[0]}
                    color="dark"
                    isOutline
                    btnClassName={styles.customBtn}
                    onClick={() => item[1]()}
                  >
                    {item[0]}
                  </Button>
                );
              })}
            </div>
          </div>
          <div
            className={styles.datePickersContainer}
            id="datePickersContainer"
          >
            {isRange ? (
              <RangePicker
                getPopupContainer={(trigger: HTMLElement | null) => {
                  return (
                    (trigger?.parentNode as HTMLElement) ??
                    document.getElementById("datePickersContainer")
                  );
                }}
                value={range}
                onChange={onChange}
                open
                popupClassName={styles.popup}
                style={{
                  display: "none",
                }}
              />
            ) : (
              <DatePicker
                getPopupContainer={(trigger: HTMLElement | null) => {
                  return (
                    (trigger?.parentNode as HTMLElement) ??
                    document.getElementById("datePickersContainer")
                  );
                }}
                value={range[0]}
                onChange={(v) => onChange([v, v])}
                open
                popupClassName={styles.popup}
                style={{
                  display: "none",
                }}
              />
            )}
          </div>
        </div>
        <div className={`${styles.modalContainer} ${styles.footer}`}>
          <div className={styles.count}>
            <Text className={styles.first}>Selected: </Text>
            <Text className={styles.second}>
              {range[1] ? daysCount + 1 : daysCount}{" "}
              {daysCount > 1 ? "days" : "day"}
            </Text>
          </div>
          <Button onClick={onConfirm} disabled={isRange && isWrongRange}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
