import getClassNames from "utils/getClassName";
import { CSSProperties } from "react";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";
import { ButtonProps } from "./types";
import LoadingIndicator from "../LoadingIndicator";

export default function Button({
  children,
  suffix,
  btnClassName = "",
  isOutline = false,
  color = "primary",
  onClick = () => {},
  type = "button",
  disabled = false,
  isLoading = false,
}: ButtonProps) {
  const buttonClassName = getClassNames([
    "btn",
    `btn-${color}`,
    styles.customBtn,
    btnClassName,
  ]);

  const outlineExtraStyle: CSSProperties = {
    color: COLORS[color],
    borderColor: COLORS[color],
    backgroundColor: COLORS.light,
  };

  const solidExtraStyle: CSSProperties = {
    color: COLORS.light,
    borderColor: COLORS[color],
    backgroundColor: COLORS[color],
  };

  return (
    <button
      style={isOutline ? outlineExtraStyle : solidExtraStyle}
      className={buttonClassName}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {children}
      {!!isLoading && <LoadingIndicator />}
      {!!suffix && (
        <div className="d-flex justify-content-center align-items-center">
          {suffix}
        </div>
      )}
    </button>
  );
}
