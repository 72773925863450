import React, { CSSProperties } from "react";

export default function Text({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
}
