/* eslint-disable */

import dayjs from "dayjs";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { useCreateRequestMutation } from "apis/services/requests";
import TextInput from "components/inputs/TextInput";
import UserInfo from "components/cards/UserInfo";
import SelectionInput from "components/inputs/SelectionInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import Paper from "components/general/PaperComponent";
import InfoRow from "components/general/InfoRow";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import UploadFiles from "components/inputs/UploadFiles";
import Button from "components/general/Button";
import Text from "components/general/Text";
import showSuccessMsg from "utils/showSuccessMsg";
import moment from "moment";
import ModalWrapper from "../../ModalWrapper";
import { CreateRequestProps } from "../types";
import styles from "../styles.module.scss";
import { useMemo, useState } from "react";
import {
  useGetLeaveBalanceQuery,
  useGetRequestsSetupQuery,
} from "apis/services/other";
import IMPORTANT_VARS from "constants/ImportantVars";
import { useNavigate } from "react-router-dom";
import { useGetStaffOvertimeIDQuery } from "apis/services/overtime";
import handleNumberDecimals from "hooks/useHandleNumberDecimals";
import { RequestsTypes } from "constants/Requests";

export default function Leave({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate,
}: CreateRequestProps) {
  // @ts-ignore
  const userData = useSelector((state: RootState) => state.auth.userData);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const employeeValue = Form.useWatch("employee_ids", form);

  const [createRequest] = useCreateRequestMutation();
  const { data: requestsList } = useGetRequestsSetupQuery(
    {
      group: "leave",
      gender: isMultipleAssign ? employeeValue?.data?.gender : userData?.gender,
      page_size: 100,
    },
    { skip: !employeeValue && isMultipleAssign }
  );
  const { data: requestsListForBoth } = useGetRequestsSetupQuery(
    {
      group: "leave",
      gender: "both",
      page_size: 100,
    },
    { skip: !employeeValue && isMultipleAssign }
  );
  const combined_types =
    requestsList?.results.concat(requestsListForBoth?.results) || [];
  const { data: overtime } = useGetStaffOvertimeIDQuery({
    employee: userData?.id,
  });

  const { data: leaveList } = useGetLeaveBalanceQuery({
    page_size: IMPORTANT_VARS.TabelDataPageSize,
    cfm_id: userData?.id,
  });

  // const leaveBalanceCalculations = useMemo(() => {
  //   let all = 0;
  //   let used = 0;
  //   leaveList?.results?.forEach((item: any) => {
  //     if (item.balance_in_days) all += item.balance_in_days;
  //     if (item.used_balance_in_days) used += item.used_balance_in_days;
  //   });

  //   return { all: all, used: used, available: all - used };
  // }, [leaveList]);

  const requestOptions =
    combined_types?.map((item: any) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    }) || [];

  const onFinish = (values: {
    template_id: number;
    employee_ids: any;
    start_date: string;
    end_date: string;
    reason: string;
    attachments: any;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      URL_request_type: "leave_requests",
      request_type: RequestsTypes.leave_requests,
      created_for: values?.employee_ids?.value ?? userData?.id,
    };

    if (values?.start_date) {
      data.start_date = dayjs(data.start_date).format("YYYY-MM-DD");
      data.end_date = dayjs(data.end_date).format("YYYY-MM-DD");
    }

    const formdata = new FormData();

    Object.keys(data).forEach((key: any) => {
      const k = key as keyof typeof data;
      if (key === "attachments") {
        if (data.attachments && data.attachments.length > 0) {
          data.attachments?.map((item: any, index: number) => {
            formdata.append(`attachments[${index}]`, item.originFileObj);
            return item.originFileObj;
          });
        }
      } else if (data[k]) {
        formdata.append(key, data[k]);
      }
    });

    createRequest({ formdata, ...data })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request created successfully!" });
        setIsVisible(false);
      });
  };

  const [customEndDateError, setcustomEndDateError] = useState(false);

  const handleDisabledFields = (changedFields: any, allValues: any) => {
    if (allValues.start_date && "end_date" in changedFields) {
      const start_date = moment(allValues.start_date);
      const end_date = moment(changedFields.end_date);
      const isAfterStart = end_date.isSameOrAfter(start_date);
      if (isAfterStart) {
        setcustomEndDateError(false);
      } else {
        setcustomEndDateError(true);
      }
    }
  };

  const decimalNumber = (number: number) => {
    if (!number) return 0;
    const hasDecimals = Number.isInteger(number) === false;

    return hasDecimals ? number.toFixed(1) : number;
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Request Leaves"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleDisabledFields}
          >
            {!isMultipleAssign ? (
              <div className={styles.memberContent}>
                <UserInfo data={userData} />
              </div>
            ) : (
              <Form.Item
                name="employee_ids"
                label="Assignees Members"
                rules={[{ required: true }]}
              >
                <AssigneeSelection isMulti={false} />
              </Form.Item>
            )}

            {!isMultipleAssign && (
              <>
                <div
                  className="border"
                  style={{
                    borderRadius: 12,
                  }}
                >
                  <SeparatedRowElements containerStyle="border-0 pb-0 mb-2">
                    <InfoRow
                      title="All:"
                      info={`${handleNumberDecimals(
                        leaveList?.total / 8,
                        2
                      )} Days`}
                      infoFontSize={18}
                      infoFontWeight="500"
                      containerStyle="py-2"
                    />
                    <InfoRow
                      title="Available:"
                      info={`${handleNumberDecimals(
                        leaveList?.balance / 8,
                        2
                      )} Days`}
                      infoFontSize={18}
                      infoFontWeight="500"
                      containerStyle="py-2"
                    />
                    <InfoRow
                      title="Used:"
                      info={`${handleNumberDecimals(
                        leaveList?.used / 8,
                        2
                      )} Days`}
                      infoFontSize={18}
                      infoFontWeight="500"
                      containerStyle="py-2"
                    />
                  </SeparatedRowElements>
                  <hr />
                  <Button
                    btnClassName={`${styles.btnStyle} mb-2 mt-0 border-0 w-100`}
                    onClick={() => navigate("/calender/leave%20balance")}
                    isOutline
                    color="dark"
                  >
                    View Leave Balance
                  </Button>
                </div>
                <div className="my-3">
                  <Paper backgroundColor="#D9D9D940" borderColor="#D9D9D9">
                    <InfoRow
                      title="Total Overtime:"
                      info={`${decimalNumber(
                        overtime?.balance
                        // overtime?.results[0]?.total_over_time
                      )} Hrs`}
                      infoFontSize={18}
                      infoFontWeight="500"
                      containerStyle="py-2"
                    />
                  </Paper>
                </div>
              </>
            )}

            <Form.Item
              name="leave_type"
              label="Leave Type"
              rules={[{ required: true }]}
            >
              <SelectionInput options={requestOptions} />
            </Form.Item>

            <Form.Item
              name="start_date"
              label="Date From:"
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            >
              <DatePickerInput />
            </Form.Item>

            <Form.Item
              name="end_date"
              label="Date to:"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!customEndDateError) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "End date must be after start date"
                      );
                    }
                  },
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <DatePickerInput />
            </Form.Item>

            <UploadFiles
              label="Attachments"
              name="attachments"
              required={false}
            />

            <Form.Item name="reason">
              <TextInput type="textarea" placeholder="Reason For leave" />
            </Form.Item>

            <Text>The amount will be deducted: {0} Hrs</Text>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
