import { Navigate, Outlet } from "react-router-dom";
// import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import UpperSideBarLayout from "components/wrappers/UpperSidebarLayout";
import StaffMemberInfo from "pages/Users/details/info";
import StaffMemberCalender from "pages/Users/details/calender";
import StaffLeaveBalance from "pages/Users/details/balance";
import StaffMemberOvertime from "pages/Users/details/overtime";

const children = [
  {
    path: "",
    element: <Navigate to="overview" />,
  },
  {
    path: "overview",
    element: <StaffMemberInfo />,
  },
  {
    path: "calender",
    element: <StaffMemberCalender />,
  },
  {
    path: "leave balance",
    element: <StaffLeaveBalance />,
  },
  {
    path: "overtime",
    element: <StaffMemberOvertime />,
  },
];

const StaffRoutes = {
  path: "staff/:id",
  element: (
    <UpperSideBarLayout list={children} title="">
      <Outlet />
    </UpperSideBarLayout>
  ),
  children,
};

export default StaffRoutes;
