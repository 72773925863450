/* eslint-disable @typescript-eslint/no-unused-vars */
import ModalWrapper from "components/modals/ModalWrapper";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import SelectionInput from "components/inputs/SelectionInput";
import {
  useGetAllStaffQuery,
  useLazyGetAllStaffQuery,
} from "apis/services/staff";
import showSuccessMsg from "utils/showSuccessMsg";
import { useAddDepartmentMutation } from "apis/services/department";
import MultiSelection from "components/inputs/MultiSelection";
import UserRoleEnum from "constants/UserRoleEnum";
import { AddNewDepartmentModalProps } from "./types";

type StaffOptionDataType = {
  label: string;
  value: number;
  key: string;
};

export default function AddNewDepartmentModal({
  isVisible,
  setIsVisible,
}: AddNewDepartmentModalProps) {
  const [form] = Form.useForm();

  const [addDepartment, { isLoading: isAddDepartmentLoading }] =
    useAddDepartmentMutation();

  const [getAllStaff] = useLazyGetAllStaffQuery();

  const { data: managers } = useGetAllStaffQuery(
    {
      page_size: 100,
      role: UserRoleEnum.manager,
    },
    { skip: !isVisible }
  );
  const managersOptions =
    managers?.results?.map((item) => ({
      label: `${item.user?.first_name} ${item.user?.last_name} (${item?.cfm_id})`,
      value: item.id as number,
    })) || [];

  const addNewDepartment = (values: any) => {
    addDepartment({
      ...values,
      assigned_staff: values?.members?.map(
        (item: any) => item?.value as number
      ),
    })
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added new department successfully!" });
      });
  };

  const submitForm = () => {
    form.submit();
  };

  const getMembersOptions = async (search: string) => {
    return getAllStaff({ search })
      .unwrap()
      .then(
        (res) =>
          res?.results?.map((item) => ({
            label: `${item?.user?.first_name} ${item?.user?.last_name} (${item?.cfm_id})`,
            value: item?.id as number,
            key: `${item?.id}`,
          }))
      );
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle="Add New Department"
      onConfirm={submitForm}
      isLoading={isAddDepartmentLoading}
    >
      <Form
        form={form}
        onFinish={addNewDepartment}
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item
          name="name"
          label="Department Name"
          rules={[{ required: true }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          name="lead"
          label="Department Lead"
          rules={[{ required: true }]}
        >
          <SelectionInput options={managersOptions} />
        </Form.Item>

        <Form.Item name="members" label="Department Members">
          <MultiSelection<StaffOptionDataType>
            fetchOptions={getMembersOptions}
          />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
