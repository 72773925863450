import { useParams } from "react-router-dom";
// import { useState } from "react";

// import { useGetStaffMemberInfoQuery } from "apis/services/staff";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";

import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";

import useGetUserInfo from "hooks/useGetUserInfo";
import CustomTable from "components/general/CustomTable";
import { useGetCalenderLeaveBalanceQuery } from "apis/services/other";
import styles from "./styles.module.scss";

export default function ProfileLeaveBalance() {
  const { id } = useParams();
  const { userInfo } = useGetUserInfo();
  const selectedID = id ?? userInfo?.cfm_id;
  // const { data } = useGetStaffMemberInfoQuery(selectedID!);
  const { data: leaveBalance, isFetching } = useGetCalenderLeaveBalanceQuery({
    search: selectedID,
  });

  // const [leaveModalshown, setleaveModalshown] = useState(false);

  // const INFODATA = [
  //   {
  //     title: "Present",
  //     info: 255,
  //   },
  //   {
  //     title: "Permissions",
  //     info: 43,
  //   },
  //   {
  //     title: "Absent",
  //     info: 7,
  //   },
  // ];

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={userInfo} />
      </Paper>

      <div className="mt-4">
        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingVertical={20}
          paddingHorizontal={32}
        >
          <SecondaryPageHeader
            title="Leave Balance"
            RightComponent={
              <div className="d-flex flex-row">
                {/* <div className="mx-2">
                  <FilterButton onClick={() => {}} filtersToHide={[]} />
                </div> */}
                {/* <Button
                  isOutline
                  color="primary"
                  suffix={<Image src={RED_DOWN_ARROW} />}
                >
                  Action
                </Button> */}
              </div>
            }
          />
          <CustomTable
            data={leaveBalance?.results}
            isLoading={isFetching}
            showSearchFilter={false}
            isFilterBtnVisible={false}
          />
        </Paper>
      </div>

      {/* <div className="my-4">
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </div>
      <Paper
        backgroundColor={COLORS.light}
        borderColor={COLORS.lightGrey}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <div>
          <SecondaryPageHeader
            title="Leave Balance"
            RightComponent={
              <div className="d-flex flex-row">
                <div className="mx-2">
                  <FilterButton onClick={() => {}} filtersToHide={[]} />
                </div>
                <Button
                  isOutline
                  color="primary"
                  suffix={<Image src={RED_DOWN_ARROW} />}
                >
                  Action
                </Button>
              </div>
            }
          />
          {["permission", "permission", "permission", "permission"].map(
            (item) => (
              <CalendarRow
                onClickHandler={() => setleaveModalshown(true)}
                dataItem={{
                  id: 15,
                  state: item,
                  date: "15/15/15",
                }}
              />
            )
          )}
        </div>
      </Paper>
      <Leave isVisible={leaveModalshown} setIsVisible={setleaveModalshown} /> */}
    </div>
  );
}
