import Users from "pages/settings/Users";
import styles from "./styles.module.scss";

export default function StaffDirectory() {
  return (
    <div className={styles.container}>
      <Users title="Staff" />
    </div>
  );
}
