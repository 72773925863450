import Menu from "components/Menu";
import Icon from "components/general/Icon";
import getClassNames from "utils/getClassName";
import { Spin } from "antd";
import styles from "./styles.module.scss";
import { MenuTableDataWrapperProps } from "./types";

export default function MenuTableDataWrapper({
  menuData = [],
  children,
}: MenuTableDataWrapperProps) {
  const MenuItems = menuData?.map((item) => ({
    key: item.key,
    onClick: item?.actionFn,
    label: (
      <span
        className={getClassNames([
          styles.menuBtn,
          item?.isDanger ? styles.dangerMenuBtn : "",
        ])}
      >
        {item.label}
        {!!item?.isLoading && <Spin />}
      </span>
    ),
  }));

  return (
    <div className={styles.container}>
      {children}

      {!!children && <div className={styles.vSepLine} />}

      {!!menuData?.length && (
        <Menu items={MenuItems}>
          <button
            className={getClassNames([
              styles.actionBtn,
              !children ? styles.noSpacing : "",
            ])}
          >
            <Icon name="Menu" size={24} color="info" />
          </button>
        </Menu>
      )}
    </div>
  );
}
