import { useState } from "react";
import UploadFiles from "components/modals/UploadShift";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Text from "components/general/Text";
import Button from "components/general/Button";
import Image from "components/general/Image";
import ImportImage from "assets/icons/import.svg";
import DownloadImage from "assets/icons/download.svg";
import { ShiftType, ShiftTypeName } from "constants/ShiftType";
import AssignShift from "components/modals/AssignShift";
import useGetUserInfo from "hooks/useGetUserInfo";
import { TabHeaderProps } from "./types";
import styles from "./styles.module.scss";

export default function TabHeader({
  containerStyle,
  shifType,
}: TabHeaderProps) {
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const [isAssignShown, setIsAssingShown] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { role } = useGetUserInfo();

  const handleDownloadTemplate = () => {};

  const AssignType = () => {
    let shiftType = "";
    switch (shifType) {
      case ShiftType.Fixed:
        shiftType = "fixed";
        break;
      case ShiftType.Flexible:
        shiftType = "flexible";
        break;
      case ShiftType.Based:
        shiftType = "based";
        break;
      default:
        break;
    }
    return shiftType;
  };

  return (
    <header className={`my-4 ${styles.container} ${containerStyle}`}>
      <div>
        <Text className={styles.title}>{t(ShiftTypeName[shifType])}</Text>
        <div>
          <Text className={styles.periodText}>Period: </Text>
          <Text className={styles.periodDate}>
            {searchParams.get("startDate") ??
              moment(new Date()).format("DD-MM-YYYY")}
          </Text>
        </div>
      </div>
      <UploadFiles
        page={`shifts${shifType}`}
        isVisible={isDetailsShown}
        setIsVisible={() => setIsDetailsShown(false)}
      />
      {role && role !== "employee" && (
        <div>
          <Button
            isOutline
            btnClassName={styles.headerBtn}
            onClick={() => setIsAssingShown(true)}
          >
            <Text style={{ paddingInline: 5 }}>+</Text>
          </Button>
          <Button
            onClick={() => setIsDetailsShown(true)}
            isOutline
            btnClassName={styles.headerBtn}
          >
            <Image src={DownloadImage} />
          </Button>
          <Button
            onClick={handleDownloadTemplate}
            isOutline
            btnClassName={styles.headerBtn}
          >
            <Image src={ImportImage} />
          </Button>
        </div>
      )}
      {isAssignShown && (
        <AssignShift
          isVisible={isAssignShown}
          setIsVisible={() => setIsAssingShown(false)}
          assignType={AssignType()}
          isMultipleAssign
        />
      )}
    </header>
  );
}
