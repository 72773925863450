// import { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CALENDER_ICON from "assets/icons/calender.svg";
import CLOCK_ICON from "assets/icons/clock.svg";
import styles from "./styles.module.scss";
import { DatePickerInputProps } from "./types";

export default function DatePickerInput({
  onChange,
  value,
  picker = "date",
  shouldShowTime = false,
  disabled,
}: DatePickerInputProps) {
  // const [isHovering, setisHovering] = useState(false);
  return (
    <DatePicker
      className={styles.datePicker}
      allowClear = {!!value}
      onChange={(_, dateString) => {
        onChange?.(dateString);
      }}
      defaultValue={value ? dayjs(value) : undefined}
      picker={picker}
      // // @ts-ignore
      // value={value}
      disabled={disabled}
      showTime={shouldShowTime}
      suffixIcon={
        !value ? <img
          className={styles["calender-icon"]}
          src={picker === "time" ? CLOCK_ICON : CALENDER_ICON}
          alt="calender"
        /> : null
      }
    />
  );
}
