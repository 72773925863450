import api from "apis";

export const bulkUploadApi = api.injectEndpoints({
  endpoints: (build) => ({
    bulkUpload: build.mutation({
      query: (body) => ({
        url: "/bulk-upload/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmployeeShiftS", "FixedTemplates"],
    }),
  }),
});

export const { useBulkUploadMutation } = bulkUploadApi;
