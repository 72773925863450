import moment from "moment";
import { useSearchParams } from "react-router-dom";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import InfoRow from "components/general/InfoRow";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import {
  useGetMyShiftsQuery,
  useGetMyEmployeeShiftsQuery,
} from "apis/services/shift";
import { AllStaffShiftsProps } from "apis/types/shift";
import { TableItemProps } from "components/general/Table/types";
import { ShiftType } from "constants/ShiftType";
// import formatTime from "utils/formatDateTime";
import IMPORTANT_VARS from "constants/ImportantVars";
import useGetUserInfo from "hooks/useGetUserInfo";
import TabHeader from "./TabHeader";
import StaffAction from "./StaffAction";
import styles from "./styles.module.scss";

export default function Flexible({
  statisticts,
  isLoading,
}: {
  statisticts?: any;
  isLoading?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const { role } = useGetUserInfo();

  const requestBasedOnRole =
    role === "employee" ? useGetMyShiftsQuery : useGetMyEmployeeShiftsQuery;

  const { data, isFetching: isEmployeeShiftsLoading } = requestBasedOnRole(
    {
      shift_type: ShiftType.Flexible,
      page: searchParams.get("page") ?? "1",
      search: searchParams.get("search") ?? "",
      start_date:
        searchParams.get("startDate") ??
        moment(new Date()).format("YYYY-MM-DD"),
      end_date: searchParams.get("endDate") ?? undefined,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    },
    { skip: !role }
  );

  const INFOS = [
    {
      title: "Present Days",
      info: statisticts?.present ?? 0,
    },
    {
      title: "Off",
      info: statisticts?.off ?? 0,
    },
    {
      title: "Leaves",
      info: statisticts?.leaves ?? 0,
    },
    {
      title: "Absent",
      info: statisticts?.absent ?? 0,
    },
  ];

  const changeStaffData = (item: AllStaffShiftsProps): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item?.employee} isTableCol />,
      },
      {
        type: "text",
        dataItem: `${item.working_hour?.number_of_hrs} Hours` ?? "-",
      },
      {
        type: "text",
        dataItem: moment(item.date).format("DD-MM-YYYY") ?? "-",
      },
      {
        type: "text",
        dataItem: `${item?.employee?.no_of_weekend} Day/s` ?? "-",
      },
      // {
      //   type: "text",
      //   dataItem: formatTime(item.start_time) ?? "-",
      // },
      {
        type: "element",
        dataItem: (
          <StaffAction
            shift={item}
            employee={item.employee}
            assignType="flexible"
          />
        ),
      },
    ],
  });
  return (
    <div className={styles.container}>
      <TabHeader shifType={ShiftType.Flexible} />

      <div className="my-4">
        <SeparatedRowElements>
          {INFOS.map((item) => (
            <InfoRow
              key={item.title}
              title={item.title}
              info={`${item.info}`}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />
          ))}
        </SeparatedRowElements>
      </div>

      <div className="my-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          headers={[
            "Members ID",
            "Working Hours",
            "Date",
            "No. of Weekend",
            "",
          ]}
          data={
            data?.results.map((item: AllStaffShiftsProps) =>
              changeStaffData(item)
            ) ?? []
          }
          isLoading={isEmployeeShiftsLoading || isLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
