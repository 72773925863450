import Paper from "components/general/PaperComponent";
import { Collapse, Spin } from "antd";
import moment from "moment";
import UserInfo from "components/cards/UserInfo";
import Text from "components/general/Text";
import COLORS from "constants/Colors";
import { useGetLeaveBalanceReportQuery } from "apis/services/other";
import IMPORTANT_VARS from "constants/ImportantVars";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

export default function LeaveBalanceDetails({
  isVisible,
  setIsVisible,
  user,
}: any) {
  const { data, isFetching } = useGetLeaveBalanceReportQuery({
    cfm_staff: user?.id,
    page_size: IMPORTANT_VARS.TabelDataPageSize,
  });
  console.log(user, "user");

  return (
    <ModalWrapper
      headerTitle="Leave Balance"
      size="large"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
    >
      {isFetching ? (
        <div className="d-flex justify-content-center w-100 my-5">
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.container}>
          <Paper
            backgroundColor={COLORS.light}
            borderColor={COLORS.light}
            paddingVertical={20}
            paddingHorizontal={32}
          >
            <UserInfo
              data={{
                ...user,
                user: {
                  first_name: user.username.split(" ")[0] || "",
                  last_name: user.username.split(" ")[1] || "",
                },
              }}
            />
          </Paper>

          {data?.results?.map((balanceItem: any) => (
            <Collapse
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              items={[
                {
                  key: "1",
                  label: (
                    <div className={styles.collapseHeader}>
                      <div className={styles.collapseHeaderTitle}>
                        <Text className={styles.balanceName}>
                          {balanceItem.name}
                        </Text>
                      </div>
                      <div className={styles.collapseHeaderTitle}>
                        <Text className={styles.balanceName}>Total:</Text>
                        <Text className={styles.balanceDays}>
                          {balanceItem.total_balance / 8 || 0} Days
                        </Text>
                      </div>
                      <div className={styles.collapseHeaderTitle}>
                        <Text className={styles.balanceName}>Available:</Text>
                        <Text className={styles.balanceDays}>
                          {balanceItem.available_balance / 8 || 0} Days
                        </Text>
                      </div>
                      <div className={styles.collapseHeaderTitle}>
                        <Text className={styles.balanceName}>Used:</Text>
                        <Text className={styles.balanceDays}>
                          {balanceItem.used_balance / 8 || 0} Days
                        </Text>
                      </div>
                    </div>
                  ),
                  children: (
                    <div className={styles.collapseContent}>
                      {balanceItem.requests.map((request: any) => (
                        <Text>
                          {request
                            ? moment(request).format("dddd, D MMMM, YYYY")
                            : ""}
                        </Text>
                      ))}
                    </div>
                  ),
                },
              ]}
            />
          ))}
        </div>
      )}
    </ModalWrapper>
  );
}
