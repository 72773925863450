// import Text from "components/general/Text";
import styles from "./styles.module.scss";
import { InfoRowProps } from "./types";

export default function InfoRow({
  containerStyle,
  isReverse = false,
  title,
  info,
  titleFontSize,
  infoFontSize,
  infoColor,
  titleColor,
  titleFontWeight,
  infoFontWeight,
}: InfoRowProps) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={isReverse ? styles.containerReversed : styles.container}>
        <span
          style={{
            fontSize: titleFontSize,
            fontWeight: titleFontWeight,
            color: titleColor,
          }}
          className={styles.titleStyle}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: infoFontSize,
            fontWeight: infoFontWeight,
            color: infoColor,
          }}
          className={styles.infoStyle}
        >
          {info}
        </span>
      </div>
    </div>
  );
}
