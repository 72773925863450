import Text from "components/general/Text";
import { ChangeEvent, useRef } from "react";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import Icon from "../Icon";

export default function UploadFile({ fileList, setFileList = () => {} }: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [fileList, setFileList] = useState<File[]>([]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setFileList([...fileList, ...files]);
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1);
    setFileList(updatedFileList);
  };

  return (
    <div className={styles.uploadContainer}>
      {/* Hidden file input */}
      <input
        id="fileUpload"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        onChange={handleFileUpload}
      />

      <label htmlFor="fileUpload" className={styles.uploadButton}>
        {/* <PaperClip /> */}
        <Text>
          Drag & Drop or
          <span className={styles.chooseFileTxt}>Choose file </span>
          to upload
        </Text>
      </label>

      <div className={styles.listContainer}>
        {/* 
// @ts-ignore */}
        {fileList.map((file, index) => (
          <div key={file.name} className={styles.fileListRow}>
            <div className="d-flex">
              <div className={styles.fileIcon}>
                <Icon name="document" size={24} color="info" />
              </div>

              <div className={styles.fileData}>
                <Text style={{ fontSize: "14px" }}>{file.name}</Text>
                <Text className={styles.greyColor} style={{ fontSize: "14px" }}>
                  {(file.size / (1024 * 1024)).toFixed(2)} MB
                </Text>
              </div>
            </div>

            <CloseOutlined
              className={styles.deleteIcon}
              onClick={() => handleDeleteFile(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
