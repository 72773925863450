import { Form } from "antd";
import dayjs from "dayjs";
import {
  useAssignBasedShiftMutation,
  useAssignFixedShiftMutation,
  useAssignFlexibleShiftMutation,
} from "apis/services/shift";
import { useGetShiftWorkinghoursQuery } from "apis/services/other";
import { useGetProjectsQuery } from "apis/services/locations";
import UserInfo from "components/cards/UserInfo";
import SelectionInput from "components/inputs/SelectionInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import { useState } from "react";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import showSuccessMsg from "utils/showSuccessMsg";
import { useGetFixedShiftTemplatesQuery } from "apis/services/fixedShiftTemplate";
import { AssignShiftProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

export default function AssignShift({
  containerStyle,
  isVisible,
  setIsVisible,
  user,
  assignType,
  isMultipleAssign,
  isUpdate,
}: AssignShiftProps) {
  const { data: fixedTemplates } = useGetFixedShiftTemplatesQuery({
    page_size: 100,
  });
  const { data: projects } = useGetProjectsQuery({
    page_size: 100,
  });

  const [form] = Form.useForm();
  const [statusType, setStatusType] = useState("1");
  const [assignFixedShift] = useAssignFixedShiftMutation();
  const [assignBasedShift] = useAssignBasedShiftMutation();
  const [assignFlexibleShift] = useAssignFlexibleShiftMutation();
  const { data: workinghoursOptions } = useGetShiftWorkinghoursQuery({
    page_size: 100,
  });
  const numberOfWeekendsOptions = [
    { label: "0 Day", value: 0 },
    { label: "1 Day", value: 1 },
    { label: "2 Days", value: 2 },
  ];

  const ShiftsCallback = {
    fixed: {
      assign: (data: any) => assignFixedShift(data),
    },
    based: {
      assign: (data: any) => assignBasedShift(data),
    },
    flexible: {
      assign: (data: any) => assignFlexibleShift(data),
    },
  };

  const onFinish = (values: {
    template_id: number;
    employee_ids: any[];
    start_date?: string;
    end_date?: string;
    start_time?: string;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PUT" : "POST",
      employee_ids: values?.employee_ids?.map((item) => item.value) ?? [
        user?.id,
      ],
    };
    if (values?.start_date) {
      data.start_date = dayjs(data.start_date).format("DD-MM-YYYY");
      data.end_date = dayjs(data.end_date).format("DD-MM-YYYY");
    }
    if (values?.start_time) {
      data.start_time += ".00";
    }
    const type = assignType as keyof typeof ShiftsCallback;
    ShiftsCallback[type]
      .assign(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Shift assigned to staff successfully!" });
        setIsVisible(false);
      });
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Assign Shift"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {!isMultipleAssign ? (
              <div className={styles.memberContent}>
                <UserInfo data={user} />
              </div>
            ) : (
              <Form.Item
                name="employee_ids"
                label="Assignees Members"
                rules={[{ required: true }]}
              >
                <AssigneeSelection />
              </Form.Item>
            )}
            {assignType === "fixed" && (
              <Form.Item
                name="template_id"
                label="Fixed Shift Name"
                rules={[{ required: true }]}
              >
                <SelectionInput
                  options={
                    fixedTemplates?.results?.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }) ?? []
                  }
                />
              </Form.Item>
            )}

            {assignType === "fixed" && (
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePickerInput />
              </Form.Item>
            )}

            {assignType !== "fixed" && (
              <>
                {assignType === "flexible" && (
                  <Form.Item
                    name="project_id"
                    label="Project Name"
                    rules={[{ required: true }]}
                  >
                    <SelectionInput
                      options={
                        projects?.results?.map((item: any) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) ?? []
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="start_date"
                  label="Start Date"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <DatePickerInput />
                </Form.Item>

                <Form.Item
                  name="end_date"
                  label="End Date"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <DatePickerInput />
                </Form.Item>

                <Form.Item
                  name="specification"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <SelectionInput
                    onChange={(val: string) => {
                      setStatusType(val);
                    }}
                    options={[
                      {
                        label: "Work",
                        value: 1,
                      },
                      {
                        label: "Holiday",
                        value: 2,
                      },
                    ]}
                  />
                </Form.Item>

                {assignType === "based" && `${statusType}` === "1" && (
                  <Form.Item
                    name="start_time"
                    label="Start Time"
                    rules={[{ required: true }]}
                  >
                    <DatePickerInput picker="time" />
                  </Form.Item>
                )}

                {`${statusType}` === "1" && (
                  <Form.Item
                    name="working_hour_id"
                    label="Working Hours"
                    rules={[{ required: true }]}
                  >
                    <SelectionInput options={workinghoursOptions ?? []} />
                  </Form.Item>
                )}

                {assignType === "flexible" && (
                  <Form.Item
                    name="no_of_weekends"
                    label="Numbers Of Weekend"
                    rules={[{ required: true }]}
                  >
                    <SelectionInput options={numberOfWeekendsOptions} />
                  </Form.Item>
                )}
              </>
            )}
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
