import { Navigate, Outlet } from "react-router-dom";
import SecondarySidebarLayout from "components/wrappers/SecondarySidebarLayout";
import Attendance from "pages/Calender/Attendance";
import Overtime from "pages/Calender/Overtime";
import LeaveBalance from "pages/Calender/LeaveBalance";

const children = [
  {
    path: "",
    element: <Navigate to="attendance" />,
  },
  {
    path: "attendance",
    element: <Attendance />,
  },
  {
    path: "leave balance",
    element: <LeaveBalance />,
  },
  {
    path: "overtime",
    element: <Overtime />,
  },
];

const CalenderRoutes = {
  path: "calender",
  element: (
    <SecondarySidebarLayout list={children} title="">
      <Outlet />
    </SecondarySidebarLayout>
  ),
  children,
};

export default CalenderRoutes;
