/* eslint-disable */

import dayjs from "dayjs";
import { Form, Progress, Switch } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  useApproveRequestMutation,
  useCreateRequestMutation,
  useUpdateTransferRequestMutation,
} from "apis/services/requests";
import { useGetAllStaffQuery } from "apis/services/staff";
import TextInput from "components/inputs/TextInput";
import UserInfo from "components/cards/UserInfo";
import SelectionInput from "components/inputs/SelectionInput";
import Text from "components/general/Text";
import {
  useGetCategoriesQuery,
  useGetLocationsQuery,
  useGetProjectsQuery,
  useGetZonesQuery,
} from "apis/services/locations";
import DatePickerInput from "components/inputs/DatePickerInput";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import showSuccessMsg from "utils/showSuccessMsg";
import { useEffect, useMemo, useState } from "react";
import Button from "components/general/Button";
import { TransferAllocation } from "apis/types/requests";
import Paper from "components/general/PaperComponent";
import InfoRow from "components/general/InfoRow";
import { RequestsTypes } from "constants/Requests";
import ModalWrapper from "../../ModalWrapper";
// import { CreateRequestProps } from "../types";
import styles from "../styles.module.scss";
import Item from "antd/es/list/Item";
import COLORS from "constants/Colors";

export default function Transfer({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate = false,
  request,
  setisConflictResolved = () => {},
}: any) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const request_created_for = request?.created_for || userData;
  const [transferAllocations, setTransferAllocations] = useState<any[]>(
    request?.transfer_allocations || []
  );

  const [assigneeData, setassigneeData] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    setTransferAllocations([]);

    if (!request?.transfer_allocations?.length) return;

    setTransferAllocations(request?.transfer_allocations);
  }, [isVisible]);

  const [createRequest] = useCreateRequestMutation();

  const [updateTransferRequest] = useUpdateTransferRequestMutation();

  const [acceptRequest] = useApproveRequestMutation();

  const precentageValue = transferAllocations?.reduce(
    (summation, item) => summation + Number(item.percentage),
    request?.percentage || 0
  );

  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const zoneValue = Form.useWatch("zone", form);

  const { data: locations } = useGetLocationsQuery(
    { page_size: 100, zone: zoneValue },
    {
      skip: !zoneValue,
    }
  );
  const locationValue = Form.useWatch("location", form);
  const categoryValue = Form.useWatch("category_id", form);

  const { data: projects } = useGetProjectsQuery(
    {
      location: locationValue,
      // category: categoryValue,
      page_size: 100,
    },
    {
      skip: !locationValue,
    }
  );
  const { data: managers } = useGetAllStaffQuery({
    role: "manager",
    employment_type: 1,
    page_size: 100,
  });

  const { data: categories } = useGetCategoriesQuery({
    page_size: 100,
  });

  const adminsOptions = managers?.results?.map((item: any) => ({
    label: `${item?.user?.username} (${item?.cfm_id})`,
    value: item?.id,
  }));

  const zoneOptions = zones?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const locationsOptions = locations?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const projectsOptions = projects?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
    projectId: item.project_id,
    categoryId: item?.category?.name,
  }));
  const projectIDsOptions = projects?.results?.map((item: any) => ({
    label: item.project_id,
    value: item.id,
    projectId: item.project_id,
  }));
  const categoriesOptions = categories?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  const getReportToName = (value: string) =>
    adminsOptions?.find((item) => +value === +item.value)?.label;
  const getZoneName = (value: string) =>
    zoneOptions?.find((item: any) => +value === +item.value)?.label;
  const getProjectIdName = (value: string) =>
    projectIDsOptions?.find((item: any) => +value === +item.value)?.label;
  const getCategoryNameFromProject = (projectId: string) =>
    categoriesOptions?.find((item: any) => +projectId === item.value)?.label;
  const getLocationNames = (locationsIDs: any) => {
    const IDs = locationsOptions?.filter(
      (item: any) => locationsIDs?.includes(item.value) && item.label
    );
    return IDs;
  };
  const getLocationNamesByDefaultValues = (locations: any) => {
    let changeElements = locations?.map((element: any) => element.id);
    const IDs = locationsOptions?.filter(
      (item: any) => changeElements?.includes(item.value) && item.label
    );
    return IDs;
  };

  const editRequest = (values: any) => {
    const requestValues = {
      ...values,
      id: request?.id,
    };
    if (transferAllocations?.length) {
      // @ts-ignore
      requestValues.transfer_allocations = transferAllocations;
    } else if (
      values?.percentage &&
      values?.assigned_projects &&
      !transferAllocations?.length
    ) {
      // @ts-ignore
      requestValues.transfer_allocations = [
        {
          // @ts-ignore
          project: values?.assigned_projects,
          percentage: +values?.percentage,
        },
      ];
    }
    //! The next line is just a workaround for making the request, assigned_projects needs to be a collecting list.
    // @ts-ignore
    if (requestValues.assigned_projects) {
      // @ts-ignore
      requestValues.assigned_projects =
        typeof requestValues.assigned_projects == "object"
          ? requestValues.assigned_projects
          : [requestValues.assigned_projects];
    } else {
      // @ts-ignore
      requestValues.assigned_projects = [];
    }

    return updateTransferRequest(requestValues)
      .unwrap()
      .then(() =>
        acceptRequest({
          ...request,
          project: request.project || transferAllocations?.[0]?.["project"],
        })
      )
      .then(() => {
        showSuccessMsg({ msg: "Edited Request and accepted successfully!" });
        setTimeout(() => {
          setIsVisible(false);
        }, 200);
      });
  };

  const onFinish = (values: any) => {
    if (isUpdate) {
      editRequest(values);
      return;
    }

    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      request_type: RequestsTypes.transfer_requests,
      URL_request_type: "transfer_requests",
      created_for: values?.employee_ids?.value ?? userData?.id,
      transfer_from: userData?.default_project?.id,
      percentage: precentageValue || values.percentage,
      // project: userData?.default_project?.id,
      attachments: [],
      delegated_to: [],
    };

    if (values?.date) {
      data.date = dayjs(data.date).format("YYYY-MM-DD");
    }
    console.log(transferAllocations, "transferAllocations");

    if (transferAllocations?.length) {
      // @ts-ignore
      data.transfer_allocations = transferAllocations;
    } else if (
      values?.percentage &&
      values?.assigned_projects &&
      !transferAllocations?.length
    ) {
      // @ts-ignore
      data.transfer_allocations = [
        {
          // @ts-ignore
          project: values?.assigned_projects,
          percentage: +values?.percentage,
        },
      ];
    }
    //! The next line is just a workaround for making the request, assigned_projects needs to be a collecting list.
    // @ts-ignore
    if (data.assigned_projects) {
      // @ts-ignore
      data.assigned_projects = [data.assigned_projects];
    } else {
      // @ts-ignore
      data.assigned_projects = [];
    }
    createRequest(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request created successfully!" });
        try {
          setisConflictResolved(true);
        } catch {
          // ignore setter if from request and not from conflicts
        }
        setIsVisible(false);
      });
  };

  const getFormInitialValues = useMemo(() => {
    return {
      start_date: request?.start_date,
      created_by: request?.created_by?.id,
      reason: request?.reason,
      functional_changes: request?.functional_changes,
      location: request?.location.map((item: any) => item?.id),
      zone: request?.zone,
      assigned_projects: request?.assigned_projects,
      project: request?.project?.id,
      category_id: request?.project?.category?.id,
      new_reports_to: request?.new_reports_to,
    };
  }, [request]);

  const addTransferAllocation = () => {
    const formValue = form.getFieldsValue();
    console.log(formValue, "formValue");

    setTransferAllocations((prevState) => [
      ...prevState,
      {
        project:
          formValue?.assigned_projects[0] || formValue?.assigned_projects,
        percentage: +formValue?.percentage,
        start_date: formValue?.start_date,
        zone: formValue?.zone,
        category: formValue?.category_id || formValue?.category,
        created_by: formValue?.created_by,
        new_reports_to: formValue?.new_reports_to,
        location: formValue?.location || formValue?.location?.[0],
      },
    ]);

    // form.resetFields([
    //   "project",
    //   "category_id",
    //   "percentage",
    //   "assigned_projects",
    // ]);
  };

  const FooterMarkup = (
    <div className="d-flex align-items-center gap-3">
      {/* {!!isUpdate && ( */}
      <Button
        onClick={addTransferAllocation}
        isOutline
        disabled={precentageValue >= 100}
      >
        Add Transfer Allocation
      </Button>
      {/* )} */}
      <Button
        onClick={() => form.submit()}
        disabled={isUpdate && precentageValue < 100}
      >
        Confirm
      </Button>
    </div>
  );
  const handleZoneField = (changedValues: any) => {
    if ("zone" in changedValues) {
      form.resetFields(["location"]);
    }
  };
  const handleAssigneeChange = (value: any) => {
    // Custom logic when AssigneeSelection changes
    form.setFieldValue("employee_ids", value);
    if (!isUpdate && !request) {
      setassigneeData(value.data); // Update the form field
    }
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Request Transfer"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
      CustomFooterComponent={FooterMarkup}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={
              isUpdate && precentageValue === 100
                ? () => onFinish(form.getFieldsValue())
                : undefined
            }
            initialValues={getFormInitialValues}
            onValuesChange={handleZoneField}
          >
            <div
              className={`${styles.memberContent} ${
                isMultipleAssign && !transferAllocations?.length && "d-none"
              }`}
            >
              <UserInfo
                // @ts-ignore
                data={assigneeData?.id ? assigneeData : request_created_for}
              />
            </div>

            {(!!transferAllocations?.length || !!precentageValue) && (
              <div>
                <Text>Total Percentage:</Text>
                <Progress percent={precentageValue} strokeColor="#E3332D" />
              </div>
            )}
            {!!transferAllocations?.length && (
              <div className="mb-4 d-flex flex-column gap-2">
                {transferAllocations?.map((item) => (
                  <Paper
                    paddingHorizontal={24}
                    paddingVertical={18}
                    backgroundColor="#F0F0F040"
                    borderColor="#D9D9D980"
                  >
                    <div className="mb-2">
                      <InfoRow
                        title="Project ID:"
                        infoFontWeight="700"
                        titleFontWeight="700"
                        titleFontSize={24}
                        infoFontSize={24}
                        // titleColor={"grey"}
                        // @ts-ignore
                        info={getProjectIdName(item?.project) || "--"}
                        containerStyle="justify-content-start"
                      />
                    </div>

                    <InfoRow
                      title="Start Date:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      // @ts-ignore
                      info={item?.start_date || request.start_date || "--"}
                      containerStyle="justify-content-start"
                    />
                    <InfoRow
                      title="Zone:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      // @ts-ignore
                      info={getZoneName(item?.zone || request.zone) || "--"}
                      containerStyle="justify-content-start"
                    />
                    <InfoRow
                      title="Locations:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      info={
                        item?.location?.length
                          ? getLocationNames(item.location)
                              ?.map((item: any) => item.label || item.name)
                              .join() || "--"
                          : getLocationNamesByDefaultValues(request.location)
                              ?.map((item: any) => item.label || item.name)
                              .join() || "--"
                      }
                      containerStyle="justify-content-start"
                    />
                    <InfoRow
                      title="Report To:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      info={
                        getReportToName(item.new_reports_to) ||
                        request.new_reports_to ||
                        "--"
                      }
                      containerStyle="justify-content-start"
                    />
                    <InfoRow
                      title="Category ID:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      info={
                        getCategoryNameFromProject(
                          item.category_id || item?.category
                        ) ||
                        request?.category_name ||
                        "--"
                      }
                      containerStyle="justify-content-start"
                    />
                    <InfoRow
                      title="Percentage:"
                      infoFontWeight="600"
                      titleFontWeight="600"
                      titleColor={"grey"}
                      info={`${item?.percentage}%` || "--"}
                      containerStyle="justify-content-start"
                    />
                  </Paper>
                ))}
              </div>
            )}

            <div
              className={`${
                (!!transferAllocations?.length || !isMultipleAssign) && "d-none"
              }`}
            >
              <Form.Item
                name="employee_ids"
                label="Assignees Member"
                rules={[{ required: true }]}
              >
                <AssigneeSelection
                  isMulti={false}
                  onChange={handleAssigneeChange}
                />
              </Form.Item>
            </div>
            {/* )} */}

            {/* <div className={`${precentageValue >= 100 && "d-none"}`}></div> */}

            <div
              className={`${
                (transferAllocations?.length || precentageValue >= 100) &&
                "d-none"
              }`}
            >
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePickerInput disabled={!!transferAllocations.length} />
              </Form.Item>
              <Form.Item name="zone" label="Zone" rules={[{ required: true }]}>
                <SelectionInput options={zoneOptions} />
              </Form.Item>

              <Form.Item
                name="location"
                label="Location"
                rules={[{ required: isUpdate }]}
              >
                <SelectionInput options={locationsOptions} mode="multiple" />
              </Form.Item>
              <Form.Item
                name="project"
                label="Default Project"
                rules={[{ required: isUpdate }]}
              >
                <SelectionInput options={projectsOptions} />
              </Form.Item>

              <Form.Item
                name="new_reports_to"
                label="Reporting to"
                // initialValue={userData?.reports_to?.id}
                rules={[{ required: isUpdate }]}
              >
                <SelectionInput options={adminsOptions ?? []} />
              </Form.Item>
            </div>

            <div
              className={
                transferAllocations?.length && precentageValue >= 100
                  ? "d-none"
                  : ""
              }
            >
              {/* {!!transferAllocations?.length && ( */}
              {/* <> */}
              <Form.Item
                name="assigned_projects"
                label="Project ID"
                rules={[{ required: isUpdate }]}
              >
                <SelectionInput options={projectIDsOptions} />
              </Form.Item>
              <Form.Item
                name="category"
                label="Category ID"
                rules={[{ required: isUpdate }]}
              >
                <SelectionInput options={categoriesOptions} />
              </Form.Item>

              <Form.Item
                name="percentage"
                label="Percentage"
                rules={[{ required: isUpdate }]}
              >
                <TextInput
                  inputSuffix="%"
                  // disabled={!form.getFieldValue("assigned_projects")}
                />
              </Form.Item>
              {/* </>
              )} */}

              <Form.Item name="reason">
                <TextInput type="textarea" placeholder="Reason For transfer" />
              </Form.Item>

              <Form.Item
                label="Are there any functional /financial changes?"
                valuePropName="checked"
                name="functional_changes"
                noStyle
              >
                <div
                  className="border d-flex flex-row p-3 justify-content-between w-100"
                  style={{
                    borderRadius: 12,
                  }}
                >
                  <Text>Are there any functional /financial changes?</Text>
                  <Switch />
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
