/* eslint-disable  */
import { useEffect, useState } from "react";
import { ConfigProvider, Form, Pagination, Tabs, TabsProps } from "antd";
import TextInput from "components/inputs/TextInput";
import Paper from "components/general/PaperComponent";
import SelectionInput from "components/inputs/SelectionInput";
import RadioGroupInput from "components/inputs/RadioGroupInput";
// import DatePickerInput from "components/inputs/DatePickerInput";
// import showSuccessMsg from "utils/showSuccessMsg";
// import {
//   useCreateFixedTemplateMutation,
//   useEditFixedShiftTemplateMutation,
// } from "apis/services/fixedShiftTemplate";
import {
  useGetLeaveBalancePolicyQuery,
  usePostLeaveBalancePolicyMutation,
  usePostLeavePolicyMutation,
  useUpdateLeaveBalancePolicyMutation,
  useUpdateLeavePolicyMutation,
} from "apis/services/other";
import CalenderAction from "components/pages/setup/CalenderAction";
import Table from "components/general/Table";
import Button from "components/general/Button";
import ADD from "assets/icons/add.svg";
import Image from "components/general/Image";
import Text from "components/general/Text";
import { AddNewPolicyProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";
import showSuccessMsg from "utils/showSuccessMsg";
import AssignBalance from "../AssignBalance";
import { handleNumberDecimals } from "hooks/useHandleNumberDecimals";

export default function AddNewPolicy({
  containerStyle,
  isVisible,
  setIsVisible,
  isUpdate,
  initValues = {},
}: AddNewPolicyProps) {
  const [selectedTab, setSelectedTab] = useState("Policy");
  const [tablePage, settablePage] = useState(1);
  const [tableData, settableData] = useState([]);
  const [policyID, setpolicyID] = useState(initValues?.id || null);
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const [detailModalType, setdetailModalType] = useState("add");

  const [form] = Form.useForm();

  // leave policy
  const [postLeavePolicy] = usePostLeavePolicyMutation();
  const [updateLeavePolicy] = useUpdateLeavePolicyMutation();
  // balance policy
  const { data, isLoading } = useGetLeaveBalancePolicyQuery(
    {
      policy: policyID,
      params: {
        page: tablePage,
        page_size: 5,
      },
    },
    {
      skip: selectedTab == "Policy",
    }
  );
  console.log(data?.results, "data.results");

  const [postLeaveBalancePolicy] = usePostLeaveBalancePolicyMutation();
  const [updateLeaveBalancePolicy] = useUpdateLeaveBalancePolicyMutation();

  useEffect(() => {
    if (selectedTab == "Balance") form.setFieldValue("name", 1);

    const leaveBalanceTable = data?.results?.map((element: any) => {
      return {
        ...element,
        leave_policy: policyID,
      };
    });
    settableData(leaveBalanceTable);
  }, [data]);
  const calculationOptions = [
    { label: "Days", value: 1 },
    // { label: "Hours", value: 0 },
  ];
  const perCycle = [
    { label: "None", value: "" },
    { label: "Annually", value: "annually" },
    { label: "Monthly", value: "monthly" },
  ];
  const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const showIn = [
    { label: "days", value: "days" },
    { label: "hours ", value: "hours" },
  ];

  const onFinish = (values: any) => {
    const data = { ...values };
    const isPolicy = selectedTab === "Policy";
    if (isPolicy) {
      // if (selectedTab === "Balance" && !data.leave_policy) {
      //   data.leave_policy = policyID;
      // }

      const PolicyTypeController = isPolicy
        ? isUpdate
          ? updateLeavePolicy
          : postLeavePolicy
        : isUpdate
        ? postLeaveBalancePolicy
        : postLeaveBalancePolicy;
      // const tempDataToSubmit = isPolicy ? { ...data } : tableData;
      const DataToSubmit = isPolicy
        ? isUpdate
          ? { ...data, id: initValues?.id }
          : { ...data }
        : tableData;
      console.log(DataToSubmit, "DataToSubmit");

      PolicyTypeController(DataToSubmit)
        .unwrap()
        // @ts-ignore
        .then((res) => {
          if (isPolicy) {
            setSelectedTab("Balance");
            setpolicyID(res.id);
          } else {
            setIsVisible(false);
            showSuccessMsg({ msg: "Template created successfully!" });
          }
        });
    } else {
      setIsVisible(false);
    }
  };

  const tableDataValidator =
    tableData?.map((item: any, index: any) => ({
      id: index,
      rowData: [
        {
          dataItem: item.age_from,
          type: "text",
        },
        {
          dataItem: item.age_to,
          type: "text",
        },
        {
          dataItem: item.years_from,
          type: "text",
        },
        {
          dataItem: item.years_to,
          type: "text",
        },
        {
          dataItem: handleNumberDecimals(item.balance / 8, 2),
          type: "text",
        },
        {
          type: "element",
          dataItem: (
            <CalenderAction
              ItemData={{ ...item, index: index }}
              tableData={tableData}
              settableData={settableData}
              isDetailsShown={isDetailsShown}
              setIsDetailsShown={setIsDetailsShown}
              detailModalType={detailModalType}
              setdetailModalType={setdetailModalType}
              policyID={policyID}
              isUpdate={isUpdate}
            />
          ),
        },
      ],
    })) || [];

  const handleAddCondition = () => {
    setIsDetailsShown(true);
    setdetailModalType("add");
    // const tempArr = !!tableData?.length ? [...tableData] : [];
    // const tempObj = {
    //   age_from: 18,
    //   age_to: 60,
    //   years_from: 1940,
    //   years_to: 2005,
    //   balance: 21,
    //   days_balance: 21,
    //   leave_policy: policyID || initValues.leave_policy,
    // };
    // // @ts-ignore
    // tempArr.push(tempObj);
    // settableData(tempArr);
  };
  // console.log(isDetailsShown, "isDetailsShown");

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const getInitValues = () => {
    if (!initValues) return undefined;

    const editedValues = {
      ...initValues,
    };

    return editedValues;
  };

  const items: TabsProps["items"] = [
    {
      key: "Policy",
      label: "Policy",
      disabled: true,
      children: (
        <div className="pt-5">
          <Form.Item
            name="name"
            label="Policy Name"
            rules={[{ required: true }]}
          >
            <TextInput />
          </Form.Item>

          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <TextInput />
          </Form.Item>

          <Form.Item name="per_cycle" label="Per Cycle">
            <SelectionInput options={perCycle} />
          </Form.Item>
          <Form.Item
            name="carry_forward"
            label="Carry Forward at the end of cycle"
          >
            <TextInput isNumberInput />
          </Form.Item>
          <Form.Item
            name="carry_forward_max_months"
            label="Carry Forward expire duration"
          >
            <TextInput isNumberInput />
          </Form.Item>
          <Form.Item
            name="max_time_during_service_life"
            label="Max time during service life"
          >
            <TextInput isNumberInput />
          </Form.Item>

          <Form.Item
            name="is_show_for_worker"
            label="Show for worker: "
            rules={[{ required: true }]}
          >
            {/* @ts-ignore */}
            <RadioGroupInput options={yesNoOptions} />
          </Form.Item>

          <Form.Item
            name="show_balance_in"
            label="Show In: "
            rules={[{ required: true }]}
          >
            {/* @ts-ignore */}
            <RadioGroupInput options={showIn} />
          </Form.Item>
          <Form.Item
            name="is_over_time"
            label="OverTime: "
            rules={[{ required: true }]}
          >
            {/* @ts-ignore */}
            <RadioGroupInput options={yesNoOptions} />
          </Form.Item>
        </div>
      ),
    },
    {
      key: "Balance",
      label: "Balance",
      disabled: true,
      children: (
        <div className="pt-3">
          <hr />
          <Form.Item
            name="name"
            label="Policy calculation method:"
            rules={[{ required: true }]}
          >
            <RadioGroupInput options={calculationOptions} />
          </Form.Item>
          <Table
            isCheckBoxVisible={false}
            showSearchFilter={false}
            hideCount
            headers={[
              "Age From",
              "Age To",
              "Years From",
              "Years To",
              "Balance",
            ]}
            // @ts-ignore
            data={tableDataValidator}
            count={data?.count || tableData?.length}
            pageSize={5}
            isLoading={isLoading}
          />
          <div className="d-flex justify-content-center mt-3 mb-5">
            <Pagination
              total={data?.count}
              pageSize={5}
              defaultCurrent={1}
              current={tablePage}
              onChange={(value) => settablePage(value)}
            />
          </div>
          <div className="my-2">
            <Button
              isOutline
              btnClassName="w-100 d-flex justify-content-center"
              onClick={handleAddCondition}
            >
              <div className="d-flex">
                <Image src={ADD} className="me-1" />
                <Text>Add Condition</Text>
              </div>
            </Button>
          </div>
          {isDetailsShown && !tableData.length && (
            <AssignBalance
              isVisible={isDetailsShown}
              setIsVisible={setIsDetailsShown}
              ItemData={{}}
              tableData={tableData}
              settableData={settableData}
              detailModalType={detailModalType}
              policyID={policyID}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <ModalWrapper
      size="large"
      headerTitle="Add New Policy"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={isUpdate ? getInitValues() : undefined}
          >
            <Paper
              backgroundColor="white"
              borderColor="white"
              paddingVertical={32}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      fontSize: 16,
                      colorBgContainer: "#E3332D0D",
                      colorBorderSecondary: "#fffff",
                      colorFillAlter: "#fffff",
                      padding: 24,
                      margin: 0,
                    },
                  },
                }}
              >
                <Tabs
                  type="card"
                  items={items}
                  onChange={onChange}
                  prefixCls="tabContainer"
                  // defaultActiveKey={selectedTab}
                  activeKey={selectedTab}
                />
              </ConfigProvider>
            </Paper>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
