/* eslint-disable */

import ModalWrapper from "components/modals/ModalWrapper";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import SelectionInput from "components/inputs/SelectionInput";
import showSuccessMsg from "utils/showSuccessMsg";
import { useAddZoneMutation, useEditZoneMutation } from "apis/services/zone";
import { useGetAllStaffQuery } from "apis/services/staff";
import { useMemo } from "react";

export default function AddNewZoneModal({
  isVisible,
  setIsVisible,
  isUpdate,
  initialValues,
}: any) {
  const [form] = Form.useForm();
  const { data: members, isLoading } = useGetAllStaffQuery({
    page_size: 100,
    role: "manager",
  });
  const [addZone, { isLoading: isAddZoneLoading }] = useAddZoneMutation();
  const [editZone] = useEditZoneMutation();

  const addNewZone = (values: any) => {
    const request = isUpdate ? editZone : addZone;
    if (isUpdate) {
      console.log(":");
      values.id = initialValues.id;
      values.head = values.head || initialValues.head;
    }
    request(values)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added new zone successfully!" });
      });
  };
  const handleInitialValues = useMemo(() => {
    const tempValues = { ...initialValues };
    tempValues.head = `${initialValues?.head_username} (${initialValues?.head_cfm_id})`;
    return tempValues;
  }, []);

  const membersOptions =
    members?.results?.map((item): any => ({
      label: `${item?.user?.username} (${item?.cfm_id})`,
      value: item?.id,
    })) || [];
  console.log(members, "membersss");

  const submitForm = () => {
    form.submit();
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle="Add New Zone"
      onConfirm={submitForm}
      isLoading={isAddZoneLoading}
    >
      <Form
        form={form}
        onFinish={addNewZone}
        initialValues={handleInitialValues}
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item name="name" label="Zone Name" rules={[{ required: true }]}>
          <TextInput />
        </Form.Item>
        <Form.Item name="head" label="Zone Head" rules={[{ required: true }]}>
          <SelectionInput options={membersOptions} isLoading={isLoading} />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
