import api from "apis";
import { AuthTokenResponse, LoginBody, User } from "../types/auth";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStaffInfo: build.query<User, void>({
      query: () => ({
        url: "/auth/staff/me/",
      }),
      providesTags: ["User"],
    }),

    login: build.mutation<AuthTokenResponse, LoginBody>({
      query: (body) => ({
        url: "/auth/users/login/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["User"],
    }),

    logout: build.mutation({
      query: () => ({
        url: "/auth/users/logout/",
        method: "POST",
      }),
    }),
  }),
});

export const { useGetStaffInfoQuery, useLoginMutation, useLogoutMutation } =
  authApi;
