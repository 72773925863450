import api from "apis";
import { PaginatedResponse, PaginationParams } from "apis/types/general";
import { FixedTemplate } from "apis/types/shift";

export const FixedShiftTemplateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFixedShiftTemplates: build.query<
      PaginatedResponse<FixedTemplate>,
      PaginationParams
    >({
      query: (params) => ({
        url: `/shifts/fixed_shift_templates/`,
        params,
      }),
      providesTags: ["FixedTemplates"],
    }),

    createFixedTemplate: build.mutation({
      query: (body) => ({
        url: "/shifts/fixed_shift_templates/create_fixed_shift_template/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["FixedTemplates"],
    }),

    editFixedShiftTemplate: build.mutation({
      query: (body) => ({
        url: `/shifts/fixed_shift_templates/${body?.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["FixedTemplates"],
    }),

    deleteFixedShiftTemplate: build.mutation({
      query: (id) => ({
        url: `/shifts/fixed_shift_templates/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["FixedTemplates"],
    }),
  }),
});

export const {
  useCreateFixedTemplateMutation,
  useGetFixedShiftTemplatesQuery,
  useEditFixedShiftTemplateMutation,
  useDeleteFixedShiftTemplateMutation,
} = FixedShiftTemplateApi;
