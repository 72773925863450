/* eslint-disable */
import React, { useState } from "react";
import { Pagination, Space, Table, Tag } from "antd";
import { useSearchParams } from "react-router-dom";
// import useToggleState from "hooks/useToggleState";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import RED_DOWN_ARROW from "assets/icons/down-red-arrow.svg";
// import getClassNames from "utils/getClassName";
// import CheckBox from "../CheckBox";
// import Text from "../Text";
import styles from "./styles.module.scss";
import FilterButton from "../FilterButton";
import Button from "../Button";
import Image from "../Image";
import UserInfo from "components/cards/UserInfo";
import StatusView from "components/pages/calender/status-view";
import Icon from "../Icon";
import CheckBox from "../CheckBox";
import Text from "../Text";
import MenuTableDataWrapper from "../MenuTableDataWrapper";
import Menu from "components/Menu";
import LeaveBalanceDetails from "components/modals/LeaveBalanceDetails";

const { Column, ColumnGroup } = Table;

interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  age: number;
  address: string;
  tags: string[];
}
export default function CustomTable({
  containerStyle,
  //   headers,
  data,
  //   isCheckBoxVisible = true,
  isFilterBtnVisible = true,
  isActionBtnVisible = false,

  //   isDataCentered = false,
  //   isHeaderCentered = false,
  filtersToHide = [],
  showSearchFilter = true,
  isLoading,
  count,
  //   hideCount = false,
  pageSize = 5,
  searchPlaceholder, //   isLoading = false,
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isViewBalanceVisible, setisViewBalanceVisible] = useState(false);
  const [selectedBalance, setselectedBalance] = useState({});
  //   const { addToSelected, isSelected, replaceState } = useToggleState<string>(
  //     []
  //   );

  const onSearch = (value: string) => {
    searchParams.set("search", value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const rowSelection = {
    // @ts-ignore
    onChange: (selectedRowKeys, selectedRows) => {},
    // @ts-ignore
    columnTitle: () => (
      <div className="px-3">
        <CheckBox />
      </div>
    ),
    renderCell: () => (
      <div className="px-3">
        <CheckBox
        // defaultChecked={isSelected("all")}
        // onValueChange={(e) =>
        //   e
        //     ? replaceState(["all", ...data.map((el) => el.id)])
        //     : replaceState([])
        // }
        />
      </div>
    ),
    // @ts-ignore
    getCheckboxProps: (record) => ({
      disabled: record.name === "Joe Black",
      name: record.name,
    }),
  };
  const onPageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };
  const calculateHoursRate = (rate: number, factor: "days" | "hours") => {
    const factorEnum = {
      hours: 1,
      days: 8,
    };
    if (rate === 0) return `0 ${factor === "days" ? "D" : "H"}`;
    return `${rate / factorEnum[factor]} ${factor === "days" ? "D" : "H"}`;
  };
  return (
    <div className={styles.tableContainer}>
      {/* {showSearchFilter && ( */}
      <div className={styles.searchAndButton}>
        {showSearchFilter && (
          <div className={styles.search}>
            <DebouncedSearch
              defaultValue={searchParams.get("search") ?? ""}
              onSearchChange={onSearch}
              placeholder={searchPlaceholder}
            />
          </div>
        )}
        {isFilterBtnVisible && (
          <div className={styles.btn}>
            <FilterButton onClick={() => {}} filtersToHide={[]} />
          </div>
        )}
      </div>
      {/* )} */}
      <div className={`${styles.container} ${containerStyle}`}>
        <Table
          // @ts-ignore
          rowSelection={{
            ...rowSelection,
          }}
          loading={isLoading}
          pagination={false}
          dataSource={data}
        >
          <Column
            title="Members ID"
            dataIndex="age"
            key="age"
            render={(_, record, index) => (
              <>
                <UserInfo
                  data={{
                    user: {
                      first_name: data[index].username,
                      last_name: "",
                    },
                    cfm_id: data[index].cfm_id,
                  }}
                />
              </>
            )}
          />
          <Column title="Department" dataIndex="department" key="age" />
          {
            // [data?.[0]]?.map(
            //   (item: any, indexx: number) =>
            data?.[0]?.leave_policies &&
              data?.[0]?.leave_policies.map(
                (leave_type: any, innerIndex: number) => {
                  // console.log(leave_type, "leave_type");

                  return (
                    <ColumnGroup
                      key={leave_type?.id + ""}
                      title={leave_type?.name}
                    >
                      <Column
                        title="Alloted"
                        dataIndex="total"
                        key="firstName"
                        render={(_, record, indexxx) => (
                          <Text>
                            {calculateHoursRate(
                              data[indexxx]?.leave_policies[innerIndex]?.total,
                              data[indexxx]?.leave_policies[innerIndex]
                                ?.show_balance_in
                            )}
                          </Text>
                        )}
                      />
                      <Column
                        title="Used"
                        dataIndex="used"
                        key="lastName"
                        render={(_, record, indexxx) => (
                          <Text>
                            {calculateHoursRate(
                              data[indexxx]?.leave_policies[innerIndex]?.used,
                              data[indexxx]?.leave_policies[innerIndex]
                                ?.show_balance_in
                            )}
                          </Text>
                        )}
                      />
                      <Column
                        title="Balance"
                        dataIndex="balance"
                        key="lastName"
                        render={(_, record, indexxx) => (
                          <Text>
                            {calculateHoursRate(
                              data[indexxx]?.leave_policies[innerIndex]
                                ?.balance,
                              data[indexxx]?.leave_policies[innerIndex]
                                ?.show_balance_in
                            )}
                          </Text>
                        )}
                      />
                    </ColumnGroup>
                  );
                }
              )
            // )
          }
          <Column
            title=""
            key="action"
            fixed="right"
            render={(_: any, record: DataType) => (
              <Menu
                items={[
                  {
                    key: "profile",
                    label: (
                      <button
                        onClick={() => {
                          setselectedBalance(record);
                          setisViewBalanceVisible(true);
                        }}
                        className={styles.view_profile}
                        style={{ all: "unset", cursor: "pointer" }}
                      >
                        View Balance
                      </button>
                    ),
                  },
                ]}
              >
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  title="Action"
                >
                  <Icon name="Menu" size={24} color="info" />
                </button>
              </Menu>
            )}
          />
        </Table>
      </div>
      {isViewBalanceVisible && (
        <LeaveBalanceDetails
          isVisible={isViewBalanceVisible}
          setIsVisible={setisViewBalanceVisible}
          user={selectedBalance}
          // @ts-ignore
        />
      )}

      {!!count && (
        <Pagination
          className="text-center my-3"
          pageSize={pageSize}
          total={count}
          current={Number(searchParams.get("page") ?? 1)}
          defaultCurrent={Number(searchParams.get("page") ?? 1)}
          onChange={(value: number) => onPageChange(value)}
          showSizeChanger={false}
        />
      )}
    </div>
  );
}
