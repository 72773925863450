/* eslint-disable  */
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ConfigProvider, Tabs } from "antd";
import type { TabsProps } from "antd";

import ImportImage from "assets/icons/import.svg";
import DownloadImage from "assets/icons/download.svg";

import { FixedTemplate } from "apis/types/shift";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import { TableItemProps } from "components/general/Table/types";
import Button from "components/general/Button";
import Table from "components/general/Table";
import Paper from "components/general/PaperComponent";
import Image from "components/general/Image";
import IMPORTANT_VARS from "constants/ImportantVars";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import AddNewRequest from "components/modals/AddNewRequest";
import AddNewPolicy from "components/modals/AddNewPolicy";
import {
  useDeleteLeavePolicyMutation,
  useDeleteRequestTypeMutation,
  useGetLeavePolicyQuery,
  useGetRequestsSetupQuery,
} from "apis/services/other";
import showSuccessMsg from "utils/showSuccessMsg";

import styles from "./styles.module.scss";
import convertUpperCase from "utils/convertUpperCase";

const items: TabsProps["items"] = [
  {
    key: "Requests",
    label: "List of Request",
  },
  {
    key: "Policies",
    label: "Request Policies",
  },
];

const RequestHeaders = [
  "Request Name",
  "Request Code",
  "Request Days",
  "Request Type",
  // "Exceed Balance",
  "",
];

const PoliciesHeaders = [
  "Policy Name",
  "Code",
  "Per Cycle",
  "Calculation Method",
  "Shown For Workers",
  "",
];

interface EditPolicyModalConfig {
  isVisible: boolean;
  selectedPolicy?: any;
}

interface EditRequestModalConfig {
  isVisible: boolean;
  selectedRequest?: any;
}

export default function Setups() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("Requests");

  const { data: requestsList, isFetching: isRequestsListLoading } =
    useGetRequestsSetupQuery(
      {
        page: searchParams.get("page") ?? "1",
        search: searchParams.get("search") ?? "",
        group: searchParams.get("group") ?? "",
        ordering: "-id",
        page_size: IMPORTANT_VARS.TabelDataPageSize,
      },
      {
        skip: selectedTab !== "Requests",
      }
    );

  const { data: policiesList, isFetching: isPoliciesListLoading } =
    useGetLeavePolicyQuery(
      {
        page: searchParams.get("page") ?? "1",
        search: searchParams.get("search") ?? "",
        ordering: "-id",
        page_size: IMPORTANT_VARS.TabelDataPageSize,
      },
      {
        skip: selectedTab === "Requests",
      }
    );

  const [deleteRequestTypeTemplate, { isLoading: isDeletingRequestType }] =
    useDeleteRequestTypeMutation();

  const [deleteLeavePolicy, { isLoading: isDeletingPolicy }] =
    useDeleteLeavePolicyMutation();

  const headers = selectedTab === "Requests" ? RequestHeaders : PoliciesHeaders;
  const data = selectedTab === "Requests" ? requestsList : policiesList;

  const [policyModalShown, setPolicyModalShown] = useState(false);
  const [requestModalShown, setRequestModalShown] = useState(false);
  const [editPolicyModalShown, setEditPolicyModalShown] =
    useState<EditPolicyModalConfig>();
  const [editRequestModalShown, setEditRequestModalShown] =
    useState<EditRequestModalConfig>();

  const onChange = (key: string) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setSelectedTab(key);
  };

  const onGetDeleteShiftFunction = (shiftId: number) => () => {
    deleteRequestTypeTemplate(shiftId)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Fixed shift template deleted successfully!" });
      });
  };

  const onGetEditShiftFunction = (shiftId: number) => () => {
    setEditRequestModalShown({
      isVisible: true,
      selectedRequest: requestsList?.results?.find(
        (item: any) => item.id === shiftId
      ),
    });
  };

  const getRequestMenuItems = (shiftId: number): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Request",
      actionFn: onGetEditShiftFunction(shiftId),
    },
    {
      key: "delete",
      label: "Delete Request",
      actionFn: onGetDeleteShiftFunction(shiftId),
      isDanger: true,
      isLoading: isDeletingRequestType,
    },
  ];

  const changeRequestData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: item?.name,
      },
      { type: "text", dataItem: item?.code },
      {
        type: "text",
        dataItem: `${item?.rate_value + " " + item?.rate_factor}${
          item?.rate_value > 1 ? "s" : ""
        }`,
      },
      {
        type: "text",
        dataItem: item?.type,
      },
      // { type: "text", dataItem: item?.is_exceed_balance ? "Yes" : "No" },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper
            menuData={getRequestMenuItems(item?.id as number)}
          />
        ),
      },
    ],
  });

  const onGetDeletePolicyFunction = (id: number) => () => {
    deleteLeavePolicy(id)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Workinghour deleted successfully!" });
      });
  };

  const onGetEditPolicyFunction = (id: number) => () => {
    setEditPolicyModalShown({
      isVisible: true,
      selectedPolicy: policiesList?.results?.find(
        (item: any) => item.id === id
      ),
    });
  };

  const getPolicyMenuItems = (id: number): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit Policy",
      actionFn: onGetEditPolicyFunction(id),
    },
    {
      key: "delete",
      label: "Delete Policy",
      actionFn: onGetDeletePolicyFunction(id),
      isDanger: true,
      isLoading: isDeletingPolicy,
    },
  ];

  const changePoliciesData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "text",
        dataItem: item?.name,
      },
      {
        type: "text",
        dataItem: item?.code,
      },
      { type: "text", dataItem: item.per_cycle },
      {
        type: "text",
        dataItem: convertUpperCase(item?.show_balance_in, " ") || "--",
      },
      {
        type: "text",
        dataItem: item.is_show_for_worker ? "Yes" : "No",
      },
      {
        type: "element",
        dataItem: (
          <MenuTableDataWrapper
            menuData={getPolicyMenuItems(item?.id as number)}
          />
        ),
      },
    ],
  });

  const RightComponent = (
    <div className="d-flex flex-row">
      <Button
        btnClassName={`px-5 ${styles.headerBtn}`}
        isOutline
        onClick={() =>
          selectedTab !== "Requests"
            ? setPolicyModalShown(true)
            : setRequestModalShown(true)
        }
      >
        +
      </Button>

      <Button isOutline btnClassName={`mx-2 ${styles.headerBtn}`}>
        <Image src={DownloadImage} />
      </Button>
      <Button isOutline btnClassName={styles.headerBtn}>
        <Image src={ImportImage} />
      </Button>
    </div>
  );

  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title="Setup Request"
        RightComponent={RightComponent}
      />

      <Paper
        backgroundColor="white"
        borderColor="white"
        paddingHorizontal={32}
        paddingVertical={14}
      >
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                /* here is your component tokens */
                fontSize: 16,
                colorBgContainer: "#E3332D0D",
                colorBorderSecondary: "#fffff",
                colorFillAlter: "#fffff",
                padding: 24,
                margin: 0,
              },
            },
          }}
        >
          <Tabs
            type="card"
            items={items}
            onChange={onChange}
            prefixCls="tabContainer"
            defaultActiveKey={selectedTab}
          />
        </ConfigProvider>
      </Paper>

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          headers={headers}
          searchPlaceholder={`Search for ${
            selectedTab === "Requests" ? "request name" : "policy name"
          }`}
          data={
            data?.results?.map((item: FixedTemplate) =>
              selectedTab === "Requests"
                ? changeRequestData(item)
                : changePoliciesData(item)
            ) ?? []
          }
          isLoading={isRequestsListLoading || isPoliciesListLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>

      {requestModalShown && (
        <AddNewRequest
          isVisible={requestModalShown}
          setIsVisible={() => setRequestModalShown(false)}
        />
      )}

      {!!editRequestModalShown?.isVisible && (
        <AddNewRequest
          isVisible={!!editRequestModalShown?.isVisible}
          setIsVisible={() => setEditRequestModalShown({ isVisible: false })}
          isUpdate
          initValues={editRequestModalShown?.selectedRequest}
        />
      )}

      {policyModalShown && (
        <AddNewPolicy
          isVisible={policyModalShown}
          setIsVisible={() => setPolicyModalShown(false)}
        />
      )}

      {!!editPolicyModalShown?.isVisible && (
        <AddNewPolicy
          isVisible={editPolicyModalShown?.isVisible}
          setIsVisible={() => setEditPolicyModalShown({ isVisible: false })}
          isUpdate
          initValues={editPolicyModalShown?.selectedPolicy}
        />
      )}
    </div>
  );
}
