import { CiSearch } from "react-icons/ci";
import COLORS from "constants/Colors";
import { ChangeEventHandler, useMemo, useState } from "react";
import { debounce } from "lodash-es";
import { DebouncedSearchProps } from "./types";
import TextInput from "../TextInput";
import styles from "./styles.module.scss";

export default function DebouncedSearch({
  defaultValue = "",
  onSearchChange,
  placeholder = "Search for Members",
}: DebouncedSearchProps) {
  const [searchValue, setsearchValue] = useState(defaultValue);

  const debouncedSearchHandler = useMemo(
    () => debounce(onSearchChange, 300),
    [onSearchChange]
  );

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setsearchValue(e.target.value);
    debouncedSearchHandler(e.target.value);
  };

  return (
    <TextInput
      containerStyle={styles.container}
      placeholder={placeholder}
      value={searchValue}
      inputPrefix={<CiSearch color={COLORS.grey} size={26} />}
      onChange={onChangeHandler}
    />
  );
}
