export enum StateType {
  success = "success",
  absent = "absent",
  permission = "permission",
  conflict = "conflict",
  off = "off",
  publicHoliday = "publicHoliday",
}

export interface CalendarRowProps {
  containerStyle?: string;
  dataItem: {
    id: number;
    state: string;
    date: string;
    shiftType?: string;
    start_time?: string;
    working_hour?: any;
  };
  showActionButton?: boolean;
  isPublicHoliday?: {
    isHoliday: boolean;
    name: string;
  };
  onClickHandler?: (id: number) => void;
}
