import api from "apis";
import { PaginationParams } from "apis/types/general";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const departmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepartments: build.query<any, PaginationParams>({
      query: (params) => ({
        url: "/locations/departments/",
        params,
      }),
      providesTags: ["Department"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    addDepartment: build.mutation({
      query: (body) => ({
        url: "/locations/departments/",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Department"],
    }),
  }),
});

export const { useGetDepartmentsQuery, useAddDepartmentMutation } =
  departmentApi;
