import Button from "components/general/Button";
import Table from "components/general/Table";
import SYNC_ICON from "assets/icons/sync.svg";
import Image from "components/general/Image";
import { useGetProjectsQuery } from "apis/services/project";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Text from "components/general/Text";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import IMPORTANT_VARS from "constants/ImportantVars";
import styles from "./styles.module.scss";

export default function Projects() {
  const [searchParams] = useSearchParams();

  const { data: projects, isFetching: isProjectsLoading } = useGetProjectsQuery(
    {
      ordering: "-id",
      search: searchParams.get("search") ?? "",
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    }
  );

  const getProjectsMenuData = (projectId: number): MenuDataItem[] => [
    {
      key: "delete",
      label: "Delete",
      isDanger: true,
      isLoading: false,
      actionFn: () => projectId,
    },
  ];

  const tabelData =
    projects?.results?.map((item: any) => ({
      id: item.id,
      rowData: [
        {
          dataItem: item.name,
          type: "text",
        },
        {
          dataItem: item?.project_id,
          type: "text",
        },
        {
          dataItem: item?.project_categories.join(", ") || "--",
          type: "text",
        },
        {
          dataItem: (
            <MenuTableDataWrapper menuData={getProjectsMenuData(item.id)}>
              <Text>{item.category?.name}</Text>
            </MenuTableDataWrapper>
          ),
          type: "element",
        },
      ],
    })) || [];

  const tabelHeaders = ["Project Name", "Project ID", "Category"];

  const lastUpdateDate = moment().format("DD/M/YYYY - hh:mm a");

  const SubtitleMarkup = (
    <Text className={styles.subtitle}>
      Last Update:
      <Text className={styles.bold}>{lastUpdateDate}</Text>
    </Text>
  );

  return (
    <div>
      <SecondaryPageHeader
        title="Projects"
        SubTitleComponent={SubtitleMarkup}
        RightComponent={
          <Button
            btnClassName={styles.headerBtn}
            suffix={<Image src={SYNC_ICON} />}
          >
            Sync
          </Button>
        }
      />

      <div>
        <Table
          headers={tabelHeaders}
          data={tabelData}
          showSearchFilter
          isLoading={isProjectsLoading}
          count={projects?.count}
          searchPlaceholder="Search for Projects"
          filtersToHide={["shift", "status", "location", "department"]}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
