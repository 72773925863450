import Trip from "./Trip";
import Mission from "./Mission";
import Permissions from "./Permissions";
import { CreateRequestProps } from "./types";
import styles from "./styles.module.scss";
import Leave from "./Leave";
import Transfer from "./Transfer";

const Requests = {
  mission: {
    title: "Request Mission",
    request_type: "mission_requests",
    component: (props: CreateRequestProps) => <Mission {...props} />,
  },
  permissions: {
    title: "Request Permissions",
    request_type: "business_permission_requests",
    component: (props: CreateRequestProps) => <Permissions {...props} />,
  },
  trip: {
    title: "Request Business trip",
    request_type: "business_trip_requests",
    component: (props: CreateRequestProps) => <Trip {...props} />,
  },
  transfer: {
    title: "Request Transfer",
    request_type: "transfer_requests",
    component: (props: CreateRequestProps) => <Transfer {...props} />,
  },
  leave: {
    title: "Request Leave",
    request_type: "leave_requests",
    component: (props: CreateRequestProps) => <Leave {...props} />,
  },
};

export default function CreateRequest({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate,
  type,
  request,
}: any) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {Requests[type as keyof typeof Requests]?.component({
        containerStyle,
        isVisible,
        setIsVisible,
        isMultipleAssign,
        isUpdate,
        type,
        request,
      })}
    </div>
  );
}
