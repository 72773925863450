import CheckBox from "components/general/CheckBox";
import useToggleState from "hooks/useToggleState";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { parseParam, stringifyParam } from "utils/queryParamService";
import styles from "./styles.module.scss";
import ModalWrapper from "../ModalWrapper";
import { FilterSelectionModalProps } from "./types";

export default function FilterSelectionModal({
  data,
  filterType,
  isVisible = false,
  setIsVisible,
}: FilterSelectionModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedValues: selectedFilterValues,
    addToSelected: addToFilter,
    replaceState: replaceFilterWith,
    isSelected: isAlreadyInFilter,
  } = useToggleState<string>([]);

  const onChangeValueHandler = (value: string) => {
    addToFilter(value);
  };

  useEffect(() => {
    if (isVisible) {
      const defaultFilterValues = searchParams.get(filterType);
      if (defaultFilterValues) {
        const parsedDefaultFilterValues = parseParam(defaultFilterValues);
        replaceFilterWith(parsedDefaultFilterValues);
      }
    } else {
      replaceFilterWith([]);
    }
  }, [isVisible, filterType]);

  const onConfirmHandler = () => {
    searchParams.set("page", "1");
    searchParams.set(filterType, stringifyParam(selectedFilterValues));

    setSearchParams(searchParams);

    setIsVisible(false);
  };

  const paramsList = searchParams.get(filterType)?.split(",");

  return (
    <ModalWrapper
      setIsVisible={setIsVisible}
      size="small"
      headerTitle={`Filter by ${filterType}`}
      headerTitleJustify="start"
      isVisible={isVisible}
      onConfirm={onConfirmHandler}
    >
      <div style={{ width: "100%" }} className="p-3">
        {data?.map((item) => (
          <div
            className={styles.container}
            style={{
              backgroundColor: !isAlreadyInFilter(item.value)
                ? "#D9D9D91A"
                : "#e3332d0d",
            }}
          >
            <CheckBox
              text={item.label}
              defaultChecked={paramsList?.includes(item.value)}
              onValueChange={() => {
                onChangeValueHandler(item.value);
              }}
            />
          </div>
        ))}
      </div>
    </ModalWrapper>
  );
}
