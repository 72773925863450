import React from "react";
import { ToastContainer } from "react-toastify";
import { ConfigProvider, ThemeConfig } from "antd";
import COLORS from "constants/Colors";
import useAuth from "hooks/useAuth";
import styles from "./styles.module.scss";

const config: ThemeConfig = {
  token: {
    colorPrimary: COLORS.primary,
  },
};

export default function MainLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useAuth();

  return (
    <ConfigProvider theme={config}>
      <div className={styles.mainContainer}>
        {children}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ConfigProvider>
  );
}
