import { Form } from "antd";
import {
  useEditMemberOvertimeMutation,
  // useEditNewStaffOvertimesMutation,
} from "apis/services/overtime";
import { useGetProjectsQuery } from "apis/services/locations";
import SelectionInput from "components/inputs/SelectionInput";
import TextInput from "components/inputs/TextInput";
import showSuccessMsg from "utils/showSuccessMsg";
import Paper from "components/general/PaperComponent";
import UserInfo from "components/cards/UserInfo";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EditOvertimeProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";

export default function EditOvertime({
  containerStyle,
  isVisible,
  setIsVisible,
  shift,
  isUpdate,
}: EditOvertimeProps) {
  const location = useLocation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const userData = useSelector((state: any) => state.auth.userData);
  const { data: projects } = useGetProjectsQuery({
    page_size: 100,
  });
  const is_editing_myself =
    String(userData?.id) === id || location.pathname.includes("my-profile");

  // const [editMemberOvertime] = useEditNewStaffOvertimesMutation();
  const [editMemberOvertime] = useEditMemberOvertimeMutation();

  const onFinish = (values: {
    total_overtime: number;
    paid_hrs: number;
    unpaid_hrs: number;
    hrs_added_to_leave_balance: number;
    project_for_paid_overtime: number;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      id: shift?.overtime?.id,
    };

    editMemberOvertime(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Overtime edited successfully!" });
        setIsVisible(false);
      });
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Edit Overtime"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
      isBtnDisabled={is_editing_myself}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <div className="mb-3">
              <Paper
                backgroundColor="#F0F0F040"
                borderColor="#D9D9D980"
                paddingHorizontal={24}
                paddingVertical={18}
              >
                <UserInfo data={shift?.employee} />
              </Paper>
            </div>

            <Form.Item
              name="total_overtime"
              label="Total Overtime"
              rules={[{ required: true }]}
              initialValue={shift?.overtime?.total_overtime}
            >
              <TextInput outerSuffix="Hrs" disabled />
            </Form.Item>

            <div className="d-flex justify-content-between">
              <Form.Item
                name="paid_hrs"
                label="Paid"
                rules={[{ required: true }]}
                style={{ display: "inline-block", width: "calc(33% - 8px)" }}
                initialValue={shift?.overtime?.paid_hrs}
              >
                <TextInput outerSuffix="Hrs" disabled={is_editing_myself} />
              </Form.Item>
              <Form.Item
                name="unpaid_hrs"
                label="Unpaid"
                rules={[{ required: true }]}
                style={{ display: "inline-block", width: "calc(33% - 8px)" }}
                initialValue={shift?.overtime?.unpaid_hrs}
              >
                <TextInput outerSuffix="Hrs" disabled={is_editing_myself} />
              </Form.Item>
              <Form.Item
                name="hrs_added_to_leave_balance"
                label="Leave Balance"
                rules={[{ required: true }]}
                style={{ display: "inline-block", width: "calc(33% - 8px)" }}
                initialValue={shift?.overtime?.hrs_added_to_leave_balance}
              >
                <TextInput outerSuffix="Hrs" disabled={is_editing_myself} />
              </Form.Item>
            </div>

            <Form.Item
              // name="project_for_paid_overtime"
              label="Project for Paid overtime"
              // rules={[{ required: true }]}
              initialValue={shift?.overtime?.project_for_paid_overtime}
            >
              <SelectionInput
                disabled={is_editing_myself}
                options={
                  projects?.results?.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  }) ?? []
                }
              />
            </Form.Item>
            <Form.Item name="category" label="Category for paid overtime">
              <SelectionInput options={[]} disabled={is_editing_myself} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
